/* tslint:disable */
/* eslint-disable */
/**
 * Consolidate Legacy API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetSentMailsRequest
 */
export interface GetSentMailsRequest {
    /**
     * 
     * @type {number}
     * @memberof GetSentMailsRequest
     */
    limit?: number;
    /**
     * 
     * @type {number}
     * @memberof GetSentMailsRequest
     */
    offset?: number;
}

export function GetSentMailsRequestFromJSON(json: any): GetSentMailsRequest {
    return GetSentMailsRequestFromJSONTyped(json, false);
}

export function GetSentMailsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetSentMailsRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'limit': !exists(json, 'limit') ? undefined : json['limit'],
        'offset': !exists(json, 'offset') ? undefined : json['offset'],
    };
}

export function GetSentMailsRequestToJSON(value?: GetSentMailsRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'limit': value.limit,
        'offset': value.offset,
    };
}


