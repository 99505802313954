/* tslint:disable */
/* eslint-disable */
/**
 * Consolidate Legacy API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LoginRequest
 */
export interface LoginRequest {
    /**
     * 
     * @type {string}
     * @memberof LoginRequest
     */
    uid: string;
    /**
     * 
     * @type {string}
     * @memberof LoginRequest
     */
    pwd: string;
    /**
     * 
     * @type {string}
     * @memberof LoginRequest
     */
    deviceid: string;
    /**
     * 
     * @type {number}
     * @memberof LoginRequest
     */
    devicetype: LoginRequestDevicetypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof LoginRequest
     */
    push: boolean;
    /**
     * 
     * @type {string}
     * @memberof LoginRequest
     */
    devicetoken?: string;
}

/**
* @export
* @enum {string}
*/
export enum LoginRequestDevicetypeEnum {
    Ios = 1,
    Android = 2,
    WindowsPhone = 3,
    Browser = 4,
    Desktop = 5
}

export function LoginRequestFromJSON(json: any): LoginRequest {
    return LoginRequestFromJSONTyped(json, false);
}

export function LoginRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): LoginRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'uid': json['uid'],
        'pwd': json['pwd'],
        'deviceid': json['deviceid'],
        'devicetype': json['devicetype'],
        'push': json['push'],
        'devicetoken': !exists(json, 'devicetoken') ? undefined : json['devicetoken'],
    };
}

export function LoginRequestToJSON(value?: LoginRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'uid': value.uid,
        'pwd': value.pwd,
        'deviceid': value.deviceid,
        'devicetype': value.devicetype,
        'push': value.push,
        'devicetoken': value.devicetoken,
    };
}


