/* tslint:disable */
/* eslint-disable */
/**
 * Consolidate Legacy API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetAktMemberDefault
 */
export interface GetAktMemberDefault {
    /**
     * 
     * @type {string}
     * @memberof GetAktMemberDefault
     */
    anr?: string;
    /**
     * 
     * @type {number}
     * @memberof GetAktMemberDefault
     */
    cid?: number;
    /**
     * 
     * @type {string}
     * @memberof GetAktMemberDefault
     */
    confirmation?: string;
    /**
     * 
     * @type {number}
     * @memberof GetAktMemberDefault
     */
    confirmationstatus?: number;
    /**
     * 
     * @type {string}
     * @memberof GetAktMemberDefault
     */
    exclusiondates?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAktMemberDefault
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAktMemberDefault
     */
    mailaddress?: string;
    /**
     * 
     * @type {number}
     * @memberof GetAktMemberDefault
     */
    mailstatus?: number;
    /**
     * 
     * @type {string}
     * @memberof GetAktMemberDefault
     */
    uid?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAktMemberDefault
     */
    mailsenddatetime?: string;
}

export function GetAktMemberDefaultFromJSON(json: any): GetAktMemberDefault {
    return GetAktMemberDefaultFromJSONTyped(json, false);
}

export function GetAktMemberDefaultFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetAktMemberDefault {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'anr': !exists(json, 'anr') ? undefined : json['anr'],
        'cid': !exists(json, 'cid') ? undefined : json['cid'],
        'confirmation': !exists(json, 'confirmation') ? undefined : json['confirmation'],
        'confirmationstatus': !exists(json, 'confirmationstatus') ? undefined : json['confirmationstatus'],
        'exclusiondates': !exists(json, 'exclusiondates') ? undefined : json['exclusiondates'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'mailaddress': !exists(json, 'mailaddress') ? undefined : json['mailaddress'],
        'mailstatus': !exists(json, 'mailstatus') ? undefined : json['mailstatus'],
        'uid': !exists(json, 'uid') ? undefined : json['uid'],
        'mailsenddatetime': !exists(json, 'mailsenddatetime') ? undefined : json['mailsenddatetime'],
    };
}

export function GetAktMemberDefaultToJSON(value?: GetAktMemberDefault | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'anr': value.anr,
        'cid': value.cid,
        'confirmation': value.confirmation,
        'confirmationstatus': value.confirmationstatus,
        'exclusiondates': value.exclusiondates,
        'description': value.description,
        'mailaddress': value.mailaddress,
        'mailstatus': value.mailstatus,
        'uid': value.uid,
        'mailsenddatetime': value.mailsenddatetime,
    };
}


