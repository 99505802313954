/* tslint:disable */
/* eslint-disable */
/**
 * Consolidate Legacy API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetTodoItemDivider
 */
export interface GetTodoItemDivider {
    /**
     * 
     * @type {string}
     * @memberof GetTodoItemDivider
     */
    aktid: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetTodoItemDivider
     */
    isDivider: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetTodoItemDivider
     */
    label: string;
}

export function GetTodoItemDividerFromJSON(json: any): GetTodoItemDivider {
    return GetTodoItemDividerFromJSONTyped(json, false);
}

export function GetTodoItemDividerFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetTodoItemDivider {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'aktid': json['aktid'],
        'isDivider': json['isDivider'],
        'label': json['label'],
    };
}

export function GetTodoItemDividerToJSON(value?: GetTodoItemDivider | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'aktid': value.aktid,
        'isDivider': value.isDivider,
        'label': value.label,
    };
}


