/* tslint:disable */
/* eslint-disable */
/**
 * Consolidate Legacy API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CustomFormField,
    CustomFormFieldFromJSON,
    CustomFormFieldFromJSONTyped,
    CustomFormFieldToJSON,
    GetAktResponseBase,
    GetAktResponseBaseFromJSON,
    GetAktResponseBaseFromJSONTyped,
    GetAktResponseBaseToJSON,
    GetAktResponseBaseObjects,
    GetAktResponseBaseObjectsFromJSON,
    GetAktResponseBaseObjectsFromJSONTyped,
    GetAktResponseBaseObjectsToJSON,
    GetAktResponseBaseRights,
    GetAktResponseBaseRightsFromJSON,
    GetAktResponseBaseRightsFromJSONTyped,
    GetAktResponseBaseRightsToJSON,
    GetAktResponseEmailAllOf,
    GetAktResponseEmailAllOfFromJSON,
    GetAktResponseEmailAllOfFromJSONTyped,
    GetAktResponseEmailAllOfToJSON,
    GetAktResponseEmailAllOfMembers,
    GetAktResponseEmailAllOfMembersFromJSON,
    GetAktResponseEmailAllOfMembersFromJSONTyped,
    GetAktResponseEmailAllOfMembersToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetAktResponseEmail
 */
export interface GetAktResponseEmail {
    /**
     * 
     * @type {number}
     * @memberof GetAktResponseEmail
     */
    addAttachment: number;
    /**
     * 
     * @type {boolean}
     * @memberof GetAktResponseEmail
     */
    allowobjects?: boolean;
    /**
     * 
     * @type {number}
     * @memberof GetAktResponseEmail
     */
    aktid: number;
    /**
     * 
     * @type {string}
     * @memberof GetAktResponseEmail
     */
    aktname: string;
    /**
     * 
     * @type {number}
     * @memberof GetAktResponseEmail
     */
    aktsakt: number;
    /**
     * 
     * @type {number}
     * @memberof GetAktResponseEmail
     */
    akttype: number;
    /**
     * 
     * @type {string}
     * @memberof GetAktResponseEmail
     */
    alarmtime?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAktResponseEmail
     */
    alarmtimeutc?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAktResponseEmail
     */
    createdby?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAktResponseEmail
     */
    creationdate?: string;
    /**
     * 
     * @type {Array<object>}
     * @memberof GetAktResponseEmail
     */
    attachments?: Array<object>;
    /**
     * 
     * @type {string}
     * @memberof GetAktResponseEmail
     */
    color: string;
    /**
     * 
     * @type {{ [key: string]: { [key: string]: CustomFormField; }; }}
     * @memberof GetAktResponseEmail
     */
    custom?: { [key: string]: { [key: string]: CustomFormField; }; };
    /**
     * 
     * @type {Array<object>}
     * @memberof GetAktResponseEmail
     */
    internalCCs?: Array<object>;
    /**
     * 
     * @type {boolean}
     * @memberof GetAktResponseEmail
     */
    morecontacts: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetAktResponseEmail
     */
    date: string;
    /**
     * 
     * @type {string}
     * @memberof GetAktResponseEmail
     */
    datelabel: string;
    /**
     * 
     * @type {number}
     * @memberof GetAktResponseEmail
     */
    hasfollowakt: number;
    /**
     * 
     * @type {string}
     * @memberof GetAktResponseEmail
     */
    icon: string;
    /**
     * 
     * @type {number}
     * @memberof GetAktResponseEmail
     */
    nextsteptype?: number;
    /**
     * 
     * @type {number}
     * @memberof GetAktResponseEmail
     */
    nextstepid?: number;
    /**
     * 
     * @type {string}
     * @memberof GetAktResponseEmail
     */
    nextstepname?: string;
    /**
     * 
     * @type {number}
     * @memberof GetAktResponseEmail
     */
    nextstepfiredby?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GetAktResponseEmail
     */
    infomail?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetAktResponseEmail
     */
    yesInfo?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAktResponseEmail
     */
    noInfo?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAktResponseEmail
     */
    stepcomment?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAktResponseEmail
     */
    steptargetuser?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAktResponseEmail
     */
    steptargetlist?: string;
    /**
     * 
     * @type {Array<GetAktResponseBaseObjects>}
     * @memberof GetAktResponseEmail
     */
    objects?: Array<GetAktResponseBaseObjects>;
    /**
     * 
     * @type {number}
     * @memberof GetAktResponseEmail
     */
    prevakt: number;
    /**
     * 
     * @type {number}
     * @memberof GetAktResponseEmail
     */
    priority: number;
    /**
     * 
     * @type {number}
     * @memberof GetAktResponseEmail
     */
    processid?: number;
    /**
     * 
     * @type {number}
     * @memberof GetAktResponseEmail
     */
    projectid: number;
    /**
     * 
     * @type {string}
     * @memberof GetAktResponseEmail
     */
    projectname?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetAktResponseEmail
     */
    projectvisible: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetAktResponseEmail
     */
    resp: string;
    /**
     * 
     * @type {GetAktResponseBaseRights}
     * @memberof GetAktResponseEmail
     */
    rights: GetAktResponseBaseRights;
    /**
     * 
     * @type {number}
     * @memberof GetAktResponseEmail
     */
    secret: number;
    /**
     * 
     * @type {string}
     * @memberof GetAktResponseEmail
     */
    status: string;
    /**
     * 
     * @type {number}
     * @memberof GetAktResponseEmail
     */
    statusid: number;
    /**
     * 
     * @type {string}
     * @memberof GetAktResponseEmail
     */
    subject: string;
    /**
     * 
     * @type {string}
     * @memberof GetAktResponseEmail
     */
    workflowInfo?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAktResponseEmail
     */
    workflowinfotimestamp: string;
    /**
     * 
     * @type {string}
     * @memberof GetAktResponseEmail
     */
    addressid?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAktResponseEmail
     */
    adrcontent: string;
    /**
     * 
     * @type {string}
     * @memberof GetAktResponseEmail
     */
    adrlabel: string;
    /**
     * 
     * @type {number}
     * @memberof GetAktResponseEmail
     */
    contactid: number;
    /**
     * 
     * @type {GetAktResponseEmailAllOfMembers}
     * @memberof GetAktResponseEmail
     */
    members: GetAktResponseEmailAllOfMembers;
    /**
     * 
     * @type {Array<object>}
     * @memberof GetAktResponseEmail
     */
    replyall?: Array<object>;
    /**
     * 
     * @type {string}
     * @memberof GetAktResponseEmail
     */
    replyto?: string;
}

export function GetAktResponseEmailFromJSON(json: any): GetAktResponseEmail {
    return GetAktResponseEmailFromJSONTyped(json, false);
}

export function GetAktResponseEmailFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetAktResponseEmail {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'addAttachment': json['addAttachment'],
        'allowobjects': !exists(json, 'allowobjects') ? undefined : json['allowobjects'],
        'aktid': json['aktid'],
        'aktname': json['aktname'],
        'aktsakt': json['aktsakt'],
        'akttype': json['akttype'],
        'alarmtime': !exists(json, 'alarmtime') ? undefined : json['alarmtime'],
        'alarmtimeutc': !exists(json, 'alarmtimeutc') ? undefined : json['alarmtimeutc'],
        'createdby': !exists(json, 'createdby') ? undefined : json['createdby'],
        'creationdate': !exists(json, 'creationdate') ? undefined : json['creationdate'],
        'attachments': !exists(json, 'attachments') ? undefined : json['attachments'],
        'color': json['color'],
        'custom': !exists(json, 'custom') ? undefined : json['custom'],
        'internalCCs': !exists(json, 'internalCCs') ? undefined : json['internalCCs'],
        'morecontacts': json['morecontacts'],
        'date': json['date'],
        'datelabel': json['datelabel'],
        'hasfollowakt': json['hasfollowakt'],
        'icon': json['icon'],
        'nextsteptype': !exists(json, 'nextsteptype') ? undefined : json['nextsteptype'],
        'nextstepid': !exists(json, 'nextstepid') ? undefined : json['nextstepid'],
        'nextstepname': !exists(json, 'nextstepname') ? undefined : json['nextstepname'],
        'nextstepfiredby': !exists(json, 'nextstepfiredby') ? undefined : json['nextstepfiredby'],
        'infomail': !exists(json, 'infomail') ? undefined : json['infomail'],
        'yesInfo': !exists(json, 'yes_info') ? undefined : json['yes_info'],
        'noInfo': !exists(json, 'no_info') ? undefined : json['no_info'],
        'stepcomment': !exists(json, 'stepcomment') ? undefined : json['stepcomment'],
        'steptargetuser': !exists(json, 'steptargetuser') ? undefined : json['steptargetuser'],
        'steptargetlist': !exists(json, 'steptargetlist') ? undefined : json['steptargetlist'],
        'objects': !exists(json, 'objects') ? undefined : ((json['objects'] as Array<any>).map(GetAktResponseBaseObjectsFromJSON)),
        'prevakt': json['prevakt'],
        'priority': json['priority'],
        'processid': !exists(json, 'processid') ? undefined : json['processid'],
        'projectid': json['projectid'],
        'projectname': !exists(json, 'projectname') ? undefined : json['projectname'],
        'projectvisible': json['projectvisible'],
        'resp': json['resp'],
        'rights': GetAktResponseBaseRightsFromJSON(json['rights']),
        'secret': json['secret'],
        'status': json['status'],
        'statusid': json['statusid'],
        'subject': json['subject'],
        'workflowInfo': !exists(json, 'workflowInfo') ? undefined : json['workflowInfo'],
        'workflowinfotimestamp': json['workflowinfotimestamp'],
        'addressid': !exists(json, 'addressid') ? undefined : json['addressid'],
        'adrcontent': json['adrcontent'],
        'adrlabel': json['adrlabel'],
        'contactid': json['contactid'],
        'members': GetAktResponseEmailAllOfMembersFromJSON(json['members']),
        'replyall': !exists(json, 'replyall') ? undefined : json['replyall'],
        'replyto': !exists(json, 'replyto') ? undefined : json['replyto'],
    };
}

export function GetAktResponseEmailToJSON(value?: GetAktResponseEmail | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'addAttachment': value.addAttachment,
        'allowobjects': value.allowobjects,
        'aktid': value.aktid,
        'aktname': value.aktname,
        'aktsakt': value.aktsakt,
        'akttype': value.akttype,
        'alarmtime': value.alarmtime,
        'alarmtimeutc': value.alarmtimeutc,
        'createdby': value.createdby,
        'creationdate': value.creationdate,
        'attachments': value.attachments,
        'color': value.color,
        'custom': value.custom,
        'internalCCs': value.internalCCs,
        'morecontacts': value.morecontacts,
        'date': value.date,
        'datelabel': value.datelabel,
        'hasfollowakt': value.hasfollowakt,
        'icon': value.icon,
        'nextsteptype': value.nextsteptype,
        'nextstepid': value.nextstepid,
        'nextstepname': value.nextstepname,
        'nextstepfiredby': value.nextstepfiredby,
        'infomail': value.infomail,
        'yes_info': value.yesInfo,
        'no_info': value.noInfo,
        'stepcomment': value.stepcomment,
        'steptargetuser': value.steptargetuser,
        'steptargetlist': value.steptargetlist,
        'objects': value.objects === undefined ? undefined : ((value.objects as Array<any>).map(GetAktResponseBaseObjectsToJSON)),
        'prevakt': value.prevakt,
        'priority': value.priority,
        'processid': value.processid,
        'projectid': value.projectid,
        'projectname': value.projectname,
        'projectvisible': value.projectvisible,
        'resp': value.resp,
        'rights': GetAktResponseBaseRightsToJSON(value.rights),
        'secret': value.secret,
        'status': value.status,
        'statusid': value.statusid,
        'subject': value.subject,
        'workflowInfo': value.workflowInfo,
        'workflowinfotimestamp': value.workflowinfotimestamp,
        'addressid': value.addressid,
        'adrcontent': value.adrcontent,
        'adrlabel': value.adrlabel,
        'contactid': value.contactid,
        'members': GetAktResponseEmailAllOfMembersToJSON(value.members),
        'replyall': value.replyall,
        'replyto': value.replyto,
    };
}


