/* tslint:disable */
/* eslint-disable */
/**
 * Consolidate Legacy API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AddOrEditContactRequest
 */
export interface AddOrEditContactRequest {
    /**
     * 
     * @type {number}
     * @memberof AddOrEditContactRequest
     */
    CID?: number;
    /**
     * 
     * @type {string}
     * @memberof AddOrEditContactRequest
     */
    lastname: string;
    /**
     * 
     * @type {string}
     * @memberof AddOrEditContactRequest
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof AddOrEditContactRequest
     */
    firstname?: string;
    /**
     * 
     * @type {string}
     * @memberof AddOrEditContactRequest
     */
    attention?: string;
    /**
     * 
     * @type {number}
     * @memberof AddOrEditContactRequest
     */
    sex?: number;
    /**
     * 
     * @type {string}
     * @memberof AddOrEditContactRequest
     */
    salutationpriv?: string;
    /**
     * 
     * @type {number}
     * @memberof AddOrEditContactRequest
     */
    locationID?: number;
    /**
     * 
     * @type {string}
     * @memberof AddOrEditContactRequest
     */
    privstreet?: string;
    /**
     * 
     * @type {string}
     * @memberof AddOrEditContactRequest
     */
    privcity?: string;
    /**
     * 
     * @type {string}
     * @memberof AddOrEditContactRequest
     */
    privzip?: string;
    /**
     * 
     * @type {string}
     * @memberof AddOrEditContactRequest
     */
    privcountrycode?: string;
    /**
     * 
     * @type {string}
     * @memberof AddOrEditContactRequest
     */
    infotext?: string;
    /**
     * 
     * @type {string}
     * @memberof AddOrEditContactRequest
     */
    function?: string;
    /**
     * 
     * @type {string}
     * @memberof AddOrEditContactRequest
     */
    department?: string;
    /**
     * 
     * @type {string}
     * @memberof AddOrEditContactRequest
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof AddOrEditContactRequest
     */
    mobile?: string;
    /**
     * 
     * @type {string}
     * @memberof AddOrEditContactRequest
     */
    tel?: string;
    /**
     * 
     * @type {string}
     * @memberof AddOrEditContactRequest
     */
    dob?: string;
    /**
     * 
     * @type {string}
     * @memberof AddOrEditContactRequest
     */
    anr?: string;
    /**
     * 
     * @type {string}
     * @memberof AddOrEditContactRequest
     */
    companyname?: string;
    /**
     * 
     * @type {string}
     * @memberof AddOrEditContactRequest
     */
    compcity?: string;
    /**
     * 
     * @type {string}
     * @memberof AddOrEditContactRequest
     */
    compcountrycode?: string;
    /**
     * 
     * @type {string}
     * @memberof AddOrEditContactRequest
     */
    compstreet?: string;
    /**
     * 
     * @type {string}
     * @memberof AddOrEditContactRequest
     */
    compzip?: string;
    /**
     * 
     * @type {string}
     * @memberof AddOrEditContactRequest
     */
    comptelprev?: string;
    /**
     * 
     * @type {string}
     * @memberof AddOrEditContactRequest
     */
    comptel?: string;
    /**
     * 
     * @type {string}
     * @memberof AddOrEditContactRequest
     */
    compfax?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AddOrEditContactRequest
     */
    addfavorite?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AddOrEditContactRequest
     */
    jpg?: string;
}

export function AddOrEditContactRequestFromJSON(json: any): AddOrEditContactRequest {
    return AddOrEditContactRequestFromJSONTyped(json, false);
}

export function AddOrEditContactRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddOrEditContactRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'CID': !exists(json, 'CID') ? undefined : json['CID'],
        'lastname': json['lastname'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'firstname': !exists(json, 'firstname') ? undefined : json['firstname'],
        'attention': !exists(json, 'attention') ? undefined : json['attention'],
        'sex': !exists(json, 'sex') ? undefined : json['sex'],
        'salutationpriv': !exists(json, 'salutationpriv') ? undefined : json['salutationpriv'],
        'locationID': !exists(json, 'locationID') ? undefined : json['locationID'],
        'privstreet': !exists(json, 'privstreet') ? undefined : json['privstreet'],
        'privcity': !exists(json, 'privcity') ? undefined : json['privcity'],
        'privzip': !exists(json, 'privzip') ? undefined : json['privzip'],
        'privcountrycode': !exists(json, 'privcountrycode') ? undefined : json['privcountrycode'],
        'infotext': !exists(json, 'infotext') ? undefined : json['infotext'],
        'function': !exists(json, 'function') ? undefined : json['function'],
        'department': !exists(json, 'department') ? undefined : json['department'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'mobile': !exists(json, 'mobile') ? undefined : json['mobile'],
        'tel': !exists(json, 'tel') ? undefined : json['tel'],
        'dob': !exists(json, 'dob') ? undefined : json['dob'],
        'anr': !exists(json, 'anr') ? undefined : json['anr'],
        'companyname': !exists(json, 'companyname') ? undefined : json['companyname'],
        'compcity': !exists(json, 'compcity') ? undefined : json['compcity'],
        'compcountrycode': !exists(json, 'compcountrycode') ? undefined : json['compcountrycode'],
        'compstreet': !exists(json, 'compstreet') ? undefined : json['compstreet'],
        'compzip': !exists(json, 'compzip') ? undefined : json['compzip'],
        'comptelprev': !exists(json, 'comptelprev') ? undefined : json['comptelprev'],
        'comptel': !exists(json, 'comptel') ? undefined : json['comptel'],
        'compfax': !exists(json, 'compfax') ? undefined : json['compfax'],
        'addfavorite': !exists(json, 'addfavorite') ? undefined : json['addfavorite'],
        'jpg': !exists(json, 'jpg') ? undefined : json['jpg'],
    };
}

export function AddOrEditContactRequestToJSON(value?: AddOrEditContactRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'CID': value.CID,
        'lastname': value.lastname,
        'title': value.title,
        'firstname': value.firstname,
        'attention': value.attention,
        'sex': value.sex,
        'salutationpriv': value.salutationpriv,
        'locationID': value.locationID,
        'privstreet': value.privstreet,
        'privcity': value.privcity,
        'privzip': value.privzip,
        'privcountrycode': value.privcountrycode,
        'infotext': value.infotext,
        'function': value.function,
        'department': value.department,
        'email': value.email,
        'mobile': value.mobile,
        'tel': value.tel,
        'dob': value.dob,
        'anr': value.anr,
        'companyname': value.companyname,
        'compcity': value.compcity,
        'compcountrycode': value.compcountrycode,
        'compstreet': value.compstreet,
        'compzip': value.compzip,
        'comptelprev': value.comptelprev,
        'comptel': value.comptel,
        'compfax': value.compfax,
        'addfavorite': value.addfavorite,
        'jpg': value.jpg,
    };
}


