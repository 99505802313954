/* tslint:disable */
/* eslint-disable */
/**
 * Consolidate Legacy API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FindActivityResponseItems
 */
export interface FindActivityResponseItems {
    /**
     * 
     * @type {number}
     * @memberof FindActivityResponseItems
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof FindActivityResponseItems
     */
    aktid: number;
    /**
     * 
     * @type {string}
     * @memberof FindActivityResponseItems
     */
    createdby: string;
    /**
     * 
     * @type {string}
     * @memberof FindActivityResponseItems
     */
    date: string;
    /**
     * 
     * @type {boolean}
     * @memberof FindActivityResponseItems
     */
    isBold: boolean;
    /**
     * 
     * @type {string}
     * @memberof FindActivityResponseItems
     */
    subject: string;
    /**
     * 
     * @type {string}
     * @memberof FindActivityResponseItems
     */
    resp: string;
    /**
     * 
     * @type {number}
     * @memberof FindActivityResponseItems
     */
    filename: number;
    /**
     * 
     * @type {boolean}
     * @memberof FindActivityResponseItems
     */
    hasattachment: boolean;
    /**
     * 
     * @type {string}
     * @memberof FindActivityResponseItems
     */
    adrcontent: string;
    /**
     * 
     * @type {string}
     * @memberof FindActivityResponseItems
     */
    projectname: string;
    /**
     * 
     * @type {string}
     * @memberof FindActivityResponseItems
     */
    aktname: string;
    /**
     * 
     * @type {string}
     * @memberof FindActivityResponseItems
     */
    icon: string;
    /**
     * 
     * @type {string}
     * @memberof FindActivityResponseItems
     */
    summary: string;
}

export function FindActivityResponseItemsFromJSON(json: any): FindActivityResponseItems {
    return FindActivityResponseItemsFromJSONTyped(json, false);
}

export function FindActivityResponseItemsFromJSONTyped(json: any, ignoreDiscriminator: boolean): FindActivityResponseItems {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'aktid': json['aktid'],
        'createdby': json['createdby'],
        'date': json['Date'],
        'isBold': json['isBold'],
        'subject': json['subject'],
        'resp': json['resp'],
        'filename': json['filename'],
        'hasattachment': json['hasattachment'],
        'adrcontent': json['adrcontent'],
        'projectname': json['projectname'],
        'aktname': json['aktname'],
        'icon': json['icon'],
        'summary': json['summary'],
    };
}

export function FindActivityResponseItemsToJSON(value?: FindActivityResponseItems | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'aktid': value.aktid,
        'createdby': value.createdby,
        'Date': value.date,
        'isBold': value.isBold,
        'subject': value.subject,
        'resp': value.resp,
        'filename': value.filename,
        'hasattachment': value.hasattachment,
        'adrcontent': value.adrcontent,
        'projectname': value.projectname,
        'aktname': value.aktname,
        'icon': value.icon,
        'summary': value.summary,
    };
}


