/* tslint:disable */
/* eslint-disable */
/**
 * Consolidate Legacy API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InlineObject18
 */
export interface InlineObject18 {
    /**
     * 
     * @type {number}
     * @memberof InlineObject18
     */
    aktid: number;
    /**
     * 
     * @type {string}
     * @memberof InlineObject18
     */
    reason?: string;
    /**
     * 
     * @type {boolean}
     * @memberof InlineObject18
     */
    sendreply?: boolean;
}

export function InlineObject18FromJSON(json: any): InlineObject18 {
    return InlineObject18FromJSONTyped(json, false);
}

export function InlineObject18FromJSONTyped(json: any, ignoreDiscriminator: boolean): InlineObject18 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'aktid': json['aktid'],
        'reason': !exists(json, 'reason') ? undefined : json['reason'],
        'sendreply': !exists(json, 'sendreply') ? undefined : json['sendreply'],
    };
}

export function InlineObject18ToJSON(value?: InlineObject18 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'aktid': value.aktid,
        'reason': value.reason,
        'sendreply': value.sendreply,
    };
}


