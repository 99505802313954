/* tslint:disable */
/* eslint-disable */
/**
 * Consolidate Legacy API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetInboxResponseItems
 */
export interface GetInboxResponseItems {
    /**
     * 
     * @type {number}
     * @memberof GetInboxResponseItems
     */
    aktid: number;
    /**
     * 
     * @type {string}
     * @memberof GetInboxResponseItems
     */
    color?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetInboxResponseItems
     */
    hasattachment: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetInboxResponseItems
     */
    icon: string;
    /**
     * 
     * @type {number}
     * @memberof GetInboxResponseItems
     */
    id: number;
    /**
     * 
     * @type {boolean}
     * @memberof GetInboxResponseItems
     */
    isspam: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetInboxResponseItems
     */
    newinfo: boolean;
    /**
     * 
     * @type {number}
     * @memberof GetInboxResponseItems
     */
    priority: number;
    /**
     * 
     * @type {string}
     * @memberof GetInboxResponseItems
     */
    received: string;
    /**
     * 
     * @type {string}
     * @memberof GetInboxResponseItems
     */
    sender: string;
    /**
     * 
     * @type {string}
     * @memberof GetInboxResponseItems
     */
    subject: string;
    /**
     * 
     * @type {string}
     * @memberof GetInboxResponseItems
     */
    summary?: string;
    /**
     * 
     * @type {string}
     * @memberof GetInboxResponseItems
     */
    resp: string;
    /**
     * 
     * @type {number}
     * @memberof GetInboxResponseItems
     */
    todotype: number;
    /**
     * 
     * @type {string}
     * @memberof GetInboxResponseItems
     */
    aktname: string;
    /**
     * 
     * @type {number}
     * @memberof GetInboxResponseItems
     */
    akttype: number;
    /**
     * 
     * @type {boolean}
     * @memberof GetInboxResponseItems
     */
    isrecurring?: boolean;
}

export function GetInboxResponseItemsFromJSON(json: any): GetInboxResponseItems {
    return GetInboxResponseItemsFromJSONTyped(json, false);
}

export function GetInboxResponseItemsFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetInboxResponseItems {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'aktid': json['aktid'],
        'color': !exists(json, 'color') ? undefined : json['color'],
        'hasattachment': json['hasattachment'],
        'icon': json['icon'],
        'id': json['id'],
        'isspam': json['isspam'],
        'newinfo': json['newinfo'],
        'priority': json['priority'],
        'received': json['received'],
        'sender': json['sender'],
        'subject': json['subject'],
        'summary': !exists(json, 'summary') ? undefined : json['summary'],
        'resp': json['resp'],
        'todotype': json['todotype'],
        'aktname': json['aktname'],
        'akttype': json['akttype'],
        'isrecurring': !exists(json, 'isrecurring') ? undefined : json['isrecurring'],
    };
}

export function GetInboxResponseItemsToJSON(value?: GetInboxResponseItems | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'aktid': value.aktid,
        'color': value.color,
        'hasattachment': value.hasattachment,
        'icon': value.icon,
        'id': value.id,
        'isspam': value.isspam,
        'newinfo': value.newinfo,
        'priority': value.priority,
        'received': value.received,
        'sender': value.sender,
        'subject': value.subject,
        'summary': value.summary,
        'resp': value.resp,
        'todotype': value.todotype,
        'aktname': value.aktname,
        'akttype': value.akttype,
        'isrecurring': value.isrecurring,
    };
}


