/* tslint:disable */
/* eslint-disable */
/**
 * Consolidate Legacy API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RegisterPushRequest
 */
export interface RegisterPushRequest {
    /**
     * 
     * @type {string}
     * @memberof RegisterPushRequest
     */
    deviceid: string;
    /**
     * 
     * @type {number}
     * @memberof RegisterPushRequest
     */
    devicetype: RegisterPushRequestDevicetypeEnum;
    /**
     * 
     * @type {string}
     * @memberof RegisterPushRequest
     */
    devicetoken: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterPushRequest
     */
    devicename?: string;
}

/**
* @export
* @enum {string}
*/
export enum RegisterPushRequestDevicetypeEnum {
    Ios = 1,
    Android = 2,
    WindowsPhone = 3,
    Browser = 4,
    Desktop = 5
}

export function RegisterPushRequestFromJSON(json: any): RegisterPushRequest {
    return RegisterPushRequestFromJSONTyped(json, false);
}

export function RegisterPushRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): RegisterPushRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'deviceid': json['deviceid'],
        'devicetype': json['devicetype'],
        'devicetoken': json['devicetoken'],
        'devicename': !exists(json, 'devicename') ? undefined : json['devicename'],
    };
}

export function RegisterPushRequestToJSON(value?: RegisterPushRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'deviceid': value.deviceid,
        'devicetype': value.devicetype,
        'devicetoken': value.devicetoken,
        'devicename': value.devicename,
    };
}


