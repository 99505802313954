/* tslint:disable */
/* eslint-disable */
/**
 * Consolidate Legacy API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetContactResponseCompanies
 */
export interface GetContactResponseCompanies {
    /**
     * 
     * @type {string}
     * @memberof GetContactResponseCompanies
     */
    adressline2?: string;
    /**
     * 
     * @type {string}
     * @memberof GetContactResponseCompanies
     */
    adressline3?: string;
    /**
     * 
     * @type {string}
     * @memberof GetContactResponseCompanies
     */
    street: string;
    /**
     * 
     * @type {string}
     * @memberof GetContactResponseCompanies
     */
    street2: string;
    /**
     * 
     * @type {string}
     * @memberof GetContactResponseCompanies
     */
    zip: string;
    /**
     * 
     * @type {string}
     * @memberof GetContactResponseCompanies
     */
    city: string;
    /**
     * 
     * @type {string}
     * @memberof GetContactResponseCompanies
     */
    country?: string;
    /**
     * 
     * @type {string}
     * @memberof GetContactResponseCompanies
     */
    countryid: string;
    /**
     * 
     * @type {number}
     * @memberof GetContactResponseCompanies
     */
    tel: number;
    /**
     * 
     * @type {string}
     * @memberof GetContactResponseCompanies
     */
    telprev: string;
    /**
     * 
     * @type {string}
     * @memberof GetContactResponseCompanies
     */
    telcomplete: string;
    /**
     * 
     * @type {string}
     * @memberof GetContactResponseCompanies
     */
    fax: string;
}

export function GetContactResponseCompaniesFromJSON(json: any): GetContactResponseCompanies {
    return GetContactResponseCompaniesFromJSONTyped(json, false);
}

export function GetContactResponseCompaniesFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetContactResponseCompanies {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'adressline2': !exists(json, 'adressline2') ? undefined : json['adressline2'],
        'adressline3': !exists(json, 'adressline3') ? undefined : json['adressline3'],
        'street': json['street'],
        'street2': json['street2'],
        'zip': json['zip'],
        'city': json['city'],
        'country': !exists(json, 'country') ? undefined : json['country'],
        'countryid': json['countryid'],
        'tel': json['tel'],
        'telprev': json['telprev'],
        'telcomplete': json['telcomplete'],
        'fax': json['fax'],
    };
}

export function GetContactResponseCompaniesToJSON(value?: GetContactResponseCompanies | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'adressline2': value.adressline2,
        'adressline3': value.adressline3,
        'street': value.street,
        'street2': value.street2,
        'zip': value.zip,
        'city': value.city,
        'country': value.country,
        'countryid': value.countryid,
        'tel': value.tel,
        'telprev': value.telprev,
        'telcomplete': value.telcomplete,
        'fax': value.fax,
    };
}


