/* tslint:disable */
/* eslint-disable */
/**
 * Consolidate Legacy API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LoginResponse
 */
export interface LoginResponse {
    /**
     * 
     * @type {string}
     * @memberof LoginResponse
     */
    uid: string;
    /**
     * 
     * @type {string}
     * @memberof LoginResponse
     */
    token: string;
    /**
     * 
     * @type {string}
     * @memberof LoginResponse
     */
    secretgroups: string;
    /**
     * 
     * @type {string}
     * @memberof LoginResponse
     */
    lang: string;
    /**
     * 
     * @type {string}
     * @memberof LoginResponse
     */
    myname: string;
}

export function LoginResponseFromJSON(json: any): LoginResponse {
    return LoginResponseFromJSONTyped(json, false);
}

export function LoginResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): LoginResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'uid': json['uid'],
        'token': json['token'],
        'secretgroups': json['secretgroups'],
        'lang': json['lang'],
        'myname': json['myname'],
    };
}

export function LoginResponseToJSON(value?: LoginResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'uid': value.uid,
        'token': value.token,
        'secretgroups': value.secretgroups,
        'lang': value.lang,
        'myname': value.myname,
    };
}


