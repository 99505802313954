/* tslint:disable */
/* eslint-disable */
/**
 * Consolidate Legacy API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetInboxRequest
 */
export interface GetInboxRequest {
    /**
     * 
     * @type {number}
     * @memberof GetInboxRequest
     */
    limit?: number;
    /**
     * 
     * @type {number}
     * @memberof GetInboxRequest
     */
    offset?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GetInboxRequest
     */
    isspam?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetInboxRequest
     */
    getcount?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetInboxRequest
     */
    divider?: boolean;
    /**
     * 
     * @type {number}
     * @memberof GetInboxRequest
     */
    hash?: number;
}

export function GetInboxRequestFromJSON(json: any): GetInboxRequest {
    return GetInboxRequestFromJSONTyped(json, false);
}

export function GetInboxRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetInboxRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'limit': !exists(json, 'limit') ? undefined : json['limit'],
        'offset': !exists(json, 'offset') ? undefined : json['offset'],
        'isspam': !exists(json, 'isspam') ? undefined : json['isspam'],
        'getcount': !exists(json, 'getcount') ? undefined : json['getcount'],
        'divider': !exists(json, 'divider') ? undefined : json['divider'],
        'hash': !exists(json, 'hash') ? undefined : json['hash'],
    };
}

export function GetInboxRequestToJSON(value?: GetInboxRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'limit': value.limit,
        'offset': value.offset,
        'isspam': value.isspam,
        'getcount': value.getcount,
        'divider': value.divider,
        'hash': value.hash,
    };
}


