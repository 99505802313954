/* tslint:disable */
/* eslint-disable */
/**
 * Consolidate Legacy API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AdditionalCommunication,
    AdditionalCommunicationFromJSON,
    AdditionalCommunicationFromJSONTyped,
    AdditionalCommunicationToJSON,
} from './';

/**
 * 
 * @export
 * @interface AddOrEditAddressRequest
 */
export interface AddOrEditAddressRequest {
    /**
     * 
     * @type {string}
     * @memberof AddOrEditAddressRequest
     */
    ANR?: string;
    /**
     * 
     * @type {string}
     * @memberof AddOrEditAddressRequest
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof AddOrEditAddressRequest
     */
    name2?: string;
    /**
     * 
     * @type {string}
     * @memberof AddOrEditAddressRequest
     */
    name3?: string;
    /**
     * 
     * @type {string}
     * @memberof AddOrEditAddressRequest
     */
    street?: string;
    /**
     * 
     * @type {string}
     * @memberof AddOrEditAddressRequest
     */
    zip?: string;
    /**
     * 
     * @type {string}
     * @memberof AddOrEditAddressRequest
     */
    city: string;
    /**
     * 
     * @type {string}
     * @memberof AddOrEditAddressRequest
     */
    state?: string;
    /**
     * 
     * @type {string}
     * @memberof AddOrEditAddressRequest
     */
    countryid?: string;
    /**
     * 
     * @type {string}
     * @memberof AddOrEditAddressRequest
     */
    pobox?: string;
    /**
     * 
     * @type {string}
     * @memberof AddOrEditAddressRequest
     */
    poboxzip?: string;
    /**
     * 
     * @type {string}
     * @memberof AddOrEditAddressRequest
     */
    telprev?: string;
    /**
     * 
     * @type {string}
     * @memberof AddOrEditAddressRequest
     */
    tel?: string;
    /**
     * 
     * @type {string}
     * @memberof AddOrEditAddressRequest
     */
    fax?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AddOrEditAddressRequest
     */
    addfavorite?: boolean;
    /**
     * 
     * @type {Array<AdditionalCommunication>}
     * @memberof AddOrEditAddressRequest
     */
    communications?: Array<AdditionalCommunication>;
}

export function AddOrEditAddressRequestFromJSON(json: any): AddOrEditAddressRequest {
    return AddOrEditAddressRequestFromJSONTyped(json, false);
}

export function AddOrEditAddressRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddOrEditAddressRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ANR': !exists(json, 'ANR') ? undefined : json['ANR'],
        'name': json['name'],
        'name2': !exists(json, 'name2') ? undefined : json['name2'],
        'name3': !exists(json, 'name3') ? undefined : json['name3'],
        'street': !exists(json, 'street') ? undefined : json['street'],
        'zip': !exists(json, 'zip') ? undefined : json['zip'],
        'city': json['city'],
        'state': !exists(json, 'state') ? undefined : json['state'],
        'countryid': !exists(json, 'countryid') ? undefined : json['countryid'],
        'pobox': !exists(json, 'pobox') ? undefined : json['pobox'],
        'poboxzip': !exists(json, 'poboxzip') ? undefined : json['poboxzip'],
        'telprev': !exists(json, 'telprev') ? undefined : json['telprev'],
        'tel': !exists(json, 'tel') ? undefined : json['tel'],
        'fax': !exists(json, 'fax') ? undefined : json['fax'],
        'addfavorite': !exists(json, 'addfavorite') ? undefined : json['addfavorite'],
        'communications': !exists(json, 'communications') ? undefined : ((json['communications'] as Array<any>).map(AdditionalCommunicationFromJSON)),
    };
}

export function AddOrEditAddressRequestToJSON(value?: AddOrEditAddressRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ANR': value.ANR,
        'name': value.name,
        'name2': value.name2,
        'name3': value.name3,
        'street': value.street,
        'zip': value.zip,
        'city': value.city,
        'state': value.state,
        'countryid': value.countryid,
        'pobox': value.pobox,
        'poboxzip': value.poboxzip,
        'telprev': value.telprev,
        'tel': value.tel,
        'fax': value.fax,
        'addfavorite': value.addfavorite,
        'communications': value.communications === undefined ? undefined : ((value.communications as Array<any>).map(AdditionalCommunicationToJSON)),
    };
}


