/* tslint:disable */
/* eslint-disable */
/**
 * Consolidate Legacy API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AdditionalCommunication,
    AdditionalCommunicationFromJSON,
    AdditionalCommunicationFromJSONTyped,
    AdditionalCommunicationToJSON,
    CustomFormField,
    CustomFormFieldFromJSON,
    CustomFormFieldFromJSONTyped,
    CustomFormFieldToJSON,
    GetContactResponseCompanies,
    GetContactResponseCompaniesFromJSON,
    GetContactResponseCompaniesFromJSONTyped,
    GetContactResponseCompaniesToJSON,
    GetContactResponseLocations,
    GetContactResponseLocationsFromJSON,
    GetContactResponseLocationsFromJSONTyped,
    GetContactResponseLocationsToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetContactResponse
 */
export interface GetContactResponse {
    /**
     * 
     * @type {string}
     * @memberof GetContactResponse
     */
    icon?: string;
    /**
     * 
     * @type {number}
     * @memberof GetContactResponse
     */
    CID: number;
    /**
     * 
     * @type {string}
     * @memberof GetContactResponse
     */
    firstname?: string;
    /**
     * 
     * @type {string}
     * @memberof GetContactResponse
     */
    lastname: string;
    /**
     * 
     * @type {string}
     * @memberof GetContactResponse
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof GetContactResponse
     */
    attention?: string;
    /**
     * 
     * @type {number}
     * @memberof GetContactResponse
     */
    gender?: number;
    /**
     * 
     * @type {string}
     * @memberof GetContactResponse
     */
    salutationpriv?: string;
    /**
     * 
     * @type {string}
     * @memberof GetContactResponse
     */
    suffix?: string;
    /**
     * 
     * @type {string}
     * @memberof GetContactResponse
     */
    ANR?: string;
    /**
     * 
     * @type {string}
     * @memberof GetContactResponse
     */
    companyname?: string;
    /**
     * 
     * @type {string}
     * @memberof GetContactResponse
     */
    department?: string;
    /**
     * 
     * @type {string}
     * @memberof GetContactResponse
     */
    function?: string;
    /**
     * 
     * @type {string}
     * @memberof GetContactResponse
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof GetContactResponse
     */
    mobile?: string;
    /**
     * 
     * @type {string}
     * @memberof GetContactResponse
     */
    info?: string;
    /**
     * 
     * @type {string}
     * @memberof GetContactResponse
     */
    infosecret?: string;
    /**
     * 
     * @type {string}
     * @memberof GetContactResponse
     */
    skypename?: string;
    /**
     * 
     * @type {string}
     * @memberof GetContactResponse
     */
    telephone?: string;
    /**
     * 
     * @type {string}
     * @memberof GetContactResponse
     */
    dateofbirth?: string;
    /**
     * 
     * @type {string}
     * @memberof GetContactResponse
     */
    telsecret?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetContactResponse
     */
    isfavorite: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetContactResponse
     */
    caneditcontact: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetContactResponse
     */
    caneditaddress: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetContactResponse
     */
    comment?: string;
    /**
     * 
     * @type {Array<AdditionalCommunication>}
     * @memberof GetContactResponse
     */
    communications?: Array<AdditionalCommunication>;
    /**
     * 
     * @type {Array<GetContactResponseCompanies>}
     * @memberof GetContactResponse
     */
    companies?: Array<GetContactResponseCompanies>;
    /**
     * 
     * @type {Array<GetContactResponseLocations>}
     * @memberof GetContactResponse
     */
    locations?: Array<GetContactResponseLocations>;
    /**
     * 
     * @type {string}
     * @memberof GetContactResponse
     */
    picture?: string;
    /**
     * 
     * @type {{ [key: string]: { [key: string]: CustomFormField; }; }}
     * @memberof GetContactResponse
     */
    custom?: { [key: string]: { [key: string]: CustomFormField; }; };
}

export function GetContactResponseFromJSON(json: any): GetContactResponse {
    return GetContactResponseFromJSONTyped(json, false);
}

export function GetContactResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetContactResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'icon': !exists(json, 'icon') ? undefined : json['icon'],
        'CID': json['CID'],
        'firstname': !exists(json, 'firstname') ? undefined : json['firstname'],
        'lastname': json['lastname'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'attention': !exists(json, 'attention') ? undefined : json['attention'],
        'gender': !exists(json, 'gender') ? undefined : json['gender'],
        'salutationpriv': !exists(json, 'salutationpriv') ? undefined : json['salutationpriv'],
        'suffix': !exists(json, 'suffix') ? undefined : json['suffix'],
        'ANR': !exists(json, 'ANR') ? undefined : json['ANR'],
        'companyname': !exists(json, 'companyname') ? undefined : json['companyname'],
        'department': !exists(json, 'department') ? undefined : json['department'],
        'function': !exists(json, 'function') ? undefined : json['function'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'mobile': !exists(json, 'mobile') ? undefined : json['mobile'],
        'info': !exists(json, 'info') ? undefined : json['info'],
        'infosecret': !exists(json, 'infosecret') ? undefined : json['infosecret'],
        'skypename': !exists(json, 'skypename') ? undefined : json['skypename'],
        'telephone': !exists(json, 'telephone') ? undefined : json['telephone'],
        'dateofbirth': !exists(json, 'dateofbirth') ? undefined : json['dateofbirth'],
        'telsecret': !exists(json, 'telsecret') ? undefined : json['telsecret'],
        'isfavorite': json['isfavorite'],
        'caneditcontact': json['caneditcontact'],
        'caneditaddress': json['caneditaddress'],
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
        'communications': !exists(json, 'communications') ? undefined : ((json['communications'] as Array<any>).map(AdditionalCommunicationFromJSON)),
        'companies': !exists(json, 'companies') ? undefined : ((json['companies'] as Array<any>).map(GetContactResponseCompaniesFromJSON)),
        'locations': !exists(json, 'locations') ? undefined : ((json['locations'] as Array<any>).map(GetContactResponseLocationsFromJSON)),
        'picture': !exists(json, 'picture') ? undefined : json['picture'],
        'custom': !exists(json, 'custom') ? undefined : json['custom'],
    };
}

export function GetContactResponseToJSON(value?: GetContactResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'icon': value.icon,
        'CID': value.CID,
        'firstname': value.firstname,
        'lastname': value.lastname,
        'title': value.title,
        'attention': value.attention,
        'gender': value.gender,
        'salutationpriv': value.salutationpriv,
        'suffix': value.suffix,
        'ANR': value.ANR,
        'companyname': value.companyname,
        'department': value.department,
        'function': value.function,
        'email': value.email,
        'mobile': value.mobile,
        'info': value.info,
        'infosecret': value.infosecret,
        'skypename': value.skypename,
        'telephone': value.telephone,
        'dateofbirth': value.dateofbirth,
        'telsecret': value.telsecret,
        'isfavorite': value.isfavorite,
        'caneditcontact': value.caneditcontact,
        'caneditaddress': value.caneditaddress,
        'comment': value.comment,
        'communications': value.communications === undefined ? undefined : ((value.communications as Array<any>).map(AdditionalCommunicationToJSON)),
        'companies': value.companies === undefined ? undefined : ((value.companies as Array<any>).map(GetContactResponseCompaniesToJSON)),
        'locations': value.locations === undefined ? undefined : ((value.locations as Array<any>).map(GetContactResponseLocationsToJSON)),
        'picture': value.picture,
        'custom': value.custom,
    };
}


