/* tslint:disable */
/* eslint-disable */
/**
 * Consolidate Legacy API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetAddressResponseContacts
 */
export interface GetAddressResponseContacts {
    /**
     * 
     * @type {number}
     * @memberof GetAddressResponseContacts
     */
    cid?: number;
    /**
     * 
     * @type {string}
     * @memberof GetAddressResponseContacts
     */
    firstname?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAddressResponseContacts
     */
    _function?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAddressResponseContacts
     */
    icon?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAddressResponseContacts
     */
    lastname?: string;
}

export function GetAddressResponseContactsFromJSON(json: any): GetAddressResponseContacts {
    return GetAddressResponseContactsFromJSONTyped(json, false);
}

export function GetAddressResponseContactsFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetAddressResponseContacts {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'cid': !exists(json, 'cid') ? undefined : json['cid'],
        'firstname': !exists(json, 'firstname') ? undefined : json['firstname'],
        '_function': !exists(json, 'function') ? undefined : json['function'],
        'icon': !exists(json, 'icon') ? undefined : json['icon'],
        'lastname': !exists(json, 'lastname') ? undefined : json['lastname'],
    };
}

export function GetAddressResponseContactsToJSON(value?: GetAddressResponseContacts | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'cid': value.cid,
        'firstname': value.firstname,
        'function': value._function,
        'icon': value.icon,
        'lastname': value.lastname,
    };
}


