/* tslint:disable */
/* eslint-disable */
/**
 * Consolidate Legacy API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetSettingsResponse
 */
export interface GetSettingsResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetSettingsResponse
     */
    allowcommentincomingmail: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetSettingsResponse
     */
    allowcommentoutgoingmail: boolean;
    /**
     * 
     * @type {number}
     * @memberof GetSettingsResponse
     */
    activityobjectlocationstrategy?: number;
    /**
     * 
     * @type {number}
     * @memberof GetSettingsResponse
     */
    activityobjectmanagerstrategy?: number;
    /**
     * 
     * @type {string}
     * @memberof GetSettingsResponse
     */
    mailaddress?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetSettingsResponse
     */
    mailalias?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof GetSettingsResponse
     */
    elasticindexversion?: number | null;
}

export function GetSettingsResponseFromJSON(json: any): GetSettingsResponse {
    return GetSettingsResponseFromJSONTyped(json, false);
}

export function GetSettingsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetSettingsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'allowcommentincomingmail': json['allowcommentincomingmail'],
        'allowcommentoutgoingmail': json['allowcommentoutgoingmail'],
        'activityobjectlocationstrategy': !exists(json, 'activityobjectlocationstrategy') ? undefined : json['activityobjectlocationstrategy'],
        'activityobjectmanagerstrategy': !exists(json, 'activityobjectmanagerstrategy') ? undefined : json['activityobjectmanagerstrategy'],
        'mailaddress': !exists(json, 'mailaddress') ? undefined : json['mailaddress'],
        'mailalias': !exists(json, 'mailalias') ? undefined : json['mailalias'],
        'elasticindexversion': !exists(json, 'elasticindexversion') ? undefined : json['elasticindexversion'],
    };
}

export function GetSettingsResponseToJSON(value?: GetSettingsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'allowcommentincomingmail': value.allowcommentincomingmail,
        'allowcommentoutgoingmail': value.allowcommentoutgoingmail,
        'activityobjectlocationstrategy': value.activityobjectlocationstrategy,
        'activityobjectmanagerstrategy': value.activityobjectmanagerstrategy,
        'mailaddress': value.mailaddress,
        'mailalias': value.mailalias,
        'elasticindexversion': value.elasticindexversion,
    };
}


