/* tslint:disable */
/* eslint-disable */
/**
 * Consolidate Legacy API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    FindActivityResponseItems,
    FindActivityResponseItemsFromJSON,
    FindActivityResponseItemsFromJSONTyped,
    FindActivityResponseItemsToJSON,
} from './';

/**
 * 
 * @export
 * @interface FindActivityResponse
 */
export interface FindActivityResponse {
    /**
     * 
     * @type {number}
     * @memberof FindActivityResponse
     */
    found: number;
    /**
     * 
     * @type {number}
     * @memberof FindActivityResponse
     */
    took?: number;
    /**
     * 
     * @type {Array<FindActivityResponseItems>}
     * @memberof FindActivityResponse
     */
    items: Array<FindActivityResponseItems>;
}

export function FindActivityResponseFromJSON(json: any): FindActivityResponse {
    return FindActivityResponseFromJSONTyped(json, false);
}

export function FindActivityResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FindActivityResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'found': json['found'],
        'took': !exists(json, 'took') ? undefined : json['took'],
        'items': ((json['items'] as Array<any>).map(FindActivityResponseItemsFromJSON)),
    };
}

export function FindActivityResponseToJSON(value?: FindActivityResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'found': value.found,
        'took': value.took,
        'items': ((value.items as Array<any>).map(FindActivityResponseItemsToJSON)),
    };
}


