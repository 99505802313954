/* tslint:disable */
/* eslint-disable */
/**
 * Consolidate Legacy API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetContactItemContact
 */
export interface GetContactItemContact {
    /**
     * 
     * @type {string}
     * @memberof GetContactItemContact
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof GetContactItemContact
     */
    cid: number;
    /**
     * 
     * @type {string}
     * @memberof GetContactItemContact
     */
    icon: string;
    /**
     * 
     * @type {string}
     * @memberof GetContactItemContact
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof GetContactItemContact
     */
    firstname?: string;
    /**
     * 
     * @type {string}
     * @memberof GetContactItemContact
     */
    lastname: string;
    /**
     * 
     * @type {string}
     * @memberof GetContactItemContact
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof GetContactItemContact
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof GetContactItemContact
     */
    mobile?: string;
    /**
     * 
     * @type {string}
     * @memberof GetContactItemContact
     */
    suffix?: string;
    /**
     * 
     * @type {string}
     * @memberof GetContactItemContact
     */
    companyname?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetContactItemContact
     */
    isfavorite: boolean;
}

export function GetContactItemContactFromJSON(json: any): GetContactItemContact {
    return GetContactItemContactFromJSONTyped(json, false);
}

export function GetContactItemContactFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetContactItemContact {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'cid': json['cid'],
        'icon': json['icon'],
        'city': !exists(json, 'city') ? undefined : json['city'],
        'firstname': !exists(json, 'firstname') ? undefined : json['firstname'],
        'lastname': json['lastname'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'mobile': !exists(json, 'mobile') ? undefined : json['mobile'],
        'suffix': !exists(json, 'suffix') ? undefined : json['suffix'],
        'companyname': !exists(json, 'companyname') ? undefined : json['companyname'],
        'isfavorite': json['isfavorite'],
    };
}

export function GetContactItemContactToJSON(value?: GetContactItemContact | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'cid': value.cid,
        'icon': value.icon,
        'city': value.city,
        'firstname': value.firstname,
        'lastname': value.lastname,
        'title': value.title,
        'email': value.email,
        'mobile': value.mobile,
        'suffix': value.suffix,
        'companyname': value.companyname,
        'isfavorite': value.isfavorite,
    };
}


