/* tslint:disable */
/* eslint-disable */
/**
 * Consolidate Legacy API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetUserTimelineResponseItems
 */
export interface GetUserTimelineResponseItems {
    /**
     * 
     * @type {number}
     * @memberof GetUserTimelineResponseItems
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof GetUserTimelineResponseItems
     */
    aktid?: number;
    /**
     * 
     * @type {string}
     * @memberof GetUserTimelineResponseItems
     */
    type: string;
    /**
     * 
     * @type {string}
     * @memberof GetUserTimelineResponseItems
     */
    date: string;
    /**
     * 
     * @type {number}
     * @memberof GetUserTimelineResponseItems
     */
    status: number;
    /**
     * 
     * @type {string}
     * @memberof GetUserTimelineResponseItems
     */
    start: string;
    /**
     * 
     * @type {string}
     * @memberof GetUserTimelineResponseItems
     */
    end: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetUserTimelineResponseItems
     */
    noStartTime: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetUserTimelineResponseItems
     */
    noEndTime: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetUserTimelineResponseItems
     */
    subject: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetUserTimelineResponseItems
     */
    isrecurring: boolean;
    /**
     * 
     * @type {number}
     * @memberof GetUserTimelineResponseItems
     */
    blocktype?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GetUserTimelineResponseItems
     */
    isconfidential: boolean;
    /**
     * 
     * @type {number}
     * @memberof GetUserTimelineResponseItems
     */
    icon?: number;
    /**
     * 
     * @type {string}
     * @memberof GetUserTimelineResponseItems
     */
    backcolor: string;
    /**
     * 
     * @type {string}
     * @memberof GetUserTimelineResponseItems
     */
    forecolor: string;
    /**
     * 
     * @type {string}
     * @memberof GetUserTimelineResponseItems
     */
    eventstart: string;
    /**
     * 
     * @type {string}
     * @memberof GetUserTimelineResponseItems
     */
    eventend: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetUserTimelineResponseItems
     */
    isholiday: boolean;
    /**
     * 
     * @type {number}
     * @memberof GetUserTimelineResponseItems
     */
    height: number;
}

export function GetUserTimelineResponseItemsFromJSON(json: any): GetUserTimelineResponseItems {
    return GetUserTimelineResponseItemsFromJSONTyped(json, false);
}

export function GetUserTimelineResponseItemsFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetUserTimelineResponseItems {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'aktid': !exists(json, 'aktid') ? undefined : json['aktid'],
        'type': json['type'],
        'date': json['date'],
        'status': json['status'],
        'start': json['start'],
        'end': json['end'],
        'noStartTime': json['NoStartTime'],
        'noEndTime': json['NoEndTime'],
        'subject': json['subject'],
        'isrecurring': json['isrecurring'],
        'blocktype': !exists(json, 'blocktype') ? undefined : json['blocktype'],
        'isconfidential': json['isconfidential'],
        'icon': !exists(json, 'icon') ? undefined : json['icon'],
        'backcolor': json['backcolor'],
        'forecolor': json['forecolor'],
        'eventstart': json['eventstart'],
        'eventend': json['eventend'],
        'isholiday': json['isholiday'],
        'height': json['height'],
    };
}

export function GetUserTimelineResponseItemsToJSON(value?: GetUserTimelineResponseItems | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'aktid': value.aktid,
        'type': value.type,
        'date': value.date,
        'status': value.status,
        'start': value.start,
        'end': value.end,
        'NoStartTime': value.noStartTime,
        'NoEndTime': value.noEndTime,
        'subject': value.subject,
        'isrecurring': value.isrecurring,
        'blocktype': value.blocktype,
        'isconfidential': value.isconfidential,
        'icon': value.icon,
        'backcolor': value.backcolor,
        'forecolor': value.forecolor,
        'eventstart': value.eventstart,
        'eventend': value.eventend,
        'isholiday': value.isholiday,
        'height': value.height,
    };
}


