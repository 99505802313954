/* tslint:disable */
/* eslint-disable */
/**
 * Consolidate Legacy API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetTodoItemTodo
 */
export interface GetTodoItemTodo {
    /**
     * 
     * @type {number}
     * @memberof GetTodoItemTodo
     */
    aktid: number;
    /**
     * 
     * @type {string}
     * @memberof GetTodoItemTodo
     */
    aktname: string;
    /**
     * 
     * @type {number}
     * @memberof GetTodoItemTodo
     */
    akttype: number;
    /**
     * 
     * @type {string}
     * @memberof GetTodoItemTodo
     */
    color: string;
    /**
     * 
     * @type {string}
     * @memberof GetTodoItemTodo
     */
    contact: string;
    /**
     * 
     * @type {string}
     * @memberof GetTodoItemTodo
     */
    duedate: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetTodoItemTodo
     */
    hasattachment: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetTodoItemTodo
     */
    haswarntime: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetTodoItemTodo
     */
    icon: string;
    /**
     * 
     * @type {number}
     * @memberof GetTodoItemTodo
     */
    priority: number;
    /**
     * 
     * @type {string}
     * @memberof GetTodoItemTodo
     */
    subject: string;
    /**
     * 
     * @type {string}
     * @memberof GetTodoItemTodo
     */
    summary?: string;
    /**
     * 
     * @type {number}
     * @memberof GetTodoItemTodo
     */
    todotype: GetTodoItemTodoTodotypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof GetTodoItemTodo
     */
    isrecurring?: boolean;
}

/**
* @export
* @enum {string}
*/
export enum GetTodoItemTodoTodotypeEnum {
    ToDo = 0,
    Waiting = 1,
    ReadingMaterial = 2,
    PermanentDocument = 3,
    Private = 4,
    WaitingForProcessCondition = 5
}

export function GetTodoItemTodoFromJSON(json: any): GetTodoItemTodo {
    return GetTodoItemTodoFromJSONTyped(json, false);
}

export function GetTodoItemTodoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetTodoItemTodo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'aktid': json['aktid'],
        'aktname': json['aktname'],
        'akttype': json['akttype'],
        'color': json['color'],
        'contact': json['contact'],
        'duedate': json['duedate'],
        'hasattachment': json['hasattachment'],
        'haswarntime': json['haswarntime'],
        'icon': json['icon'],
        'priority': json['priority'],
        'subject': json['subject'],
        'summary': !exists(json, 'summary') ? undefined : json['summary'],
        'todotype': json['todotype'],
        'isrecurring': !exists(json, 'isrecurring') ? undefined : json['isrecurring'],
    };
}

export function GetTodoItemTodoToJSON(value?: GetTodoItemTodo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'aktid': value.aktid,
        'aktname': value.aktname,
        'akttype': value.akttype,
        'color': value.color,
        'contact': value.contact,
        'duedate': value.duedate,
        'hasattachment': value.hasattachment,
        'haswarntime': value.haswarntime,
        'icon': value.icon,
        'priority': value.priority,
        'subject': value.subject,
        'summary': value.summary,
        'todotype': value.todotype,
        'isrecurring': value.isrecurring,
    };
}


