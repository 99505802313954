/* tslint:disable */
/* eslint-disable */
/**
 * Consolidate Legacy API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GetAktMemberDefault,
    GetAktMemberDefaultFromJSON,
    GetAktMemberDefaultFromJSONTyped,
    GetAktMemberDefaultToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetAktResponseDocumentAllOfMembers
 */
export interface GetAktResponseDocumentAllOfMembers {
    /**
     * 
     * @type {Array<GetAktMemberDefault>}
     * @memberof GetAktResponseDocumentAllOfMembers
     */
    addresslist?: Array<GetAktMemberDefault>;
    /**
     * 
     * @type {Array<GetAktMemberDefault>}
     * @memberof GetAktResponseDocumentAllOfMembers
     */
    clients?: Array<GetAktMemberDefault>;
    /**
     * 
     * @type {Array<GetAktMemberDefault>}
     * @memberof GetAktResponseDocumentAllOfMembers
     */
    participants?: Array<GetAktMemberDefault>;
}

export function GetAktResponseDocumentAllOfMembersFromJSON(json: any): GetAktResponseDocumentAllOfMembers {
    return GetAktResponseDocumentAllOfMembersFromJSONTyped(json, false);
}

export function GetAktResponseDocumentAllOfMembersFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetAktResponseDocumentAllOfMembers {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'addresslist': !exists(json, 'addresslist') ? undefined : ((json['addresslist'] as Array<any>).map(GetAktMemberDefaultFromJSON)),
        'clients': !exists(json, 'clients') ? undefined : ((json['clients'] as Array<any>).map(GetAktMemberDefaultFromJSON)),
        'participants': !exists(json, 'participants') ? undefined : ((json['participants'] as Array<any>).map(GetAktMemberDefaultFromJSON)),
    };
}

export function GetAktResponseDocumentAllOfMembersToJSON(value?: GetAktResponseDocumentAllOfMembers | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'addresslist': value.addresslist === undefined ? undefined : ((value.addresslist as Array<any>).map(GetAktMemberDefaultToJSON)),
        'clients': value.clients === undefined ? undefined : ((value.clients as Array<any>).map(GetAktMemberDefaultToJSON)),
        'participants': value.participants === undefined ? undefined : ((value.participants as Array<any>).map(GetAktMemberDefaultToJSON)),
    };
}


