/* tslint:disable */
/* eslint-disable */
/**
 * Consolidate Legacy API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WorkflowDoNextStepRequest
 */
export interface WorkflowDoNextStepRequest {
    /**
     * 
     * @type {number}
     * @memberof WorkflowDoNextStepRequest
     */
    aktid: number;
    /**
     * 
     * @type {boolean}
     * @memberof WorkflowDoNextStepRequest
     */
    decision?: boolean;
    /**
     * 
     * @type {string}
     * @memberof WorkflowDoNextStepRequest
     */
    newcomment?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkflowDoNextStepRequest
     */
    targetuserid?: string;
}

export function WorkflowDoNextStepRequestFromJSON(json: any): WorkflowDoNextStepRequest {
    return WorkflowDoNextStepRequestFromJSONTyped(json, false);
}

export function WorkflowDoNextStepRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkflowDoNextStepRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'aktid': json['aktid'],
        'decision': !exists(json, 'decision') ? undefined : json['decision'],
        'newcomment': !exists(json, 'newcomment') ? undefined : json['newcomment'],
        'targetuserid': !exists(json, 'targetuserid') ? undefined : json['targetuserid'],
    };
}

export function WorkflowDoNextStepRequestToJSON(value?: WorkflowDoNextStepRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'aktid': value.aktid,
        'decision': value.decision,
        'newcomment': value.newcomment,
        'targetuserid': value.targetuserid,
    };
}


