/* tslint:disable */
/* eslint-disable */
/**
 * Consolidate Legacy API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetApiInfoResponse
 */
export interface GetApiInfoResponse {
    /**
     * 
     * @type {string}
     * @memberof GetApiInfoResponse
     */
    version: string;
    /**
     * 
     * @type {string}
     * @memberof GetApiInfoResponse
     */
    status: string;
}

export function GetApiInfoResponseFromJSON(json: any): GetApiInfoResponse {
    return GetApiInfoResponseFromJSONTyped(json, false);
}

export function GetApiInfoResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetApiInfoResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'version': json['version'],
        'status': json['status'],
    };
}

export function GetApiInfoResponseToJSON(value?: GetApiInfoResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'version': value.version,
        'status': value.status,
    };
}


