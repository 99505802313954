/* tslint:disable */
/* eslint-disable */
/**
 * Consolidate Legacy API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AdditionalCommunication,
    AdditionalCommunicationFromJSON,
    AdditionalCommunicationFromJSONTyped,
    AdditionalCommunicationToJSON,
    CustomFormField,
    CustomFormFieldFromJSON,
    CustomFormFieldFromJSONTyped,
    CustomFormFieldToJSON,
    GetAddressResponseContacts,
    GetAddressResponseContactsFromJSON,
    GetAddressResponseContactsFromJSONTyped,
    GetAddressResponseContactsToJSON,
    GetAddressResponseObjects,
    GetAddressResponseObjectsFromJSON,
    GetAddressResponseObjectsFromJSONTyped,
    GetAddressResponseObjectsToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetAddressResponse
 */
export interface GetAddressResponse {
    /**
     * 
     * @type {string}
     * @memberof GetAddressResponse
     */
    icon?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetAddressResponse
     */
    active: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetAddressResponse
     */
    ANR: string;
    /**
     * 
     * @type {string}
     * @memberof GetAddressResponse
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof GetAddressResponse
     */
    name2?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAddressResponse
     */
    name3?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAddressResponse
     */
    companyname?: string;
    /**
     * 
     * @type {Array<GetAddressResponseContacts>}
     * @memberof GetAddressResponse
     */
    contacts?: Array<GetAddressResponseContacts>;
    /**
     * 
     * @type {string}
     * @memberof GetAddressResponse
     */
    street?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAddressResponse
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAddressResponse
     */
    zip?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAddressResponse
     */
    state?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAddressResponse
     */
    country?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAddressResponse
     */
    countryid?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAddressResponse
     */
    pobox?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAddressResponse
     */
    poboxzip?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetAddressResponse
     */
    caneditcontact: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetAddressResponse
     */
    caneditaddress: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetAddressResponse
     */
    tel?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAddressResponse
     */
    telprev?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAddressResponse
     */
    telcomplete?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAddressResponse
     */
    fax?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAddressResponse
     */
    lastChange?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetAddressResponse
     */
    isfavorite: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetAddressResponse
     */
    comment?: string;
    /**
     * 
     * @type {Array<AdditionalCommunication>}
     * @memberof GetAddressResponse
     */
    communications?: Array<AdditionalCommunication>;
    /**
     * 
     * @type {{ [key: string]: { [key: string]: CustomFormField; }; }}
     * @memberof GetAddressResponse
     */
    custom?: { [key: string]: { [key: string]: CustomFormField; }; };
    /**
     * 
     * @type {Array<GetAddressResponseObjects>}
     * @memberof GetAddressResponse
     */
    objects?: Array<GetAddressResponseObjects>;
}

export function GetAddressResponseFromJSON(json: any): GetAddressResponse {
    return GetAddressResponseFromJSONTyped(json, false);
}

export function GetAddressResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetAddressResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'icon': !exists(json, 'icon') ? undefined : json['icon'],
        'active': json['active'],
        'ANR': json['ANR'],
        'name': json['name'],
        'name2': !exists(json, 'name2') ? undefined : json['name2'],
        'name3': !exists(json, 'name3') ? undefined : json['name3'],
        'companyname': !exists(json, 'companyname') ? undefined : json['companyname'],
        'contacts': !exists(json, 'contacts') ? undefined : ((json['contacts'] as Array<any>).map(GetAddressResponseContactsFromJSON)),
        'street': !exists(json, 'street') ? undefined : json['street'],
        'city': !exists(json, 'city') ? undefined : json['city'],
        'zip': !exists(json, 'zip') ? undefined : json['zip'],
        'state': !exists(json, 'state') ? undefined : json['state'],
        'country': !exists(json, 'country') ? undefined : json['country'],
        'countryid': !exists(json, 'countryid') ? undefined : json['countryid'],
        'pobox': !exists(json, 'pobox') ? undefined : json['pobox'],
        'poboxzip': !exists(json, 'poboxzip') ? undefined : json['poboxzip'],
        'caneditcontact': json['caneditcontact'],
        'caneditaddress': json['caneditaddress'],
        'tel': !exists(json, 'tel') ? undefined : json['tel'],
        'telprev': !exists(json, 'telprev') ? undefined : json['telprev'],
        'telcomplete': !exists(json, 'telcomplete') ? undefined : json['telcomplete'],
        'fax': !exists(json, 'fax') ? undefined : json['fax'],
        'lastChange': !exists(json, 'LastChange') ? undefined : json['LastChange'],
        'isfavorite': json['isfavorite'],
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
        'communications': !exists(json, 'communications') ? undefined : ((json['communications'] as Array<any>).map(AdditionalCommunicationFromJSON)),
        'custom': !exists(json, 'custom') ? undefined : json['custom'],
        'objects': !exists(json, 'objects') ? undefined : ((json['objects'] as Array<any>).map(GetAddressResponseObjectsFromJSON)),
    };
}

export function GetAddressResponseToJSON(value?: GetAddressResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'icon': value.icon,
        'active': value.active,
        'ANR': value.ANR,
        'name': value.name,
        'name2': value.name2,
        'name3': value.name3,
        'companyname': value.companyname,
        'contacts': value.contacts === undefined ? undefined : ((value.contacts as Array<any>).map(GetAddressResponseContactsToJSON)),
        'street': value.street,
        'city': value.city,
        'zip': value.zip,
        'state': value.state,
        'country': value.country,
        'countryid': value.countryid,
        'pobox': value.pobox,
        'poboxzip': value.poboxzip,
        'caneditcontact': value.caneditcontact,
        'caneditaddress': value.caneditaddress,
        'tel': value.tel,
        'telprev': value.telprev,
        'telcomplete': value.telcomplete,
        'fax': value.fax,
        'LastChange': value.lastChange,
        'isfavorite': value.isfavorite,
        'comment': value.comment,
        'communications': value.communications === undefined ? undefined : ((value.communications as Array<any>).map(AdditionalCommunicationToJSON)),
        'custom': value.custom,
        'objects': value.objects === undefined ? undefined : ((value.objects as Array<any>).map(GetAddressResponseObjectsToJSON)),
    };
}


