/* tslint:disable */
/* eslint-disable */
/**
 * Consolidate Legacy API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetSignatureResponse
 */
export interface GetSignatureResponse {
    /**
     * 
     * @type {string}
     * @memberof GetSignatureResponse
     */
    text: string;
    /**
     * 
     * @type {string}
     * @memberof GetSignatureResponse
     */
    filledsignature?: string;
    /**
     * 
     * @type {string}
     * @memberof GetSignatureResponse
     */
    filleddisclaimer?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetSignatureResponse
     */
    editappsignature?: boolean;
}

export function GetSignatureResponseFromJSON(json: any): GetSignatureResponse {
    return GetSignatureResponseFromJSONTyped(json, false);
}

export function GetSignatureResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetSignatureResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'text': json['text'],
        'filledsignature': !exists(json, 'filledsignature') ? undefined : json['filledsignature'],
        'filleddisclaimer': !exists(json, 'filleddisclaimer') ? undefined : json['filleddisclaimer'],
        'editappsignature': !exists(json, 'editappsignature') ? undefined : json['editappsignature'],
    };
}

export function GetSignatureResponseToJSON(value?: GetSignatureResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'text': value.text,
        'filledsignature': value.filledsignature,
        'filleddisclaimer': value.filleddisclaimer,
        'editappsignature': value.editappsignature,
    };
}


