/* tslint:disable */
/* eslint-disable */
/**
 * Consolidate Legacy API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InlineObject19
 */
export interface InlineObject19 {
    /**
     * 
     * @type {number}
     * @memberof InlineObject19
     */
    aktid: number;
    /**
     * 
     * @type {boolean}
     * @memberof InlineObject19
     */
    wholeday: boolean;
    /**
     * 
     * @type {string}
     * @memberof InlineObject19
     */
    starttime?: string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject19
     */
    startdate?: string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject19
     */
    endtime?: string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject19
     */
    enddate?: string;
    /**
     * 
     * @type {boolean}
     * @memberof InlineObject19
     */
    updatereservations?: boolean;
}

export function InlineObject19FromJSON(json: any): InlineObject19 {
    return InlineObject19FromJSONTyped(json, false);
}

export function InlineObject19FromJSONTyped(json: any, ignoreDiscriminator: boolean): InlineObject19 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'aktid': json['aktid'],
        'wholeday': json['wholeday'],
        'starttime': !exists(json, 'starttime') ? undefined : json['starttime'],
        'startdate': !exists(json, 'startdate') ? undefined : json['startdate'],
        'endtime': !exists(json, 'endtime') ? undefined : json['endtime'],
        'enddate': !exists(json, 'enddate') ? undefined : json['enddate'],
        'updatereservations': !exists(json, 'updatereservations') ? undefined : json['updatereservations'],
    };
}

export function InlineObject19ToJSON(value?: InlineObject19 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'aktid': value.aktid,
        'wholeday': value.wholeday,
        'starttime': value.starttime,
        'startdate': value.startdate,
        'endtime': value.endtime,
        'enddate': value.enddate,
        'updatereservations': value.updatereservations,
    };
}


