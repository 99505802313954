/* tslint:disable */
/* eslint-disable */
/**
 * Consolidate Legacy API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetSecretGroupsResponseItems
 */
export interface GetSecretGroupsResponseItems {
    /**
     * 
     * @type {number}
     * @memberof GetSecretGroupsResponseItems
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof GetSecretGroupsResponseItems
     */
    secName: string;
}

export function GetSecretGroupsResponseItemsFromJSON(json: any): GetSecretGroupsResponseItems {
    return GetSecretGroupsResponseItemsFromJSONTyped(json, false);
}

export function GetSecretGroupsResponseItemsFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetSecretGroupsResponseItems {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'secName': json['SecName'],
    };
}

export function GetSecretGroupsResponseItemsToJSON(value?: GetSecretGroupsResponseItems | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'SecName': value.secName,
    };
}


