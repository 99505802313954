/* tslint:disable */
/* eslint-disable */
/**
 * Consolidate Legacy API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetAktResponseAppointmentAllOfAttendees
 */
export interface GetAktResponseAppointmentAllOfAttendees {
    /**
     * 
     * @type {boolean | string}
     * @memberof GetAktResponseAppointmentAllOfAttendees
     */
    confirmation: boolean | string;
    /**
     * 
     * @type {number}
     * @memberof GetAktResponseAppointmentAllOfAttendees
     */
    confirmationstatus: number;
    /**
     * 
     * @type {string}
     * @memberof GetAktResponseAppointmentAllOfAttendees
     */
    exclusiondates?: string;
    /**
     * 
     * @type {number}
     * @memberof GetAktResponseAppointmentAllOfAttendees
     */
    cid?: number;
    /**
     * 
     * @type {string}
     * @memberof GetAktResponseAppointmentAllOfAttendees
     */
    uid?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAktResponseAppointmentAllOfAttendees
     */
    username: string;
}

export function GetAktResponseAppointmentAllOfAttendeesFromJSON(json: any): GetAktResponseAppointmentAllOfAttendees {
    return GetAktResponseAppointmentAllOfAttendeesFromJSONTyped(json, false);
}

export function GetAktResponseAppointmentAllOfAttendeesFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetAktResponseAppointmentAllOfAttendees {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'confirmation': json['confirmation'],
        'confirmationstatus': json['confirmationstatus'],
        'exclusiondates': !exists(json, 'exclusiondates') ? undefined : json['exclusiondates'],
        'cid': !exists(json, 'cid') ? undefined : json['cid'],
        'uid': !exists(json, 'uid') ? undefined : json['uid'],
        'username': json['username'],
    };
}

export function GetAktResponseAppointmentAllOfAttendeesToJSON(value?: GetAktResponseAppointmentAllOfAttendees | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'confirmation': value.confirmation,
        'confirmationstatus': value.confirmationstatus,
        'exclusiondates': value.exclusiondates,
        'cid': value.cid,
        'uid': value.uid,
        'username': value.username,
    };
}


