/* tslint:disable */
/* eslint-disable */
/**
 * Consolidate Legacy API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GetAktMemberDefault,
    GetAktMemberDefaultFromJSON,
    GetAktMemberDefaultFromJSONTyped,
    GetAktMemberDefaultToJSON,
    GetAktResponseEmailAllOfMembersAddresslist,
    GetAktResponseEmailAllOfMembersAddresslistFromJSON,
    GetAktResponseEmailAllOfMembersAddresslistFromJSONTyped,
    GetAktResponseEmailAllOfMembersAddresslistToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetAktResponseEmailAllOfMembers
 */
export interface GetAktResponseEmailAllOfMembers {
    /**
     * 
     * @type {Array<GetAktResponseEmailAllOfMembersAddresslist>}
     * @memberof GetAktResponseEmailAllOfMembers
     */
    addresslist?: Array<GetAktResponseEmailAllOfMembersAddresslist>;
    /**
     * 
     * @type {Array<GetAktMemberDefault>}
     * @memberof GetAktResponseEmailAllOfMembers
     */
    bcc?: Array<GetAktMemberDefault>;
    /**
     * 
     * @type {Array<GetAktMemberDefault>}
     * @memberof GetAktResponseEmailAllOfMembers
     */
    cc?: Array<GetAktMemberDefault>;
    /**
     * 
     * @type {Array<GetAktMemberDefault>}
     * @memberof GetAktResponseEmailAllOfMembers
     */
    clients?: Array<GetAktMemberDefault>;
    /**
     * 
     * @type {Array<GetAktMemberDefault>}
     * @memberof GetAktResponseEmailAllOfMembers
     */
    from?: Array<GetAktMemberDefault>;
    /**
     * 
     * @type {Array<GetAktMemberDefault>}
     * @memberof GetAktResponseEmailAllOfMembers
     */
    to?: Array<GetAktMemberDefault>;
}

export function GetAktResponseEmailAllOfMembersFromJSON(json: any): GetAktResponseEmailAllOfMembers {
    return GetAktResponseEmailAllOfMembersFromJSONTyped(json, false);
}

export function GetAktResponseEmailAllOfMembersFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetAktResponseEmailAllOfMembers {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'addresslist': !exists(json, 'addresslist') ? undefined : ((json['addresslist'] as Array<any>).map(GetAktResponseEmailAllOfMembersAddresslistFromJSON)),
        'bcc': !exists(json, 'bcc') ? undefined : ((json['bcc'] as Array<any>).map(GetAktMemberDefaultFromJSON)),
        'cc': !exists(json, 'cc') ? undefined : ((json['cc'] as Array<any>).map(GetAktMemberDefaultFromJSON)),
        'clients': !exists(json, 'clients') ? undefined : ((json['clients'] as Array<any>).map(GetAktMemberDefaultFromJSON)),
        'from': !exists(json, 'from') ? undefined : ((json['from'] as Array<any>).map(GetAktMemberDefaultFromJSON)),
        'to': !exists(json, 'to') ? undefined : ((json['to'] as Array<any>).map(GetAktMemberDefaultFromJSON)),
    };
}

export function GetAktResponseEmailAllOfMembersToJSON(value?: GetAktResponseEmailAllOfMembers | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'addresslist': value.addresslist === undefined ? undefined : ((value.addresslist as Array<any>).map(GetAktResponseEmailAllOfMembersAddresslistToJSON)),
        'bcc': value.bcc === undefined ? undefined : ((value.bcc as Array<any>).map(GetAktMemberDefaultToJSON)),
        'cc': value.cc === undefined ? undefined : ((value.cc as Array<any>).map(GetAktMemberDefaultToJSON)),
        'clients': value.clients === undefined ? undefined : ((value.clients as Array<any>).map(GetAktMemberDefaultToJSON)),
        'from': value.from === undefined ? undefined : ((value.from as Array<any>).map(GetAktMemberDefaultToJSON)),
        'to': value.to === undefined ? undefined : ((value.to as Array<any>).map(GetAktMemberDefaultToJSON)),
    };
}


