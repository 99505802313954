/* tslint:disable */
/* eslint-disable */
/**
 * Consolidate Legacy API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InlineObject20
 */
export interface InlineObject20 {
    /**
     * 
     * @type {number}
     * @memberof InlineObject20
     */
    aktid: number;
    /**
     * 
     * @type {string}
     * @memberof InlineObject20
     */
    fileName: string;
    /**
     * 
     * @type {number}
     * @memberof InlineObject20
     */
    fileSize: number;
    /**
     * 
     * @type {string}
     * @memberof InlineObject20
     */
    fileType: string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject20
     */
    base64: string;
}

export function InlineObject20FromJSON(json: any): InlineObject20 {
    return InlineObject20FromJSONTyped(json, false);
}

export function InlineObject20FromJSONTyped(json: any, ignoreDiscriminator: boolean): InlineObject20 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'aktid': json['aktid'],
        'fileName': json['fileName'],
        'fileSize': json['fileSize'],
        'fileType': json['fileType'],
        'base64': json['base64'],
    };
}

export function InlineObject20ToJSON(value?: InlineObject20 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'aktid': value.aktid,
        'fileName': value.fileName,
        'fileSize': value.fileSize,
        'fileType': value.fileType,
        'base64': value.base64,
    };
}


