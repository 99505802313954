/* tslint:disable */
/* eslint-disable */
/**
 * Consolidate Legacy API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InlineObject13
 */
export interface InlineObject13 {
    /**
     * 
     * @type {number}
     * @memberof InlineObject13
     */
    aktid: number;
    /**
     * 
     * @type {string}
     * @memberof InlineObject13
     */
    internalCCs: string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject13
     */
    cCcomment?: string;
}

export function InlineObject13FromJSON(json: any): InlineObject13 {
    return InlineObject13FromJSONTyped(json, false);
}

export function InlineObject13FromJSONTyped(json: any, ignoreDiscriminator: boolean): InlineObject13 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'aktid': json['aktid'],
        'internalCCs': json['internalCCs'],
        'cCcomment': !exists(json, 'CCcomment') ? undefined : json['CCcomment'],
    };
}

export function InlineObject13ToJSON(value?: InlineObject13 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'aktid': value.aktid,
        'internalCCs': value.internalCCs,
        'CCcomment': value.cCcomment,
    };
}


