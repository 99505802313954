/* tslint:disable */
/* eslint-disable */
/**
 * Consolidate Legacy API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CustomFormField,
    CustomFormFieldFromJSON,
    CustomFormFieldFromJSONTyped,
    CustomFormFieldToJSON,
    GetAktTypesResponseWorkflows,
    GetAktTypesResponseWorkflowsFromJSON,
    GetAktTypesResponseWorkflowsFromJSONTyped,
    GetAktTypesResponseWorkflowsToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetAktTypesResponseItems
 */
export interface GetAktTypesResponseItems {
    /**
     * 
     * @type {number}
     * @memberof GetAktTypesResponseItems
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof GetAktTypesResponseItems
     */
    icon: number;
    /**
     * 
     * @type {string}
     * @memberof GetAktTypesResponseItems
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof GetAktTypesResponseItems
     */
    color: string;
    /**
     * 
     * @type {number}
     * @memberof GetAktTypesResponseItems
     */
    artsakt: number;
    /**
     * 
     * @type {boolean}
     * @memberof GetAktTypesResponseItems
     */
    allowdocs: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetAktTypesResponseItems
     */
    allowobjects?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetAktTypesResponseItems
     */
    allowmultipledocs?: boolean;
    /**
     * 
     * @type {number}
     * @memberof GetAktTypesResponseItems
     */
    secretgroup: number;
    /**
     * 
     * @type {boolean}
     * @memberof GetAktTypesResponseItems
     */
    defaultanswer: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetAktTypesResponseItems
     */
    defaultanswerall: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetAktTypesResponseItems
     */
    defaultforward: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetAktTypesResponseItems
     */
    needsproject: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetAktTypesResponseItems
     */
    cannotchangeproject: boolean;
    /**
     * 
     * @type {number}
     * @memberof GetAktTypesResponseItems
     */
    defaultprojectid: number;
    /**
     * 
     * @type {string}
     * @memberof GetAktTypesResponseItems
     */
    defaultprojectname: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetAktTypesResponseItems
     */
    active?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetAktTypesResponseItems
     */
    hideonmobile?: boolean;
    /**
     * 
     * @type {Array<GetAktTypesResponseWorkflows>}
     * @memberof GetAktTypesResponseItems
     */
    workflows?: Array<GetAktTypesResponseWorkflows>;
    /**
     * 
     * @type {{ [key: string]: { [key: string]: CustomFormField; }; }}
     * @memberof GetAktTypesResponseItems
     */
    custom?: { [key: string]: { [key: string]: CustomFormField; }; };
}

export function GetAktTypesResponseItemsFromJSON(json: any): GetAktTypesResponseItems {
    return GetAktTypesResponseItemsFromJSONTyped(json, false);
}

export function GetAktTypesResponseItemsFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetAktTypesResponseItems {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'icon': json['icon'],
        'name': json['name'],
        'color': json['color'],
        'artsakt': json['artsakt'],
        'allowdocs': json['allowdocs'],
        'allowobjects': !exists(json, 'allowobjects') ? undefined : json['allowobjects'],
        'allowmultipledocs': !exists(json, 'allowmultipledocs') ? undefined : json['allowmultipledocs'],
        'secretgroup': json['secretgroup'],
        'defaultanswer': json['defaultanswer'],
        'defaultanswerall': json['defaultanswerall'],
        'defaultforward': json['defaultforward'],
        'needsproject': json['needsproject'],
        'cannotchangeproject': json['cannotchangeproject'],
        'defaultprojectid': json['defaultprojectid'],
        'defaultprojectname': json['defaultprojectname'],
        'active': !exists(json, 'active') ? undefined : json['active'],
        'hideonmobile': !exists(json, 'hideonmobile') ? undefined : json['hideonmobile'],
        'workflows': !exists(json, 'workflows') ? undefined : ((json['workflows'] as Array<any>).map(GetAktTypesResponseWorkflowsFromJSON)),
        'custom': !exists(json, 'custom') ? undefined : json['custom'],
    };
}

export function GetAktTypesResponseItemsToJSON(value?: GetAktTypesResponseItems | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'icon': value.icon,
        'name': value.name,
        'color': value.color,
        'artsakt': value.artsakt,
        'allowdocs': value.allowdocs,
        'allowobjects': value.allowobjects,
        'allowmultipledocs': value.allowmultipledocs,
        'secretgroup': value.secretgroup,
        'defaultanswer': value.defaultanswer,
        'defaultanswerall': value.defaultanswerall,
        'defaultforward': value.defaultforward,
        'needsproject': value.needsproject,
        'cannotchangeproject': value.cannotchangeproject,
        'defaultprojectid': value.defaultprojectid,
        'defaultprojectname': value.defaultprojectname,
        'active': value.active,
        'hideonmobile': value.hideonmobile,
        'workflows': value.workflows === undefined ? undefined : ((value.workflows as Array<any>).map(GetAktTypesResponseWorkflowsToJSON)),
        'custom': value.custom,
    };
}


