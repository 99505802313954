





















































































































import { AuthService } from '@consolidate/shared/util-auth';
import Vue from 'vue';
import LoginFacade from '../domain/LoginFacade';

export default Vue.extend({
  name: 'Login',
  props: {
    name: { type: String },
    animation: { type: Object },
    minVersion: { type: String },
  },
  data: () => ({
    webserviceUrl: AuthService.authority ?? '',
    loading: false,
    error: null as null | 'unreachable' | 'outdated' | 'servermissing',
    errorShown: false,
    scannerShown: false,
  }),
  computed: {
    canScan() {
      return false;
    },
  },
  deactivated() {
    this.stopScan();
  },
  beforeDestroy() {
    this.stopScan();
  },
  methods: {
    scan() {},
    stopScan() {},
    async login() {
      this.loading = true;

      const result = await LoginFacade.login(
        this.webserviceUrl,
        this.minVersion
      );
      if (result !== true) {
        this.error = result;
        this.errorShown = true;
      }

      this.loading = false;
    },
  },
});
