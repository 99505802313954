/* tslint:disable */
/* eslint-disable */
/**
 * Consolidate Legacy API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetContactResponseLocations
 */
export interface GetContactResponseLocations {
    /**
     * 
     * @type {number}
     * @memberof GetContactResponseLocations
     */
    locationID: number;
    /**
     * 
     * @type {string}
     * @memberof GetContactResponseLocations
     */
    locationtype: string;
    /**
     * 
     * @type {string}
     * @memberof GetContactResponseLocations
     */
    adressline2?: string;
    /**
     * 
     * @type {string}
     * @memberof GetContactResponseLocations
     */
    adressline3?: string;
    /**
     * 
     * @type {string}
     * @memberof GetContactResponseLocations
     */
    street: string;
    /**
     * 
     * @type {string}
     * @memberof GetContactResponseLocations
     */
    street2?: string;
    /**
     * 
     * @type {string}
     * @memberof GetContactResponseLocations
     */
    zip: string;
    /**
     * 
     * @type {string}
     * @memberof GetContactResponseLocations
     */
    city: string;
    /**
     * 
     * @type {string}
     * @memberof GetContactResponseLocations
     */
    country: string;
    /**
     * 
     * @type {string}
     * @memberof GetContactResponseLocations
     */
    countryid: string;
    /**
     * 
     * @type {string}
     * @memberof GetContactResponseLocations
     */
    phone: string;
}

export function GetContactResponseLocationsFromJSON(json: any): GetContactResponseLocations {
    return GetContactResponseLocationsFromJSONTyped(json, false);
}

export function GetContactResponseLocationsFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetContactResponseLocations {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'locationID': json['locationID'],
        'locationtype': json['locationtype'],
        'adressline2': !exists(json, 'adressline2') ? undefined : json['adressline2'],
        'adressline3': !exists(json, 'adressline3') ? undefined : json['adressline3'],
        'street': json['street'],
        'street2': !exists(json, 'street2') ? undefined : json['street2'],
        'zip': json['zip'],
        'city': json['city'],
        'country': json['country'],
        'countryid': json['countryid'],
        'phone': json['phone'],
    };
}

export function GetContactResponseLocationsToJSON(value?: GetContactResponseLocations | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'locationID': value.locationID,
        'locationtype': value.locationtype,
        'adressline2': value.adressline2,
        'adressline3': value.adressline3,
        'street': value.street,
        'street2': value.street2,
        'zip': value.zip,
        'city': value.city,
        'country': value.country,
        'countryid': value.countryid,
        'phone': value.phone,
    };
}


