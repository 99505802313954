/* tslint:disable */
/* eslint-disable */
/**
 * Consolidate Legacy API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    GetUsersResponseItemDivider,
    GetUsersResponseItemUser,
    GetUsersResponseItemDividerFromJSONTyped,
    GetUsersResponseItemDividerToJSON,
    GetUsersResponseItemUserFromJSONTyped,
    GetUsersResponseItemUserToJSON,
} from './';

/**
 * @type GetUsersResponseItem
 * 
 * @export
 */
export type GetUsersResponseItem = { isdivider: false } & GetUsersResponseItemUser | { isdivider: true } & GetUsersResponseItemDivider;

export function GetUsersResponseItemFromJSON(json: any): GetUsersResponseItem {
    return GetUsersResponseItemFromJSONTyped(json, false);
}

export function GetUsersResponseItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetUsersResponseItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    switch (json['isdivider']) {
        
            
        case true:
            return {...GetUsersResponseItemDividerFromJSONTyped(json, true), isdivider: true};
        default:
            return {...GetUsersResponseItemUserFromJSONTyped(json, true), isdivider: false};
    }
}

export function GetUsersResponseItemToJSON(value?: GetUsersResponseItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    switch (value['isdivider']) {
        
            
        case true:
            return GetUsersResponseItemDividerToJSON(value);
        default:
            return GetUsersResponseItemUserToJSON(value);
    }
}

