/* tslint:disable */
/* eslint-disable */
/**
 * Consolidate Legacy API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GetAktMemberDefault,
    GetAktMemberDefaultFromJSON,
    GetAktMemberDefaultFromJSONTyped,
    GetAktMemberDefaultToJSON,
    GetAktResponseAppointmentAllOfMembersReservations,
    GetAktResponseAppointmentAllOfMembersReservationsFromJSON,
    GetAktResponseAppointmentAllOfMembersReservationsFromJSONTyped,
    GetAktResponseAppointmentAllOfMembersReservationsToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetAktResponseAppointmentAllOfMembers
 */
export interface GetAktResponseAppointmentAllOfMembers {
    /**
     * 
     * @type {Array<GetAktMemberDefault>}
     * @memberof GetAktResponseAppointmentAllOfMembers
     */
    addresslist?: Array<GetAktMemberDefault>;
    /**
     * 
     * @type {Array<GetAktMemberDefault>}
     * @memberof GetAktResponseAppointmentAllOfMembers
     */
    attendees?: Array<GetAktMemberDefault>;
    /**
     * 
     * @type {Array<GetAktMemberDefault>}
     * @memberof GetAktResponseAppointmentAllOfMembers
     */
    clients?: Array<GetAktMemberDefault>;
    /**
     * 
     * @type {Array<GetAktResponseAppointmentAllOfMembersReservations>}
     * @memberof GetAktResponseAppointmentAllOfMembers
     */
    reservations?: Array<GetAktResponseAppointmentAllOfMembersReservations>;
}

export function GetAktResponseAppointmentAllOfMembersFromJSON(json: any): GetAktResponseAppointmentAllOfMembers {
    return GetAktResponseAppointmentAllOfMembersFromJSONTyped(json, false);
}

export function GetAktResponseAppointmentAllOfMembersFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetAktResponseAppointmentAllOfMembers {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'addresslist': !exists(json, 'addresslist') ? undefined : ((json['addresslist'] as Array<any>).map(GetAktMemberDefaultFromJSON)),
        'attendees': !exists(json, 'attendees') ? undefined : ((json['attendees'] as Array<any>).map(GetAktMemberDefaultFromJSON)),
        'clients': !exists(json, 'clients') ? undefined : ((json['clients'] as Array<any>).map(GetAktMemberDefaultFromJSON)),
        'reservations': !exists(json, 'reservations') ? undefined : ((json['reservations'] as Array<any>).map(GetAktResponseAppointmentAllOfMembersReservationsFromJSON)),
    };
}

export function GetAktResponseAppointmentAllOfMembersToJSON(value?: GetAktResponseAppointmentAllOfMembers | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'addresslist': value.addresslist === undefined ? undefined : ((value.addresslist as Array<any>).map(GetAktMemberDefaultToJSON)),
        'attendees': value.attendees === undefined ? undefined : ((value.attendees as Array<any>).map(GetAktMemberDefaultToJSON)),
        'clients': value.clients === undefined ? undefined : ((value.clients as Array<any>).map(GetAktMemberDefaultToJSON)),
        'reservations': value.reservations === undefined ? undefined : ((value.reservations as Array<any>).map(GetAktResponseAppointmentAllOfMembersReservationsToJSON)),
    };
}


