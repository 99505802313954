/* tslint:disable */
/* eslint-disable */
/**
 * Consolidate Legacy API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GetAktsFromIdResponseItems,
    GetAktsFromIdResponseItemsFromJSON,
    GetAktsFromIdResponseItemsFromJSONTyped,
    GetAktsFromIdResponseItemsToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetAktsFromIdResponse
 */
export interface GetAktsFromIdResponse {
    /**
     * 
     * @type {number}
     * @memberof GetAktsFromIdResponse
     */
    found: number;
    /**
     * 
     * @type {Array<GetAktsFromIdResponseItems>}
     * @memberof GetAktsFromIdResponse
     */
    items: Array<GetAktsFromIdResponseItems>;
}

export function GetAktsFromIdResponseFromJSON(json: any): GetAktsFromIdResponse {
    return GetAktsFromIdResponseFromJSONTyped(json, false);
}

export function GetAktsFromIdResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetAktsFromIdResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'found': json['found'],
        'items': ((json['items'] as Array<any>).map(GetAktsFromIdResponseItemsFromJSON)),
    };
}

export function GetAktsFromIdResponseToJSON(value?: GetAktsFromIdResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'found': value.found,
        'items': ((value.items as Array<any>).map(GetAktsFromIdResponseItemsToJSON)),
    };
}


