/* tslint:disable */
/* eslint-disable */
/**
 * Consolidate Legacy API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AddActivityRequestAttachments,
    AddActivityRequestAttachmentsFromJSON,
    AddActivityRequestAttachmentsFromJSONTyped,
    AddActivityRequestAttachmentsToJSON,
    RecurrenceInfo,
    RecurrenceInfoFromJSON,
    RecurrenceInfoFromJSONTyped,
    RecurrenceInfoToJSON,
} from './';

/**
 * 
 * @export
 * @interface AddActivityRequest
 */
export interface AddActivityRequest {
    /**
     * 
     * @type {number}
     * @memberof AddActivityRequest
     */
    akttype: number;
    /**
     * 
     * @type {number}
     * @memberof AddActivityRequest
     */
    aktsakt: number;
    /**
     * 
     * @type {string}
     * @memberof AddActivityRequest
     */
    aktresp: string;
    /**
     * 
     * @type {string}
     * @memberof AddActivityRequest
     */
    transferComment?: string;
    /**
     * 
     * @type {string}
     * @memberof AddActivityRequest
     */
    subject: string;
    /**
     * 
     * @type {number}
     * @memberof AddActivityRequest
     */
    type?: number;
    /**
     * 
     * @type {number}
     * @memberof AddActivityRequest
     */
    prevakt?: number;
    /**
     * 
     * @type {boolean}
     * @memberof AddActivityRequest
     */
    commentHistory?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AddActivityRequest
     */
    comment?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof AddActivityRequest
     */
    objects?: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof AddActivityRequest
     */
    project?: number;
    /**
     * 
     * @type {number}
     * @memberof AddActivityRequest
     */
    priority?: number;
    /**
     * 
     * @type {boolean}
     * @memberof AddActivityRequest
     */
    requestReadReceipt?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AddActivityRequest
     */
    secretGroup?: number;
    /**
     * 
     * @type {string}
     * @memberof AddActivityRequest
     */
    externalParticipants?: string;
    /**
     * 
     * @type {string}
     * @memberof AddActivityRequest
     */
    internalParticipants?: string;
    /**
     * 
     * @type {string}
     * @memberof AddActivityRequest
     */
    receivers?: string;
    /**
     * 
     * @type {string}
     * @memberof AddActivityRequest
     */
    externalCCs?: string;
    /**
     * 
     * @type {string}
     * @memberof AddActivityRequest
     */
    clients?: string;
    /**
     * 
     * @type {string}
     * @memberof AddActivityRequest
     */
    internalCCs?: string;
    /**
     * 
     * @type {string}
     * @memberof AddActivityRequest
     */
    cCcomment?: string;
    /**
     * 
     * @type {number}
     * @memberof AddActivityRequest
     */
    blockType?: number;
    /**
     * 
     * @type {boolean}
     * @memberof AddActivityRequest
     */
    isWholeDay?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AddActivityRequest
     */
    fromDate?: string;
    /**
     * 
     * @type {string}
     * @memberof AddActivityRequest
     */
    toDate?: string;
    /**
     * 
     * @type {string}
     * @memberof AddActivityRequest
     */
    fromTime?: string;
    /**
     * 
     * @type {string}
     * @memberof AddActivityRequest
     */
    toTime?: string;
    /**
     * 
     * @type {number}
     * @memberof AddActivityRequest
     */
    attachmentsCount?: number;
    /**
     * 
     * @type {Array<AddActivityRequestAttachments>}
     * @memberof AddActivityRequest
     */
    attachments?: Array<AddActivityRequestAttachments>;
    /**
     * 
     * @type {string}
     * @memberof AddActivityRequest
     */
    selectedAttachments?: string;
    /**
     * 
     * @type {RecurrenceInfo}
     * @memberof AddActivityRequest
     */
    recurrence?: RecurrenceInfo;
    /**
     * 
     * @type {string}
     * @memberof AddActivityRequest
     */
    mailfrom?: string;
}

export function AddActivityRequestFromJSON(json: any): AddActivityRequest {
    return AddActivityRequestFromJSONTyped(json, false);
}

export function AddActivityRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddActivityRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'akttype': json['akttype'],
        'aktsakt': json['aktsakt'],
        'aktresp': json['aktresp'],
        'transferComment': !exists(json, 'transferComment') ? undefined : json['transferComment'],
        'subject': json['subject'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'prevakt': !exists(json, 'prevakt') ? undefined : json['prevakt'],
        'commentHistory': !exists(json, 'commentHistory') ? undefined : json['commentHistory'],
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
        'objects': !exists(json, 'objects') ? undefined : json['objects'],
        'project': !exists(json, 'project') ? undefined : json['project'],
        'priority': !exists(json, 'priority') ? undefined : json['priority'],
        'requestReadReceipt': !exists(json, 'requestReadReceipt') ? undefined : json['requestReadReceipt'],
        'secretGroup': !exists(json, 'secretGroup') ? undefined : json['secretGroup'],
        'externalParticipants': !exists(json, 'externalParticipants') ? undefined : json['externalParticipants'],
        'internalParticipants': !exists(json, 'internalParticipants') ? undefined : json['internalParticipants'],
        'receivers': !exists(json, 'receivers') ? undefined : json['receivers'],
        'externalCCs': !exists(json, 'externalCCs') ? undefined : json['externalCCs'],
        'clients': !exists(json, 'clients') ? undefined : json['clients'],
        'internalCCs': !exists(json, 'internalCCs') ? undefined : json['internalCCs'],
        'cCcomment': !exists(json, 'CCcomment') ? undefined : json['CCcomment'],
        'blockType': !exists(json, 'blockType') ? undefined : json['blockType'],
        'isWholeDay': !exists(json, 'isWholeDay') ? undefined : json['isWholeDay'],
        'fromDate': !exists(json, 'fromDate') ? undefined : json['fromDate'],
        'toDate': !exists(json, 'toDate') ? undefined : json['toDate'],
        'fromTime': !exists(json, 'fromTime') ? undefined : json['fromTime'],
        'toTime': !exists(json, 'toTime') ? undefined : json['toTime'],
        'attachmentsCount': !exists(json, 'attachmentsCount') ? undefined : json['attachmentsCount'],
        'attachments': !exists(json, 'attachments') ? undefined : ((json['attachments'] as Array<any>).map(AddActivityRequestAttachmentsFromJSON)),
        'selectedAttachments': !exists(json, 'selectedAttachments') ? undefined : json['selectedAttachments'],
        'recurrence': !exists(json, 'recurrence') ? undefined : RecurrenceInfoFromJSON(json['recurrence']),
        'mailfrom': !exists(json, 'mailfrom') ? undefined : json['mailfrom'],
    };
}

export function AddActivityRequestToJSON(value?: AddActivityRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'akttype': value.akttype,
        'aktsakt': value.aktsakt,
        'aktresp': value.aktresp,
        'transferComment': value.transferComment,
        'subject': value.subject,
        'type': value.type,
        'prevakt': value.prevakt,
        'commentHistory': value.commentHistory,
        'comment': value.comment,
        'objects': value.objects,
        'project': value.project,
        'priority': value.priority,
        'requestReadReceipt': value.requestReadReceipt,
        'secretGroup': value.secretGroup,
        'externalParticipants': value.externalParticipants,
        'internalParticipants': value.internalParticipants,
        'receivers': value.receivers,
        'externalCCs': value.externalCCs,
        'clients': value.clients,
        'internalCCs': value.internalCCs,
        'CCcomment': value.cCcomment,
        'blockType': value.blockType,
        'isWholeDay': value.isWholeDay,
        'fromDate': value.fromDate,
        'toDate': value.toDate,
        'fromTime': value.fromTime,
        'toTime': value.toTime,
        'attachmentsCount': value.attachmentsCount,
        'attachments': value.attachments === undefined ? undefined : ((value.attachments as Array<any>).map(AddActivityRequestAttachmentsToJSON)),
        'selectedAttachments': value.selectedAttachments,
        'recurrence': RecurrenceInfoToJSON(value.recurrence),
        'mailfrom': value.mailfrom,
    };
}


