/* tslint:disable */
/* eslint-disable */
/**
 * Consolidate Legacy API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetProjectListResponseItems
 */
export interface GetProjectListResponseItems {
    /**
     * 
     * @type {number}
     * @memberof GetProjectListResponseItems
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof GetProjectListResponseItems
     */
    icon: string;
    /**
     * 
     * @type {string}
     * @memberof GetProjectListResponseItems
     */
    projectname: string;
    /**
     * 
     * @type {string}
     * @memberof GetProjectListResponseItems
     */
    projectowner: string;
    /**
     * 
     * @type {string}
     * @memberof GetProjectListResponseItems
     */
    startdate?: string;
    /**
     * 
     * @type {string}
     * @memberof GetProjectListResponseItems
     */
    enddate?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetProjectListResponseItems
     */
    isfavorite: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetProjectListResponseItems
     */
    active?: boolean;
}

export function GetProjectListResponseItemsFromJSON(json: any): GetProjectListResponseItems {
    return GetProjectListResponseItemsFromJSONTyped(json, false);
}

export function GetProjectListResponseItemsFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetProjectListResponseItems {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'icon': json['icon'],
        'projectname': json['projectname'],
        'projectowner': json['projectowner'],
        'startdate': !exists(json, 'startdate') ? undefined : json['startdate'],
        'enddate': !exists(json, 'enddate') ? undefined : json['enddate'],
        'isfavorite': json['isfavorite'],
        'active': !exists(json, 'active') ? undefined : json['active'],
    };
}

export function GetProjectListResponseItemsToJSON(value?: GetProjectListResponseItems | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'icon': value.icon,
        'projectname': value.projectname,
        'projectowner': value.projectowner,
        'startdate': value.startdate,
        'enddate': value.enddate,
        'isfavorite': value.isfavorite,
        'active': value.active,
    };
}


