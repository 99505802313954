/* tslint:disable */
/* eslint-disable */
/**
 * Consolidate Legacy API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InlineObject23
 */
export interface InlineObject23 {
    /**
     * 
     * @type {number}
     * @memberof InlineObject23
     */
    aktid: number;
    /**
     * 
     * @type {string}
     * @memberof InlineObject23
     */
    method?: string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject23
     */
    mailfrom?: string;
    /**
     * 
     * @type {boolean}
     * @memberof InlineObject23
     */
    withcomment?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InlineObject23
     */
    activitydeleted?: boolean;
    /**
     * 
     * @type {string}
     * @memberof InlineObject23
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject23
     */
    cID?: string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject23
     */
    aNR?: string;
}

export function InlineObject23FromJSON(json: any): InlineObject23 {
    return InlineObject23FromJSONTyped(json, false);
}

export function InlineObject23FromJSONTyped(json: any, ignoreDiscriminator: boolean): InlineObject23 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'aktid': json['aktid'],
        'method': !exists(json, 'method') ? undefined : json['method'],
        'mailfrom': !exists(json, 'mailfrom') ? undefined : json['mailfrom'],
        'withcomment': !exists(json, 'withcomment') ? undefined : json['withcomment'],
        'activitydeleted': !exists(json, 'activitydeleted') ? undefined : json['activitydeleted'],
        'email': !exists(json, 'Email') ? undefined : json['Email'],
        'cID': !exists(json, 'CID') ? undefined : json['CID'],
        'aNR': !exists(json, 'ANR') ? undefined : json['ANR'],
    };
}

export function InlineObject23ToJSON(value?: InlineObject23 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'aktid': value.aktid,
        'method': value.method,
        'mailfrom': value.mailfrom,
        'withcomment': value.withcomment,
        'activitydeleted': value.activitydeleted,
        'Email': value.email,
        'CID': value.cID,
        'ANR': value.aNR,
    };
}


