/* tslint:disable */
/* eslint-disable */
/**
 * Consolidate Legacy API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FindActivityRequest
 */
export interface FindActivityRequest {
    /**
     * 
     * @type {number}
     * @memberof FindActivityRequest
     */
    limit?: number;
    /**
     * 
     * @type {number}
     * @memberof FindActivityRequest
     */
    offset?: number;
    /**
     * 
     * @type {string}
     * @memberof FindActivityRequest
     */
    searchstring: string;
    /**
     * 
     * @type {string}
     * @memberof FindActivityRequest
     */
    project?: string;
    /**
     * 
     * @type {string}
     * @memberof FindActivityRequest
     */
    akttype?: string;
    /**
     * 
     * @type {string}
     * @memberof FindActivityRequest
     */
    responsible?: string;
    /**
     * 
     * @type {string}
     * @memberof FindActivityRequest
     */
    anrlist?: string;
    /**
     * 
     * @type {string}
     * @memberof FindActivityRequest
     */
    cidlist?: string;
    /**
     * 
     * @type {string}
     * @memberof FindActivityRequest
     */
    createdfrom?: string;
    /**
     * 
     * @type {boolean}
     * @memberof FindActivityRequest
     */
    status?: boolean;
}

export function FindActivityRequestFromJSON(json: any): FindActivityRequest {
    return FindActivityRequestFromJSONTyped(json, false);
}

export function FindActivityRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): FindActivityRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'limit': !exists(json, 'limit') ? undefined : json['limit'],
        'offset': !exists(json, 'offset') ? undefined : json['offset'],
        'searchstring': json['searchstring'],
        'project': !exists(json, 'project') ? undefined : json['project'],
        'akttype': !exists(json, 'akttype') ? undefined : json['akttype'],
        'responsible': !exists(json, 'responsible') ? undefined : json['responsible'],
        'anrlist': !exists(json, 'anrlist') ? undefined : json['anrlist'],
        'cidlist': !exists(json, 'cidlist') ? undefined : json['cidlist'],
        'createdfrom': !exists(json, 'createdfrom') ? undefined : json['createdfrom'],
        'status': !exists(json, 'status') ? undefined : json['status'],
    };
}

export function FindActivityRequestToJSON(value?: FindActivityRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'limit': value.limit,
        'offset': value.offset,
        'searchstring': value.searchstring,
        'project': value.project,
        'akttype': value.akttype,
        'responsible': value.responsible,
        'anrlist': value.anrlist,
        'cidlist': value.cidlist,
        'createdfrom': value.createdfrom,
        'status': value.status,
    };
}


