/* tslint:disable */
/* eslint-disable */
/**
 * Consolidate Legacy API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RecurrenceInfo
 */
export interface RecurrenceInfo {
    /**
     * 
     * @type {number}
     * @memberof RecurrenceInfo
     */
    type: number;
    /**
     * 
     * @type {number}
     * @memberof RecurrenceInfo
     */
    mode?: number;
    /**
     * 
     * @type {string}
     * @memberof RecurrenceInfo
     */
    exclusionDates?: string;
    /**
     * 
     * @type {number}
     * @memberof RecurrenceInfo
     */
    day?: number;
    /**
     * 
     * @type {number}
     * @memberof RecurrenceInfo
     */
    month?: number;
    /**
     * 
     * @type {number}
     * @memberof RecurrenceInfo
     */
    frequency?: number;
    /**
     * 
     * @type {number}
     * @memberof RecurrenceInfo
     */
    dayOfWeek?: number;
    /**
     * 
     * @type {number}
     * @memberof RecurrenceInfo
     */
    weekOfMonth?: number;
    /**
     * 
     * @type {string}
     * @memberof RecurrenceInfo
     */
    days?: string;
    /**
     * 
     * @type {number}
     * @memberof RecurrenceInfo
     */
    duration?: number;
}

export function RecurrenceInfoFromJSON(json: any): RecurrenceInfo {
    return RecurrenceInfoFromJSONTyped(json, false);
}

export function RecurrenceInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RecurrenceInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': json['type'],
        'mode': !exists(json, 'mode') ? undefined : json['mode'],
        'exclusionDates': !exists(json, 'exclusionDates') ? undefined : json['exclusionDates'],
        'day': !exists(json, 'day') ? undefined : json['day'],
        'month': !exists(json, 'month') ? undefined : json['month'],
        'frequency': !exists(json, 'frequency') ? undefined : json['frequency'],
        'dayOfWeek': !exists(json, 'dayOfWeek') ? undefined : json['dayOfWeek'],
        'weekOfMonth': !exists(json, 'weekOfMonth') ? undefined : json['weekOfMonth'],
        'days': !exists(json, 'days') ? undefined : json['days'],
        'duration': !exists(json, 'duration') ? undefined : json['duration'],
    };
}

export function RecurrenceInfoToJSON(value?: RecurrenceInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'mode': value.mode,
        'exclusionDates': value.exclusionDates,
        'day': value.day,
        'month': value.month,
        'frequency': value.frequency,
        'dayOfWeek': value.dayOfWeek,
        'weekOfMonth': value.weekOfMonth,
        'days': value.days,
        'duration': value.duration,
    };
}


