/* tslint:disable */
/* eslint-disable */
/**
 * Consolidate Legacy API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetAktTypesResponseWorkflows
 */
export interface GetAktTypesResponseWorkflows {
    /**
     * 
     * @type {number}
     * @memberof GetAktTypesResponseWorkflows
     */
    processid: number;
    /**
     * 
     * @type {string}
     * @memberof GetAktTypesResponseWorkflows
     */
    processname: string;
}

export function GetAktTypesResponseWorkflowsFromJSON(json: any): GetAktTypesResponseWorkflows {
    return GetAktTypesResponseWorkflowsFromJSONTyped(json, false);
}

export function GetAktTypesResponseWorkflowsFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetAktTypesResponseWorkflows {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'processid': json['processid'],
        'processname': json['processname'],
    };
}

export function GetAktTypesResponseWorkflowsToJSON(value?: GetAktTypesResponseWorkflows | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'processid': value.processid,
        'processname': value.processname,
    };
}


