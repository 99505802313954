/* tslint:disable */
/* eslint-disable */
/**
 * Consolidate Legacy API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetCountryListResponseItems
 */
export interface GetCountryListResponseItems {
    /**
     * 
     * @type {string}
     * @memberof GetCountryListResponseItems
     */
    countryid: string;
    /**
     * 
     * @type {string}
     * @memberof GetCountryListResponseItems
     */
    countryname: string;
}

export function GetCountryListResponseItemsFromJSON(json: any): GetCountryListResponseItems {
    return GetCountryListResponseItemsFromJSONTyped(json, false);
}

export function GetCountryListResponseItemsFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetCountryListResponseItems {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'countryid': json['countryid'],
        'countryname': json['countryname'],
    };
}

export function GetCountryListResponseItemsToJSON(value?: GetCountryListResponseItems | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'countryid': value.countryid,
        'countryname': value.countryname,
    };
}


