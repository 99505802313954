/* tslint:disable */
/* eslint-disable */
/**
 * Consolidate Legacy API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetAktRequest
 */
export interface GetAktRequest {
    /**
     * 
     * @type {number}
     * @memberof GetAktRequest
     */
    hash?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GetAktRequest
     */
    getexternals?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetAktRequest
     */
    getmembers?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetAktRequest
     */
    getcustom?: boolean;
}

export function GetAktRequestFromJSON(json: any): GetAktRequest {
    return GetAktRequestFromJSONTyped(json, false);
}

export function GetAktRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetAktRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'hash': !exists(json, 'hash') ? undefined : json['hash'],
        'getexternals': !exists(json, 'getexternals') ? undefined : json['getexternals'],
        'getmembers': !exists(json, 'getmembers') ? undefined : json['getmembers'],
        'getcustom': !exists(json, 'getcustom') ? undefined : json['getcustom'],
    };
}

export function GetAktRequestToJSON(value?: GetAktRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'hash': value.hash,
        'getexternals': value.getexternals,
        'getmembers': value.getmembers,
        'getcustom': value.getcustom,
    };
}


