/* tslint:disable */
/* eslint-disable */
/**
 * Consolidate Legacy API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CustomFormField,
    CustomFormFieldFromJSON,
    CustomFormFieldFromJSONTyped,
    CustomFormFieldToJSON,
    GetProjectCustomDataResponsePartOf,
    GetProjectCustomDataResponsePartOfFromJSON,
    GetProjectCustomDataResponsePartOfFromJSONTyped,
    GetProjectCustomDataResponsePartOfToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetProjectCustomDataResponse
 */
export interface GetProjectCustomDataResponse {
    /**
     * 
     * @type {{ [key: string]: { [key: string]: CustomFormField; }; }}
     * @memberof GetProjectCustomDataResponse
     */
    custom?: { [key: string]: { [key: string]: CustomFormField; }; };
    /**
     * 
     * @type {boolean}
     * @memberof GetProjectCustomDataResponse
     */
    active?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetProjectCustomDataResponse
     */
    archived?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetProjectCustomDataResponse
     */
    comment?: string;
    /**
     * 
     * @type {string}
     * @memberof GetProjectCustomDataResponse
     */
    confidentialityGroup?: string;
    /**
     * 
     * @type {GetProjectCustomDataResponsePartOf}
     * @memberof GetProjectCustomDataResponse
     */
    partOf?: GetProjectCustomDataResponsePartOf;
    /**
     * 
     * @type {number}
     * @memberof GetProjectCustomDataResponse
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof GetProjectCustomDataResponse
     */
    icon?: string;
    /**
     * 
     * @type {string}
     * @memberof GetProjectCustomDataResponse
     */
    projectname?: string;
    /**
     * 
     * @type {string}
     * @memberof GetProjectCustomDataResponse
     */
    projectowner?: string;
    /**
     * 
     * @type {string}
     * @memberof GetProjectCustomDataResponse
     */
    startdate?: string;
    /**
     * 
     * @type {string}
     * @memberof GetProjectCustomDataResponse
     */
    enddate?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetProjectCustomDataResponse
     */
    isfavorite?: boolean;
}

export function GetProjectCustomDataResponseFromJSON(json: any): GetProjectCustomDataResponse {
    return GetProjectCustomDataResponseFromJSONTyped(json, false);
}

export function GetProjectCustomDataResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetProjectCustomDataResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'custom': !exists(json, 'custom') ? undefined : json['custom'],
        'active': !exists(json, 'active') ? undefined : json['active'],
        'archived': !exists(json, 'archived') ? undefined : json['archived'],
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
        'confidentialityGroup': !exists(json, 'confidentialityGroup') ? undefined : json['confidentialityGroup'],
        'partOf': !exists(json, 'partOf') ? undefined : GetProjectCustomDataResponsePartOfFromJSON(json['partOf']),
        'id': !exists(json, 'id') ? undefined : json['id'],
        'icon': !exists(json, 'icon') ? undefined : json['icon'],
        'projectname': !exists(json, 'projectname') ? undefined : json['projectname'],
        'projectowner': !exists(json, 'projectowner') ? undefined : json['projectowner'],
        'startdate': !exists(json, 'startdate') ? undefined : json['startdate'],
        'enddate': !exists(json, 'enddate') ? undefined : json['enddate'],
        'isfavorite': !exists(json, 'isfavorite') ? undefined : json['isfavorite'],
    };
}

export function GetProjectCustomDataResponseToJSON(value?: GetProjectCustomDataResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'custom': value.custom,
        'active': value.active,
        'archived': value.archived,
        'comment': value.comment,
        'confidentialityGroup': value.confidentialityGroup,
        'partOf': GetProjectCustomDataResponsePartOfToJSON(value.partOf),
        'id': value.id,
        'icon': value.icon,
        'projectname': value.projectname,
        'projectowner': value.projectowner,
        'startdate': value.startdate,
        'enddate': value.enddate,
        'isfavorite': value.isfavorite,
    };
}


