/* tslint:disable */
/* eslint-disable */
/**
 * Consolidate Legacy API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    GetAktResponseAppointment,
    GetAktResponseDocument,
    GetAktResponseEmail,
    GetAktResponseAppointmentFromJSONTyped,
    GetAktResponseAppointmentToJSON,
    GetAktResponseDocumentFromJSONTyped,
    GetAktResponseDocumentToJSON,
    GetAktResponseEmailFromJSONTyped,
    GetAktResponseEmailToJSON,
} from './';

/**
 * @type GetAktResponse
 * 
 * @export
 */
export type GetAktResponse = { type: 'APPOINTMENT' } & GetAktResponseAppointment | { type: 'DOCUMENT' } & GetAktResponseDocument | { type: 'EMAIL' } & GetAktResponseEmail;

export function GetAktResponseFromJSON(json: any): GetAktResponse {
    return GetAktResponseFromJSONTyped(json, false);
}

export function GetAktResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetAktResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    switch (json['aktsakt']) {
        case 4:
            return {...GetAktResponseAppointmentFromJSONTyped(json, true), type: 'APPOINTMENT'};
        case 12:
        case 13:
            return {...GetAktResponseEmailFromJSONTyped(json, true), type: 'EMAIL'};
        default:
          return {...GetAktResponseDocumentFromJSONTyped(json, true), type: 'DOCUMENT'};
    }
}

export function GetAktResponseToJSON(value?: GetAktResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    switch (value['type']) {
        case 'APPOINTMENT':
            return GetAktResponseAppointmentToJSON(value);
        case 'DOCUMENT':
            return GetAktResponseDocumentToJSON(value);
        case 'EMAIL':
            return GetAktResponseEmailToJSON(value);
        default:
            throw new Error(`No variant of GetAktResponse exists with 'type=${value['type']}'`);
    }
}

