/* tslint:disable */
/* eslint-disable */
/**
 * Consolidate Legacy API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CustomFormField,
    CustomFormFieldFromJSON,
    CustomFormFieldFromJSONTyped,
    CustomFormFieldToJSON,
    GetAktResponseAppointmentAllOf,
    GetAktResponseAppointmentAllOfFromJSON,
    GetAktResponseAppointmentAllOfFromJSONTyped,
    GetAktResponseAppointmentAllOfToJSON,
    GetAktResponseAppointmentAllOfAttendees,
    GetAktResponseAppointmentAllOfAttendeesFromJSON,
    GetAktResponseAppointmentAllOfAttendeesFromJSONTyped,
    GetAktResponseAppointmentAllOfAttendeesToJSON,
    GetAktResponseAppointmentAllOfMembers,
    GetAktResponseAppointmentAllOfMembersFromJSON,
    GetAktResponseAppointmentAllOfMembersFromJSONTyped,
    GetAktResponseAppointmentAllOfMembersToJSON,
    GetAktResponseBase,
    GetAktResponseBaseFromJSON,
    GetAktResponseBaseFromJSONTyped,
    GetAktResponseBaseToJSON,
    GetAktResponseBaseObjects,
    GetAktResponseBaseObjectsFromJSON,
    GetAktResponseBaseObjectsFromJSONTyped,
    GetAktResponseBaseObjectsToJSON,
    GetAktResponseBaseRights,
    GetAktResponseBaseRightsFromJSON,
    GetAktResponseBaseRightsFromJSONTyped,
    GetAktResponseBaseRightsToJSON,
    RecurrenceInfo,
    RecurrenceInfoFromJSON,
    RecurrenceInfoFromJSONTyped,
    RecurrenceInfoToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetAktResponseAppointment
 */
export interface GetAktResponseAppointment {
    /**
     * 
     * @type {number}
     * @memberof GetAktResponseAppointment
     */
    addAttachment: number;
    /**
     * 
     * @type {boolean}
     * @memberof GetAktResponseAppointment
     */
    allowobjects?: boolean;
    /**
     * 
     * @type {number}
     * @memberof GetAktResponseAppointment
     */
    aktid: number;
    /**
     * 
     * @type {string}
     * @memberof GetAktResponseAppointment
     */
    aktname: string;
    /**
     * 
     * @type {number}
     * @memberof GetAktResponseAppointment
     */
    aktsakt: number;
    /**
     * 
     * @type {number}
     * @memberof GetAktResponseAppointment
     */
    akttype: number;
    /**
     * 
     * @type {string}
     * @memberof GetAktResponseAppointment
     */
    alarmtime?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAktResponseAppointment
     */
    alarmtimeutc?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAktResponseAppointment
     */
    createdby?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAktResponseAppointment
     */
    creationdate?: string;
    /**
     * 
     * @type {Array<object>}
     * @memberof GetAktResponseAppointment
     */
    attachments?: Array<object>;
    /**
     * 
     * @type {string}
     * @memberof GetAktResponseAppointment
     */
    color: string;
    /**
     * 
     * @type {{ [key: string]: { [key: string]: CustomFormField; }; }}
     * @memberof GetAktResponseAppointment
     */
    custom?: { [key: string]: { [key: string]: CustomFormField; }; };
    /**
     * 
     * @type {Array<object>}
     * @memberof GetAktResponseAppointment
     */
    internalCCs?: Array<object>;
    /**
     * 
     * @type {boolean}
     * @memberof GetAktResponseAppointment
     */
    morecontacts: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetAktResponseAppointment
     */
    date: string;
    /**
     * 
     * @type {string}
     * @memberof GetAktResponseAppointment
     */
    datelabel: string;
    /**
     * 
     * @type {number}
     * @memberof GetAktResponseAppointment
     */
    hasfollowakt: number;
    /**
     * 
     * @type {string}
     * @memberof GetAktResponseAppointment
     */
    icon: string;
    /**
     * 
     * @type {number}
     * @memberof GetAktResponseAppointment
     */
    nextsteptype?: number;
    /**
     * 
     * @type {number}
     * @memberof GetAktResponseAppointment
     */
    nextstepid?: number;
    /**
     * 
     * @type {string}
     * @memberof GetAktResponseAppointment
     */
    nextstepname?: string;
    /**
     * 
     * @type {number}
     * @memberof GetAktResponseAppointment
     */
    nextstepfiredby?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GetAktResponseAppointment
     */
    infomail?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetAktResponseAppointment
     */
    yesInfo?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAktResponseAppointment
     */
    noInfo?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAktResponseAppointment
     */
    stepcomment?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAktResponseAppointment
     */
    steptargetuser?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAktResponseAppointment
     */
    steptargetlist?: string;
    /**
     * 
     * @type {Array<GetAktResponseBaseObjects>}
     * @memberof GetAktResponseAppointment
     */
    objects?: Array<GetAktResponseBaseObjects>;
    /**
     * 
     * @type {number}
     * @memberof GetAktResponseAppointment
     */
    prevakt: number;
    /**
     * 
     * @type {number}
     * @memberof GetAktResponseAppointment
     */
    priority: number;
    /**
     * 
     * @type {number}
     * @memberof GetAktResponseAppointment
     */
    processid?: number;
    /**
     * 
     * @type {number}
     * @memberof GetAktResponseAppointment
     */
    projectid: number;
    /**
     * 
     * @type {string}
     * @memberof GetAktResponseAppointment
     */
    projectname?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetAktResponseAppointment
     */
    projectvisible: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetAktResponseAppointment
     */
    resp: string;
    /**
     * 
     * @type {GetAktResponseBaseRights}
     * @memberof GetAktResponseAppointment
     */
    rights: GetAktResponseBaseRights;
    /**
     * 
     * @type {number}
     * @memberof GetAktResponseAppointment
     */
    secret: number;
    /**
     * 
     * @type {string}
     * @memberof GetAktResponseAppointment
     */
    status: string;
    /**
     * 
     * @type {number}
     * @memberof GetAktResponseAppointment
     */
    statusid: number;
    /**
     * 
     * @type {string}
     * @memberof GetAktResponseAppointment
     */
    subject: string;
    /**
     * 
     * @type {string}
     * @memberof GetAktResponseAppointment
     */
    workflowInfo?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAktResponseAppointment
     */
    workflowinfotimestamp: string;
    /**
     * 
     * @type {Array<GetAktResponseAppointmentAllOfAttendees>}
     * @memberof GetAktResponseAppointment
     */
    attendees: Array<GetAktResponseAppointmentAllOfAttendees>;
    /**
     * 
     * @type {boolean}
     * @memberof GetAktResponseAppointment
     */
    cancancel: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetAktResponseAppointment
     */
    enddatetime: string;
    /**
     * 
     * @type {string}
     * @memberof GetAktResponseAppointment
     */
    enddatetimeutc: string;
    /**
     * 
     * @type {GetAktResponseAppointmentAllOfMembers}
     * @memberof GetAktResponseAppointment
     */
    members: GetAktResponseAppointmentAllOfMembers;
    /**
     * 
     * @type {boolean}
     * @memberof GetAktResponseAppointment
     */
    needconfirmation?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetAktResponseAppointment
     */
    notimechange?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetAktResponseAppointment
     */
    startdatetime: string;
    /**
     * 
     * @type {string}
     * @memberof GetAktResponseAppointment
     */
    startdatetimeutc: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetAktResponseAppointment
     */
    wholeday: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetAktResponseAppointment
     */
    shouldsendreply?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetAktResponseAppointment
     */
    organizer?: string;
    /**
     * 
     * @type {RecurrenceInfo}
     * @memberof GetAktResponseAppointment
     */
    recurrence?: RecurrenceInfo;
}

export function GetAktResponseAppointmentFromJSON(json: any): GetAktResponseAppointment {
    return GetAktResponseAppointmentFromJSONTyped(json, false);
}

export function GetAktResponseAppointmentFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetAktResponseAppointment {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'addAttachment': json['addAttachment'],
        'allowobjects': !exists(json, 'allowobjects') ? undefined : json['allowobjects'],
        'aktid': json['aktid'],
        'aktname': json['aktname'],
        'aktsakt': json['aktsakt'],
        'akttype': json['akttype'],
        'alarmtime': !exists(json, 'alarmtime') ? undefined : json['alarmtime'],
        'alarmtimeutc': !exists(json, 'alarmtimeutc') ? undefined : json['alarmtimeutc'],
        'createdby': !exists(json, 'createdby') ? undefined : json['createdby'],
        'creationdate': !exists(json, 'creationdate') ? undefined : json['creationdate'],
        'attachments': !exists(json, 'attachments') ? undefined : json['attachments'],
        'color': json['color'],
        'custom': !exists(json, 'custom') ? undefined : json['custom'],
        'internalCCs': !exists(json, 'internalCCs') ? undefined : json['internalCCs'],
        'morecontacts': json['morecontacts'],
        'date': json['date'],
        'datelabel': json['datelabel'],
        'hasfollowakt': json['hasfollowakt'],
        'icon': json['icon'],
        'nextsteptype': !exists(json, 'nextsteptype') ? undefined : json['nextsteptype'],
        'nextstepid': !exists(json, 'nextstepid') ? undefined : json['nextstepid'],
        'nextstepname': !exists(json, 'nextstepname') ? undefined : json['nextstepname'],
        'nextstepfiredby': !exists(json, 'nextstepfiredby') ? undefined : json['nextstepfiredby'],
        'infomail': !exists(json, 'infomail') ? undefined : json['infomail'],
        'yesInfo': !exists(json, 'yes_info') ? undefined : json['yes_info'],
        'noInfo': !exists(json, 'no_info') ? undefined : json['no_info'],
        'stepcomment': !exists(json, 'stepcomment') ? undefined : json['stepcomment'],
        'steptargetuser': !exists(json, 'steptargetuser') ? undefined : json['steptargetuser'],
        'steptargetlist': !exists(json, 'steptargetlist') ? undefined : json['steptargetlist'],
        'objects': !exists(json, 'objects') ? undefined : ((json['objects'] as Array<any>).map(GetAktResponseBaseObjectsFromJSON)),
        'prevakt': json['prevakt'],
        'priority': json['priority'],
        'processid': !exists(json, 'processid') ? undefined : json['processid'],
        'projectid': json['projectid'],
        'projectname': !exists(json, 'projectname') ? undefined : json['projectname'],
        'projectvisible': json['projectvisible'],
        'resp': json['resp'],
        'rights': GetAktResponseBaseRightsFromJSON(json['rights']),
        'secret': json['secret'],
        'status': json['status'],
        'statusid': json['statusid'],
        'subject': json['subject'],
        'workflowInfo': !exists(json, 'workflowInfo') ? undefined : json['workflowInfo'],
        'workflowinfotimestamp': json['workflowinfotimestamp'],
        'attendees': ((json['attendees'] as Array<any>).map(GetAktResponseAppointmentAllOfAttendeesFromJSON)),
        'cancancel': json['cancancel'],
        'enddatetime': json['enddatetime'],
        'enddatetimeutc': json['enddatetimeutc'],
        'members': GetAktResponseAppointmentAllOfMembersFromJSON(json['members']),
        'needconfirmation': !exists(json, 'needconfirmation') ? undefined : json['needconfirmation'],
        'notimechange': !exists(json, 'notimechange') ? undefined : json['notimechange'],
        'startdatetime': json['startdatetime'],
        'startdatetimeutc': json['startdatetimeutc'],
        'wholeday': json['wholeday'],
        'shouldsendreply': !exists(json, 'shouldsendreply') ? undefined : json['shouldsendreply'],
        'organizer': !exists(json, 'organizer') ? undefined : json['organizer'],
        'recurrence': !exists(json, 'recurrence') ? undefined : RecurrenceInfoFromJSON(json['recurrence']),
    };
}

export function GetAktResponseAppointmentToJSON(value?: GetAktResponseAppointment | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'addAttachment': value.addAttachment,
        'allowobjects': value.allowobjects,
        'aktid': value.aktid,
        'aktname': value.aktname,
        'aktsakt': value.aktsakt,
        'akttype': value.akttype,
        'alarmtime': value.alarmtime,
        'alarmtimeutc': value.alarmtimeutc,
        'createdby': value.createdby,
        'creationdate': value.creationdate,
        'attachments': value.attachments,
        'color': value.color,
        'custom': value.custom,
        'internalCCs': value.internalCCs,
        'morecontacts': value.morecontacts,
        'date': value.date,
        'datelabel': value.datelabel,
        'hasfollowakt': value.hasfollowakt,
        'icon': value.icon,
        'nextsteptype': value.nextsteptype,
        'nextstepid': value.nextstepid,
        'nextstepname': value.nextstepname,
        'nextstepfiredby': value.nextstepfiredby,
        'infomail': value.infomail,
        'yes_info': value.yesInfo,
        'no_info': value.noInfo,
        'stepcomment': value.stepcomment,
        'steptargetuser': value.steptargetuser,
        'steptargetlist': value.steptargetlist,
        'objects': value.objects === undefined ? undefined : ((value.objects as Array<any>).map(GetAktResponseBaseObjectsToJSON)),
        'prevakt': value.prevakt,
        'priority': value.priority,
        'processid': value.processid,
        'projectid': value.projectid,
        'projectname': value.projectname,
        'projectvisible': value.projectvisible,
        'resp': value.resp,
        'rights': GetAktResponseBaseRightsToJSON(value.rights),
        'secret': value.secret,
        'status': value.status,
        'statusid': value.statusid,
        'subject': value.subject,
        'workflowInfo': value.workflowInfo,
        'workflowinfotimestamp': value.workflowinfotimestamp,
        'attendees': ((value.attendees as Array<any>).map(GetAktResponseAppointmentAllOfAttendeesToJSON)),
        'cancancel': value.cancancel,
        'enddatetime': value.enddatetime,
        'enddatetimeutc': value.enddatetimeutc,
        'members': GetAktResponseAppointmentAllOfMembersToJSON(value.members),
        'needconfirmation': value.needconfirmation,
        'notimechange': value.notimechange,
        'startdatetime': value.startdatetime,
        'startdatetimeutc': value.startdatetimeutc,
        'wholeday': value.wholeday,
        'shouldsendreply': value.shouldsendreply,
        'organizer': value.organizer,
        'recurrence': RecurrenceInfoToJSON(value.recurrence),
    };
}


