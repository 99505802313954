/* tslint:disable */
/* eslint-disable */
/**
 * Consolidate Legacy API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GetContactItem,
    GetContactItemFromJSON,
    GetContactItemFromJSONTyped,
    GetContactItemToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetContactsResponse
 */
export interface GetContactsResponse {
    /**
     * 
     * @type {Array<GetContactItem>}
     * @memberof GetContactsResponse
     */
    items?: Array<GetContactItem>;
    /**
     * 
     * @type {number}
     * @memberof GetContactsResponse
     */
    aktid: number;
    /**
     * 
     * @type {boolean}
     * @memberof GetContactsResponse
     */
    cancreatecontact: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetContactsResponse
     */
    cancreateaddress: boolean;
}

export function GetContactsResponseFromJSON(json: any): GetContactsResponse {
    return GetContactsResponseFromJSONTyped(json, false);
}

export function GetContactsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetContactsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'items': !exists(json, 'items') ? undefined : ((json['items'] as Array<any>).map(GetContactItemFromJSON)),
        'aktid': json['aktid'],
        'cancreatecontact': json['cancreatecontact'],
        'cancreateaddress': json['cancreateaddress'],
    };
}

export function GetContactsResponseToJSON(value?: GetContactsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'items': value.items === undefined ? undefined : ((value.items as Array<any>).map(GetContactItemToJSON)),
        'aktid': value.aktid,
        'cancreatecontact': value.cancreatecontact,
        'cancreateaddress': value.cancreateaddress,
    };
}


