/* tslint:disable */
/* eslint-disable */
/**
 * Consolidate Legacy API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InlineObject22
 */
export interface InlineObject22 {
    /**
     * 
     * @type {number}
     * @memberof InlineObject22
     */
    aktid: number;
    /**
     * 
     * @type {number}
     * @memberof InlineObject22
     */
    processid: number;
    /**
     * 
     * @type {number}
     * @memberof InlineObject22
     */
    startstepid: number;
}

export function InlineObject22FromJSON(json: any): InlineObject22 {
    return InlineObject22FromJSONTyped(json, false);
}

export function InlineObject22FromJSONTyped(json: any, ignoreDiscriminator: boolean): InlineObject22 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'aktid': json['aktid'],
        'processid': json['processid'],
        'startstepid': json['startstepid'],
    };
}

export function InlineObject22ToJSON(value?: InlineObject22 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'aktid': value.aktid,
        'processid': value.processid,
        'startstepid': value.startstepid,
    };
}


