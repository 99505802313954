/* tslint:disable */
/* eslint-disable */
/**
 * Consolidate Legacy API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AddActivityResponseSubstitute,
    AddActivityResponseSubstituteFromJSON,
    AddActivityResponseSubstituteFromJSONTyped,
    AddActivityResponseSubstituteToJSON,
} from './';

/**
 * 
 * @export
 * @interface AddActivityResponse
 */
export interface AddActivityResponse {
    /**
     * 
     * @type {number}
     * @memberof AddActivityResponse
     */
    _return: number;
    /**
     * 
     * @type {AddActivityResponseSubstitute}
     * @memberof AddActivityResponse
     */
    substitute?: AddActivityResponseSubstitute;
}

export function AddActivityResponseFromJSON(json: any): AddActivityResponse {
    return AddActivityResponseFromJSONTyped(json, false);
}

export function AddActivityResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddActivityResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        '_return': json['return'],
        'substitute': !exists(json, 'substitute') ? undefined : AddActivityResponseSubstituteFromJSON(json['substitute']),
    };
}

export function AddActivityResponseToJSON(value?: AddActivityResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'return': value._return,
        'substitute': AddActivityResponseSubstituteToJSON(value.substitute),
    };
}


