/* tslint:disable */
/* eslint-disable */
/**
 * Consolidate Legacy API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AddActivityRequest,
    AddActivityRequestFromJSON,
    AddActivityRequestToJSON,
    AddActivityResponse,
    AddActivityResponseFromJSON,
    AddActivityResponseToJSON,
    AddOrEditAddressRequest,
    AddOrEditAddressRequestFromJSON,
    AddOrEditAddressRequestToJSON,
    AddOrEditContactRequest,
    AddOrEditContactRequestFromJSON,
    AddOrEditContactRequestToJSON,
    AddOrRemoveClientRequest,
    AddOrRemoveClientRequestFromJSON,
    AddOrRemoveClientRequestToJSON,
    AddParticipantRequest,
    AddParticipantRequestFromJSON,
    AddParticipantRequestToJSON,
    DeRegisterPushRequest,
    DeRegisterPushRequestFromJSON,
    DeRegisterPushRequestToJSON,
    DefaultResponse,
    DefaultResponseFromJSON,
    DefaultResponseToJSON,
    FindActivityRequest,
    FindActivityRequestFromJSON,
    FindActivityRequestToJSON,
    FindActivityResponse,
    FindActivityResponseFromJSON,
    FindActivityResponseToJSON,
    FindAddressesRequest,
    FindAddressesRequestFromJSON,
    FindAddressesRequestToJSON,
    FindAddressesResponse,
    FindAddressesResponseFromJSON,
    FindAddressesResponseToJSON,
    GetAddressResponse,
    GetAddressResponseFromJSON,
    GetAddressResponseToJSON,
    GetAktRequest,
    GetAktRequestFromJSON,
    GetAktRequestToJSON,
    GetAktResponse,
    GetAktResponseFromJSON,
    GetAktResponseToJSON,
    GetAktTypesResponse,
    GetAktTypesResponseFromJSON,
    GetAktTypesResponseToJSON,
    GetAktsFromIdRequest,
    GetAktsFromIdRequestFromJSON,
    GetAktsFromIdRequestToJSON,
    GetAktsFromIdResponse,
    GetAktsFromIdResponseFromJSON,
    GetAktsFromIdResponseToJSON,
    GetApiInfoResponse,
    GetApiInfoResponseFromJSON,
    GetApiInfoResponseToJSON,
    GetAttachmentRequest,
    GetAttachmentRequestFromJSON,
    GetAttachmentRequestToJSON,
    GetContactResponse,
    GetContactResponseFromJSON,
    GetContactResponseToJSON,
    GetContactsRequest,
    GetContactsRequestFromJSON,
    GetContactsRequestToJSON,
    GetContactsResponse,
    GetContactsResponseFromJSON,
    GetContactsResponseToJSON,
    GetCountryListResponse,
    GetCountryListResponseFromJSON,
    GetCountryListResponseToJSON,
    GetFollowAktsRequest,
    GetFollowAktsRequestFromJSON,
    GetFollowAktsRequestToJSON,
    GetFollowAktsResponse,
    GetFollowAktsResponseFromJSON,
    GetFollowAktsResponseToJSON,
    GetInboxRequest,
    GetInboxRequestFromJSON,
    GetInboxRequestToJSON,
    GetInboxResponse,
    GetInboxResponseFromJSON,
    GetInboxResponseToJSON,
    GetProjectCustomDataResponse,
    GetProjectCustomDataResponseFromJSON,
    GetProjectCustomDataResponseToJSON,
    GetProjectListRequest,
    GetProjectListRequestFromJSON,
    GetProjectListRequestToJSON,
    GetProjectListResponse,
    GetProjectListResponseFromJSON,
    GetProjectListResponseToJSON,
    GetSecretGroupsResponse,
    GetSecretGroupsResponseFromJSON,
    GetSecretGroupsResponseToJSON,
    GetSentMailsRequest,
    GetSentMailsRequestFromJSON,
    GetSentMailsRequestToJSON,
    GetSentMailsResponse,
    GetSentMailsResponseFromJSON,
    GetSentMailsResponseToJSON,
    GetSettingsResponse,
    GetSettingsResponseFromJSON,
    GetSettingsResponseToJSON,
    GetSignatureResponse,
    GetSignatureResponseFromJSON,
    GetSignatureResponseToJSON,
    GetTodoRequest,
    GetTodoRequestFromJSON,
    GetTodoRequestToJSON,
    GetTodoResponse,
    GetTodoResponseFromJSON,
    GetTodoResponseToJSON,
    GetUserCCsRequest,
    GetUserCCsRequestFromJSON,
    GetUserCCsRequestToJSON,
    GetUserCCsResponse,
    GetUserCCsResponseFromJSON,
    GetUserCCsResponseToJSON,
    GetUserFoldersResponse,
    GetUserFoldersResponseFromJSON,
    GetUserFoldersResponseToJSON,
    GetUserTeamsResponse,
    GetUserTeamsResponseFromJSON,
    GetUserTeamsResponseToJSON,
    GetUserTimelineResponse,
    GetUserTimelineResponseFromJSON,
    GetUserTimelineResponseToJSON,
    GetUsersResponse,
    GetUsersResponseFromJSON,
    GetUsersResponseToJSON,
    GetthumbpicturesResponse,
    GetthumbpicturesResponseFromJSON,
    GetthumbpicturesResponseToJSON,
    InlineObject,
    InlineObjectFromJSON,
    InlineObjectToJSON,
    InlineObject1,
    InlineObject1FromJSON,
    InlineObject1ToJSON,
    InlineObject10,
    InlineObject10FromJSON,
    InlineObject10ToJSON,
    InlineObject11,
    InlineObject11FromJSON,
    InlineObject11ToJSON,
    InlineObject12,
    InlineObject12FromJSON,
    InlineObject12ToJSON,
    InlineObject13,
    InlineObject13FromJSON,
    InlineObject13ToJSON,
    InlineObject14,
    InlineObject14FromJSON,
    InlineObject14ToJSON,
    InlineObject15,
    InlineObject15FromJSON,
    InlineObject15ToJSON,
    InlineObject16,
    InlineObject16FromJSON,
    InlineObject16ToJSON,
    InlineObject17,
    InlineObject17FromJSON,
    InlineObject17ToJSON,
    InlineObject18,
    InlineObject18FromJSON,
    InlineObject18ToJSON,
    InlineObject19,
    InlineObject19FromJSON,
    InlineObject19ToJSON,
    InlineObject2,
    InlineObject2FromJSON,
    InlineObject2ToJSON,
    InlineObject20,
    InlineObject20FromJSON,
    InlineObject20ToJSON,
    InlineObject21,
    InlineObject21FromJSON,
    InlineObject21ToJSON,
    InlineObject22,
    InlineObject22FromJSON,
    InlineObject22ToJSON,
    InlineObject23,
    InlineObject23FromJSON,
    InlineObject23ToJSON,
    InlineObject3,
    InlineObject3FromJSON,
    InlineObject3ToJSON,
    InlineObject4,
    InlineObject4FromJSON,
    InlineObject4ToJSON,
    InlineObject5,
    InlineObject5FromJSON,
    InlineObject5ToJSON,
    InlineObject6,
    InlineObject6FromJSON,
    InlineObject6ToJSON,
    InlineObject7,
    InlineObject7FromJSON,
    InlineObject7ToJSON,
    InlineObject8,
    InlineObject8FromJSON,
    InlineObject8ToJSON,
    InlineObject9,
    InlineObject9FromJSON,
    InlineObject9ToJSON,
    InlineResponse200,
    InlineResponse200FromJSON,
    InlineResponse200ToJSON,
    InlineResponse2001,
    InlineResponse2001FromJSON,
    InlineResponse2001ToJSON,
    InlineResponse2002,
    InlineResponse2002FromJSON,
    InlineResponse2002ToJSON,
    LoginRequest,
    LoginRequestFromJSON,
    LoginRequestToJSON,
    LoginResponse,
    LoginResponseFromJSON,
    LoginResponseToJSON,
    RegisterPushRequest,
    RegisterPushRequestFromJSON,
    RegisterPushRequestToJSON,
    RemoveAktsFromFolderRequest,
    RemoveAktsFromFolderRequestFromJSON,
    RemoveAktsFromFolderRequestToJSON,
    RemoveParticipantRequest,
    RemoveParticipantRequestFromJSON,
    RemoveParticipantRequestToJSON,
    WorkflowCancelProcessRequest,
    WorkflowCancelProcessRequestFromJSON,
    WorkflowCancelProcessRequestToJSON,
    WorkflowDoNextStepRequest,
    WorkflowDoNextStepRequestFromJSON,
    WorkflowDoNextStepRequestToJSON,
    WorkflowDoNextStepResponse,
    WorkflowDoNextStepResponseFromJSON,
    WorkflowDoNextStepResponseToJSON,
} from '../models';

export interface Add2FavoriteRequest {
    inlineObject5?: InlineObject5;
}

export interface AddActivityOperationRequest {
    addActivityRequest?: AddActivityRequest;
}

export interface AddAktsToFolderRequest {
    inlineObject3?: InlineObject3;
}

export interface AddAttachmentRequest {
    inlineObject20?: InlineObject20;
}

export interface AddCcRequest {
    inlineObject13?: InlineObject13;
}

export interface AddClientRequest {
    addOrRemoveClientRequest?: AddOrRemoveClientRequest;
}

export interface AddOrEditAddressOperationRequest {
    addOrEditAddressRequest?: AddOrEditAddressRequest;
}

export interface AddOrEditContactOperationRequest {
    addOrEditContactRequest?: AddOrEditContactRequest;
}

export interface AddParticipantOperationRequest {
    addParticipantRequest?: AddParticipantRequest;
}

export interface AktDelegateRequest {
    inlineObject16?: InlineObject16;
}

export interface CancelAppointmentRequest {
    inlineObject18?: InlineObject18;
}

export interface ChangeRecipientRequest {
    inlineObject6?: InlineObject6;
}

export interface CommentActivityRequest {
    inlineObject21?: InlineObject21;
}

export interface ConfirmAppointmentRequest {
    inlineObject17?: InlineObject17;
}

export interface CreateAppointmentMailRequest {
    inlineObject23?: InlineObject23;
}

export interface DeRegisterPushOperationRequest {
    deRegisterPushRequest?: DeRegisterPushRequest;
}

export interface DeleteAktRequest {
    id: number;
}

export interface FindActivityOperationRequest {
    findActivityRequest?: FindActivityRequest;
}

export interface FindAddressesOperationRequest {
    findAddressesRequest?: FindAddressesRequest;
}

export interface GetAddressRequest {
    id: string;
    getcustom?: boolean;
}

export interface GetAktOperationRequest {
    id: number;
    getAktRequest?: GetAktRequest;
}

export interface GetAktTypesRequest {
    hash?: number;
    all?: boolean;
}

export interface GetAktsFromIdOperationRequest {
    getAktsFromIdRequest?: GetAktsFromIdRequest;
}

export interface GetAttachmentOperationRequest {
    id?: number;
    getAttachmentRequest?: GetAttachmentRequest;
}

export interface GetCommentRequest {
    id: number;
}

export interface GetContactRequest {
    id: number;
    getcustom?: boolean;
}

export interface GetContactsOperationRequest {
    hash?: number;
    getContactsRequest?: GetContactsRequest;
}

export interface GetFollowAktsOperationRequest {
    getFollowAktsRequest?: GetFollowAktsRequest;
}

export interface GetInboxOperationRequest {
    hash?: number;
    getInboxRequest?: GetInboxRequest;
}

export interface GetProjectCustomDataRequest {
    id: number;
}

export interface GetProjectListOperationRequest {
    hash?: number;
    getProjectListRequest?: GetProjectListRequest;
}

export interface GetSentMailsOperationRequest {
    hash?: number;
    getSentMailsRequest?: GetSentMailsRequest;
}

export interface GetThumbpicturesRequest {
    idlist: string;
}

export interface GetTodoOperationRequest {
    hash?: number;
    getTodoRequest?: GetTodoRequest;
}

export interface GetUserCCsOperationRequest {
    hash?: number;
    getUserCCsRequest?: GetUserCCsRequest;
}

export interface GetUserFoldersRequest {
    hash?: number;
}

export interface GetUserTeamsRequest {
    showFuture?: boolean;
    hash?: number;
}

export interface GetUserTimelineRequest {
    id?: string;
    hash?: number;
    inlineObject?: InlineObject;
}

export interface GetUsersRequest {
    hash?: number;
}

export interface LoginOperationRequest {
    loginRequest?: LoginRequest;
}

export interface RegisterPushOperationRequest {
    registerPushRequest?: RegisterPushRequest;
}

export interface RemoveAktsFromFolderOperationRequest {
    removeAktsFromFolderRequest?: RemoveAktsFromFolderRequest;
}

export interface RemoveAttachmentRequest {
    inlineObject14?: InlineObject14;
}

export interface RemoveClientRequest {
    addOrRemoveClientRequest?: AddOrRemoveClientRequest;
}

export interface RemoveFavoriteRequest {
    inlineObject4?: InlineObject4;
}

export interface RemoveParticipantOperationRequest {
    removeParticipantRequest?: RemoveParticipantRequest;
}

export interface SetAktDoneRequest {
    id?: number;
}

export interface SetAktProjectRequest {
    inlineObject9?: InlineObject9;
}

export interface SetAktReadRequest {
    id?: number;
}

export interface SetAktSecretGroupRequest {
    inlineObject11?: InlineObject11;
}

export interface SetAktSeenRequest {
    id?: number;
}

export interface SetAktTypeRequest {
    inlineObject7?: InlineObject7;
}

export interface SetAlarmTimeRequest {
    inlineObject12?: InlineObject12;
}

export interface SetAppointmentDateRequest {
    inlineObject19?: InlineObject19;
}

export interface SetDateRequest {
    inlineObject15?: InlineObject15;
}

export interface SetPriorityRequest {
    inlineObject10?: InlineObject10;
}

export interface SetSignatureRequest {
    inlineObject2?: InlineObject2;
}

export interface SetStatusRequest {
    inlineObject8?: InlineObject8;
}

export interface SetSubjectRequest {
    inlineObject1?: InlineObject1;
}

export interface WorkflowCancelProcessOperationRequest {
    workflowCancelProcessRequest?: WorkflowCancelProcessRequest;
}

export interface WorkflowDoNextStepOperationRequest {
    workflowDoNextStepRequest?: WorkflowDoNextStepRequest;
}

export interface WorkflowStartProcessRequest {
    inlineObject22?: InlineObject22;
}

/**
 * 
 */
export class DefaultApi extends runtime.BaseAPI {

    /**
     * Add person address or project to favorites
     */
    async add2FavoriteRaw(requestParameters: Add2FavoriteRequest): Promise<runtime.ApiResponse<DefaultResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["token"] = this.configuration.apiKey("token"); // legacy authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["cons.app"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/app/add2favorite`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InlineObject5ToJSON(requestParameters.inlineObject5),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DefaultResponseFromJSON(jsonValue));
    }

    /**
     * Add person address or project to favorites
     */
    async add2Favorite(inlineObject5?: InlineObject5): Promise<DefaultResponse> {
        const response = await this.add2FavoriteRaw({ inlineObject5: inlineObject5 });
        return await response.value();
    }

    /**
     * Create a new activity. Returns the activity ID on success and 0 on failure
     */
    async addActivityRaw(requestParameters: AddActivityOperationRequest): Promise<runtime.ApiResponse<AddActivityResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["token"] = this.configuration.apiKey("token"); // legacy authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["cons.app"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/app/addactivity`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddActivityRequestToJSON(requestParameters.addActivityRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AddActivityResponseFromJSON(jsonValue));
    }

    /**
     * Create a new activity. Returns the activity ID on success and 0 on failure
     */
    async addActivity(addActivityRequest?: AddActivityRequest): Promise<AddActivityResponse> {
        const response = await this.addActivityRaw({ addActivityRequest: addActivityRequest });
        return await response.value();
    }

    /**
     * Add one or more activities to a user folder
     */
    async addAktsToFolderRaw(requestParameters: AddAktsToFolderRequest): Promise<runtime.ApiResponse<DefaultResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["token"] = this.configuration.apiKey("token"); // legacy authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["cons.app"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/app/addaktstofolder`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InlineObject3ToJSON(requestParameters.inlineObject3),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DefaultResponseFromJSON(jsonValue));
    }

    /**
     * Add one or more activities to a user folder
     */
    async addAktsToFolder(inlineObject3?: InlineObject3): Promise<DefaultResponse> {
        const response = await this.addAktsToFolderRaw({ inlineObject3: inlineObject3 });
        return await response.value();
    }

    /**
     * Add attachment to activity
     */
    async addAttachmentRaw(requestParameters: AddAttachmentRequest): Promise<runtime.ApiResponse<InlineResponse200>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["token"] = this.configuration.apiKey("token"); // legacy authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["cons.app"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/app/addattachment`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InlineObject20ToJSON(requestParameters.inlineObject20),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse200FromJSON(jsonValue));
    }

    /**
     * Add attachment to activity
     */
    async addAttachment(inlineObject20?: InlineObject20): Promise<InlineResponse200> {
        const response = await this.addAttachmentRaw({ inlineObject20: inlineObject20 });
        return await response.value();
    }

    /**
     * Add CC to an activity
     */
    async addCcRaw(requestParameters: AddCcRequest): Promise<runtime.ApiResponse<DefaultResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["token"] = this.configuration.apiKey("token"); // legacy authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["cons.app"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/app/addcc`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InlineObject13ToJSON(requestParameters.inlineObject13),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DefaultResponseFromJSON(jsonValue));
    }

    /**
     * Add CC to an activity
     */
    async addCc(inlineObject13?: InlineObject13): Promise<DefaultResponse> {
        const response = await this.addCcRaw({ inlineObject13: inlineObject13 });
        return await response.value();
    }

    /**
     * Adds a client to an activity
     */
    async addClientRaw(requestParameters: AddClientRequest): Promise<runtime.ApiResponse<number>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["token"] = this.configuration.apiKey("token"); // legacy authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["cons.app"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/app/addclient`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddOrRemoveClientRequestToJSON(requestParameters.addOrRemoveClientRequest),
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Adds a client to an activity
     */
    async addClient(addOrRemoveClientRequest?: AddOrRemoveClientRequest): Promise<number> {
        const response = await this.addClientRaw({ addOrRemoveClientRequest: addOrRemoveClientRequest });
        return await response.value();
    }

    /**
     * Add new or update existing address. Returns new ANR on success
     */
    async addOrEditAddressRaw(requestParameters: AddOrEditAddressOperationRequest): Promise<runtime.ApiResponse<DefaultResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["token"] = this.configuration.apiKey("token"); // legacy authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["cons.app"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/app/addoreditaddress`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddOrEditAddressRequestToJSON(requestParameters.addOrEditAddressRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DefaultResponseFromJSON(jsonValue));
    }

    /**
     * Add new or update existing address. Returns new ANR on success
     */
    async addOrEditAddress(addOrEditAddressRequest?: AddOrEditAddressRequest): Promise<DefaultResponse> {
        const response = await this.addOrEditAddressRaw({ addOrEditAddressRequest: addOrEditAddressRequest });
        return await response.value();
    }

    /**
     * Sets the appointment date for an appointment
     */
    async addOrEditContactRaw(requestParameters: AddOrEditContactOperationRequest): Promise<runtime.ApiResponse<InlineResponse2001>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["token"] = this.configuration.apiKey("token"); // legacy authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["cons.app"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/app/addoreditcontact`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddOrEditContactRequestToJSON(requestParameters.addOrEditContactRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse2001FromJSON(jsonValue));
    }

    /**
     * Sets the appointment date for an appointment
     */
    async addOrEditContact(addOrEditContactRequest?: AddOrEditContactRequest): Promise<InlineResponse2001> {
        const response = await this.addOrEditContactRaw({ addOrEditContactRequest: addOrEditContactRequest });
        return await response.value();
    }

    /**
     * Adds a participant to an appointment
     */
    async addParticipantRaw(requestParameters: AddParticipantOperationRequest): Promise<runtime.ApiResponse<number>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["token"] = this.configuration.apiKey("token"); // legacy authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["cons.app"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/app/addparticipant`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddParticipantRequestToJSON(requestParameters.addParticipantRequest),
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Adds a participant to an appointment
     */
    async addParticipant(addParticipantRequest?: AddParticipantRequest): Promise<number> {
        const response = await this.addParticipantRaw({ addParticipantRequest: addParticipantRequest });
        return await response.value();
    }

    /**
     * Delegate activity to user
     */
    async aktDelegateRaw(requestParameters: AktDelegateRequest): Promise<runtime.ApiResponse<DefaultResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["token"] = this.configuration.apiKey("token"); // legacy authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["cons.app"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/app/aktdelegate`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InlineObject16ToJSON(requestParameters.inlineObject16),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DefaultResponseFromJSON(jsonValue));
    }

    /**
     * Delegate activity to user
     */
    async aktDelegate(inlineObject16?: InlineObject16): Promise<DefaultResponse> {
        const response = await this.aktDelegateRaw({ inlineObject16: inlineObject16 });
        return await response.value();
    }

    /**
     * Cancel an appointment
     */
    async cancelAppointmentRaw(requestParameters: CancelAppointmentRequest): Promise<runtime.ApiResponse<DefaultResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["token"] = this.configuration.apiKey("token"); // legacy authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["cons.app"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/app/cancelappointment`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InlineObject18ToJSON(requestParameters.inlineObject18),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DefaultResponseFromJSON(jsonValue));
    }

    /**
     * Cancel an appointment
     */
    async cancelAppointment(inlineObject18?: InlineObject18): Promise<DefaultResponse> {
        const response = await this.cancelAppointmentRaw({ inlineObject18: inlineObject18 });
        return await response.value();
    }

    /**
     * Change activity recipients
     */
    async changeRecipientRaw(requestParameters: ChangeRecipientRequest): Promise<runtime.ApiResponse<DefaultResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["token"] = this.configuration.apiKey("token"); // legacy authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["cons.app"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/app/changerecipient`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InlineObject6ToJSON(requestParameters.inlineObject6),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DefaultResponseFromJSON(jsonValue));
    }

    /**
     * Change activity recipients
     */
    async changeRecipient(inlineObject6?: InlineObject6): Promise<DefaultResponse> {
        const response = await this.changeRecipientRaw({ inlineObject6: inlineObject6 });
        return await response.value();
    }

    /**
     * Adds a comment to an activity
     */
    async commentActivityRaw(requestParameters: CommentActivityRequest): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["token"] = this.configuration.apiKey("token"); // legacy authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["cons.app"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/app/commentactivity`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InlineObject21ToJSON(requestParameters.inlineObject21),
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Adds a comment to an activity
     */
    async commentActivity(inlineObject21?: InlineObject21): Promise<string> {
        const response = await this.commentActivityRaw({ inlineObject21: inlineObject21 });
        return await response.value();
    }

    /**
     * confirms an appointment
     */
    async confirmAppointmentRaw(requestParameters: ConfirmAppointmentRequest): Promise<runtime.ApiResponse<DefaultResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["token"] = this.configuration.apiKey("token"); // legacy authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["cons.app"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/app/confirmappointment`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InlineObject17ToJSON(requestParameters.inlineObject17),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DefaultResponseFromJSON(jsonValue));
    }

    /**
     * confirms an appointment
     */
    async confirmAppointment(inlineObject17?: InlineObject17): Promise<DefaultResponse> {
        const response = await this.confirmAppointmentRaw({ inlineObject17: inlineObject17 });
        return await response.value();
    }

    /**
     * Sends ICal invite to external participants
     */
    async createAppointmentMailRaw(requestParameters: CreateAppointmentMailRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["token"] = this.configuration.apiKey("token"); // legacy authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["cons.app"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/app/createappointmentmail`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InlineObject23ToJSON(requestParameters.inlineObject23),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Sends ICal invite to external participants
     */
    async createAppointmentMail(inlineObject23?: InlineObject23): Promise<void> {
        await this.createAppointmentMailRaw({ inlineObject23: inlineObject23 });
    }

    /**
     * Removes the push settings of the device from table mobiledevices
     */
    async deRegisterPushRaw(requestParameters: DeRegisterPushOperationRequest): Promise<runtime.ApiResponse<DefaultResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["token"] = this.configuration.apiKey("token"); // legacy authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["cons.app"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/app/deregisterpush`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DeRegisterPushRequestToJSON(requestParameters.deRegisterPushRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DefaultResponseFromJSON(jsonValue));
    }

    /**
     * Removes the push settings of the device from table mobiledevices
     */
    async deRegisterPush(deRegisterPushRequest?: DeRegisterPushRequest): Promise<DefaultResponse> {
        const response = await this.deRegisterPushRaw({ deRegisterPushRequest: deRegisterPushRequest });
        return await response.value();
    }

    /**
     * Deletes an activity
     */
    async deleteAktRaw(requestParameters: DeleteAktRequest): Promise<runtime.ApiResponse<DefaultResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteAkt.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.id !== undefined && requestParameters.id !== null) {
            headerParameters['id'] = String(requestParameters.id);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["token"] = this.configuration.apiKey("token"); // legacy authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["cons.app"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/app/deleteakt`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DefaultResponseFromJSON(jsonValue));
    }

    /**
     * Deletes an activity
     */
    async deleteAkt(id: number): Promise<DefaultResponse> {
        const response = await this.deleteAktRaw({ id: id });
        return await response.value();
    }

    /**
     * Search for activities by search string
     */
    async findActivityRaw(requestParameters: FindActivityOperationRequest): Promise<runtime.ApiResponse<FindActivityResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["token"] = this.configuration.apiKey("token"); // legacy authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["cons.app"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/app/findactivity`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FindActivityRequestToJSON(requestParameters.findActivityRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => FindActivityResponseFromJSON(jsonValue));
    }

    /**
     * Search for activities by search string
     */
    async findActivity(findActivityRequest?: FindActivityRequest): Promise<FindActivityResponse> {
        const response = await this.findActivityRaw({ findActivityRequest: findActivityRequest });
        return await response.value();
    }

    /**
     * Search for addresses by search string
     */
    async findAddressesRaw(requestParameters: FindAddressesOperationRequest): Promise<runtime.ApiResponse<FindAddressesResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["token"] = this.configuration.apiKey("token"); // legacy authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["cons.app"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/app/findaddresses`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FindAddressesRequestToJSON(requestParameters.findAddressesRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => FindAddressesResponseFromJSON(jsonValue));
    }

    /**
     * Search for addresses by search string
     */
    async findAddresses(findAddressesRequest?: FindAddressesRequest): Promise<FindAddressesResponse> {
        const response = await this.findAddressesRaw({ findAddressesRequest: findAddressesRequest });
        return await response.value();
    }

    /**
     * Get address  details
     */
    async getAddressRaw(requestParameters: GetAddressRequest): Promise<runtime.ApiResponse<GetAddressResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getAddress.');
        }

        const queryParameters: any = {};

        if (requestParameters.getcustom !== undefined) {
            queryParameters['getcustom'] = requestParameters.getcustom;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.id !== undefined && requestParameters.id !== null) {
            headerParameters['id'] = String(requestParameters.id);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["token"] = this.configuration.apiKey("token"); // legacy authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["cons.app"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/app/getaddress`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetAddressResponseFromJSON(jsonValue));
    }

    /**
     * Get address  details
     */
    async getAddress(id: string, getcustom?: boolean): Promise<GetAddressResponse> {
        const response = await this.getAddressRaw({ id: id, getcustom: getcustom });
        return await response.value();
    }

    /**
     * Get aktivity by id
     */
    async getAktRaw(requestParameters: GetAktOperationRequest): Promise<runtime.ApiResponse<GetAktResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getAkt.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.id !== undefined && requestParameters.id !== null) {
            headerParameters['id'] = String(requestParameters.id);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["token"] = this.configuration.apiKey("token"); // legacy authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["cons.app"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/app/getakt`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GetAktRequestToJSON(requestParameters.getAktRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetAktResponseFromJSON(jsonValue));
    }

    /**
     * Get aktivity by id
     */
    async getAkt(id: number, getAktRequest?: GetAktRequest): Promise<GetAktResponse> {
        const response = await this.getAktRaw({ id: id, getAktRequest: getAktRequest });
        return await response.value();
    }

    /**
     * Gets the act types
     */
    async getAktTypesRaw(requestParameters: GetAktTypesRequest): Promise<runtime.ApiResponse<GetAktTypesResponse>> {
        const queryParameters: any = {};

        if (requestParameters.all !== undefined) {
            queryParameters['all'] = requestParameters.all;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.hash !== undefined && requestParameters.hash !== null) {
            headerParameters['hash'] = String(requestParameters.hash);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["token"] = this.configuration.apiKey("token"); // legacy authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["cons.app"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/app/getakttypes`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetAktTypesResponseFromJSON(jsonValue));
    }

    /**
     * Gets the act types
     */
    async getAktTypes(hash?: number, all?: boolean): Promise<GetAktTypesResponse> {
        const response = await this.getAktTypesRaw({ hash: hash, all: all });
        return await response.value();
    }

    /**
     * Get activities for contact, address, project, folder or team
     */
    async getAktsFromIdRaw(requestParameters: GetAktsFromIdOperationRequest): Promise<runtime.ApiResponse<GetAktsFromIdResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["token"] = this.configuration.apiKey("token"); // legacy authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["cons.app"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/app/getaktsfromid`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GetAktsFromIdRequestToJSON(requestParameters.getAktsFromIdRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetAktsFromIdResponseFromJSON(jsonValue));
    }

    /**
     * Get activities for contact, address, project, folder or team
     */
    async getAktsFromId(getAktsFromIdRequest?: GetAktsFromIdRequest): Promise<GetAktsFromIdResponse> {
        const response = await this.getAktsFromIdRaw({ getAktsFromIdRequest: getAktsFromIdRequest });
        return await response.value();
    }

    /**
     * Get Information about the API
     */
    async getApiInfoRaw(): Promise<runtime.ApiResponse<GetApiInfoResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["token"] = this.configuration.apiKey("token"); // legacy authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["cons.app"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/app/debugjson`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetApiInfoResponseFromJSON(jsonValue));
    }

    /**
     * Get Information about the API
     */
    async getApiInfo(): Promise<GetApiInfoResponse> {
        const response = await this.getApiInfoRaw();
        return await response.value();
    }

    /**
     * Get activity attachment by ID
     */
    async getAttachmentRaw(requestParameters: GetAttachmentOperationRequest): Promise<runtime.ApiResponse<Blob>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.id !== undefined && requestParameters.id !== null) {
            headerParameters['id'] = String(requestParameters.id);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["token"] = this.configuration.apiKey("token"); // legacy authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["cons.app"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/app/getattachment`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GetAttachmentRequestToJSON(requestParameters.getAttachmentRequest),
        });

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Get activity attachment by ID
     */
    async getAttachment(id?: number, getAttachmentRequest?: GetAttachmentRequest): Promise<Blob> {
        const response = await this.getAttachmentRaw({ id: id, getAttachmentRequest: getAttachmentRequest });
        return await response.value();
    }

    /**
     * Get activity comment as HTML
     */
    async getCommentRaw(requestParameters: GetCommentRequest): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getComment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.id !== undefined && requestParameters.id !== null) {
            headerParameters['id'] = String(requestParameters.id);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["token"] = this.configuration.apiKey("token"); // legacy authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["cons.app"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/app/getcomment`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Get activity comment as HTML
     */
    async getComment(id: number): Promise<string> {
        const response = await this.getCommentRaw({ id: id });
        return await response.value();
    }

    /**
     * Get contact details
     */
    async getContactRaw(requestParameters: GetContactRequest): Promise<runtime.ApiResponse<GetContactResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getContact.');
        }

        const queryParameters: any = {};

        if (requestParameters.getcustom !== undefined) {
            queryParameters['getcustom'] = requestParameters.getcustom;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.id !== undefined && requestParameters.id !== null) {
            headerParameters['id'] = String(requestParameters.id);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["token"] = this.configuration.apiKey("token"); // legacy authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["cons.app"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/app/getcontact`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetContactResponseFromJSON(jsonValue));
    }

    /**
     * Get contact details
     */
    async getContact(id: number, getcustom?: boolean): Promise<GetContactResponse> {
        const response = await this.getContactRaw({ id: id, getcustom: getcustom });
        return await response.value();
    }

    /**
     * Get user contacts without details
     */
    async getContactsRaw(requestParameters: GetContactsOperationRequest): Promise<runtime.ApiResponse<GetContactsResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.hash !== undefined && requestParameters.hash !== null) {
            headerParameters['hash'] = String(requestParameters.hash);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["token"] = this.configuration.apiKey("token"); // legacy authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["cons.app"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/app/getcontacts`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GetContactsRequestToJSON(requestParameters.getContactsRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetContactsResponseFromJSON(jsonValue));
    }

    /**
     * Get user contacts without details
     */
    async getContacts(hash?: number, getContactsRequest?: GetContactsRequest): Promise<GetContactsResponse> {
        const response = await this.getContactsRaw({ hash: hash, getContactsRequest: getContactsRequest });
        return await response.value();
    }

    /**
     * Get country list
     */
    async getCountryListRaw(): Promise<runtime.ApiResponse<GetCountryListResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["token"] = this.configuration.apiKey("token"); // legacy authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["cons.app"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/app/getcountrylist`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetCountryListResponseFromJSON(jsonValue));
    }

    /**
     * Get country list
     */
    async getCountryList(): Promise<GetCountryListResponse> {
        const response = await this.getCountryListRaw();
        return await response.value();
    }

    /**
     * Get following activities for activity
     */
    async getFollowAktsRaw(requestParameters: GetFollowAktsOperationRequest): Promise<runtime.ApiResponse<GetFollowAktsResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["token"] = this.configuration.apiKey("token"); // legacy authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["cons.app"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/app/getfollowakts`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GetFollowAktsRequestToJSON(requestParameters.getFollowAktsRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetFollowAktsResponseFromJSON(jsonValue));
    }

    /**
     * Get following activities for activity
     */
    async getFollowAkts(getFollowAktsRequest?: GetFollowAktsRequest): Promise<GetFollowAktsResponse> {
        const response = await this.getFollowAktsRaw({ getFollowAktsRequest: getFollowAktsRequest });
        return await response.value();
    }

    /**
     * Get Inbox items
     */
    async getInboxRaw(requestParameters: GetInboxOperationRequest): Promise<runtime.ApiResponse<GetInboxResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.hash !== undefined && requestParameters.hash !== null) {
            headerParameters['hash'] = String(requestParameters.hash);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["token"] = this.configuration.apiKey("token"); // legacy authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["cons.app"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/app/getinbox`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GetInboxRequestToJSON(requestParameters.getInboxRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetInboxResponseFromJSON(jsonValue));
    }

    /**
     * Get Inbox items
     */
    async getInbox(hash?: number, getInboxRequest?: GetInboxRequest): Promise<GetInboxResponse> {
        const response = await this.getInboxRaw({ hash: hash, getInboxRequest: getInboxRequest });
        return await response.value();
    }

    /**
     * Gets the custom data for project
     */
    async getProjectCustomDataRaw(requestParameters: GetProjectCustomDataRequest): Promise<runtime.ApiResponse<GetProjectCustomDataResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getProjectCustomData.');
        }

        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["token"] = this.configuration.apiKey("token"); // legacy authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["cons.app"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/app/getprojectcustomdata`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetProjectCustomDataResponseFromJSON(jsonValue));
    }

    /**
     * Gets the custom data for project
     */
    async getProjectCustomData(id: number): Promise<GetProjectCustomDataResponse> {
        const response = await this.getProjectCustomDataRaw({ id: id });
        return await response.value();
    }

    /**
     * Get project list
     */
    async getProjectListRaw(requestParameters: GetProjectListOperationRequest): Promise<runtime.ApiResponse<GetProjectListResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.hash !== undefined && requestParameters.hash !== null) {
            headerParameters['hash'] = String(requestParameters.hash);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["token"] = this.configuration.apiKey("token"); // legacy authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["cons.app"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/app/getprojectlist`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GetProjectListRequestToJSON(requestParameters.getProjectListRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetProjectListResponseFromJSON(jsonValue));
    }

    /**
     * Get project list
     */
    async getProjectList(hash?: number, getProjectListRequest?: GetProjectListRequest): Promise<GetProjectListResponse> {
        const response = await this.getProjectListRaw({ hash: hash, getProjectListRequest: getProjectListRequest });
        return await response.value();
    }

    /**
     * Get the Secret groups available for the current user
     */
    async getSecretGroupsRaw(): Promise<runtime.ApiResponse<GetSecretGroupsResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["token"] = this.configuration.apiKey("token"); // legacy authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["cons.app"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/app/getsecretgroups`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetSecretGroupsResponseFromJSON(jsonValue));
    }

    /**
     * Get the Secret groups available for the current user
     */
    async getSecretGroups(): Promise<GetSecretGroupsResponse> {
        const response = await this.getSecretGroupsRaw();
        return await response.value();
    }

    /**
     * Get list of mails send by the current user
     */
    async getSentMailsRaw(requestParameters: GetSentMailsOperationRequest): Promise<runtime.ApiResponse<GetSentMailsResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.hash !== undefined && requestParameters.hash !== null) {
            headerParameters['hash'] = String(requestParameters.hash);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["token"] = this.configuration.apiKey("token"); // legacy authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["cons.app"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/app/getsentmails`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GetSentMailsRequestToJSON(requestParameters.getSentMailsRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetSentMailsResponseFromJSON(jsonValue));
    }

    /**
     * Get list of mails send by the current user
     */
    async getSentMails(hash?: number, getSentMailsRequest?: GetSentMailsRequest): Promise<GetSentMailsResponse> {
        const response = await this.getSentMailsRaw({ hash: hash, getSentMailsRequest: getSentMailsRequest });
        return await response.value();
    }

    /**
     * Gets the settings from the user
     */
    async getSettingsRaw(): Promise<runtime.ApiResponse<GetSettingsResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["token"] = this.configuration.apiKey("token"); // legacy authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["cons.app"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/app/getsettings`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetSettingsResponseFromJSON(jsonValue));
    }

    /**
     * Gets the settings from the user
     */
    async getSettings(): Promise<GetSettingsResponse> {
        const response = await this.getSettingsRaw();
        return await response.value();
    }

    /**
     * Get user signature
     */
    async getSignatureRaw(): Promise<runtime.ApiResponse<GetSignatureResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["token"] = this.configuration.apiKey("token"); // legacy authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["cons.app"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/app/getsignature`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetSignatureResponseFromJSON(jsonValue));
    }

    /**
     * Get user signature
     */
    async getSignature(): Promise<GetSignatureResponse> {
        const response = await this.getSignatureRaw();
        return await response.value();
    }

    /**
     * Get a list of thumbpictures for attachments, if existing
     */
    async getThumbpicturesRaw(requestParameters: GetThumbpicturesRequest): Promise<runtime.ApiResponse<GetthumbpicturesResponse>> {
        if (requestParameters.idlist === null || requestParameters.idlist === undefined) {
            throw new runtime.RequiredError('idlist','Required parameter requestParameters.idlist was null or undefined when calling getThumbpictures.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.idlist !== undefined && requestParameters.idlist !== null) {
            headerParameters['idlist'] = String(requestParameters.idlist);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["token"] = this.configuration.apiKey("token"); // legacy authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["cons.app"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/app/getthumbpictures`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetthumbpicturesResponseFromJSON(jsonValue));
    }

    /**
     * Get a list of thumbpictures for attachments, if existing
     */
    async getThumbpictures(idlist: string): Promise<GetthumbpicturesResponse> {
        const response = await this.getThumbpicturesRaw({ idlist: idlist });
        return await response.value();
    }

    /**
     * Get Todos for current future
     */
    async getTodoRaw(requestParameters: GetTodoOperationRequest): Promise<runtime.ApiResponse<GetTodoResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.hash !== undefined && requestParameters.hash !== null) {
            headerParameters['hash'] = String(requestParameters.hash);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["token"] = this.configuration.apiKey("token"); // legacy authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["cons.app"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/app/gettodo`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GetTodoRequestToJSON(requestParameters.getTodoRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetTodoResponseFromJSON(jsonValue));
    }

    /**
     * Get Todos for current future
     */
    async getTodo(hash?: number, getTodoRequest?: GetTodoRequest): Promise<GetTodoResponse> {
        const response = await this.getTodoRaw({ hash: hash, getTodoRequest: getTodoRequest });
        return await response.value();
    }

    /**
     * Get user CCs
     */
    async getUserCCsRaw(requestParameters: GetUserCCsOperationRequest): Promise<runtime.ApiResponse<GetUserCCsResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.hash !== undefined && requestParameters.hash !== null) {
            headerParameters['hash'] = String(requestParameters.hash);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["token"] = this.configuration.apiKey("token"); // legacy authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["cons.app"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/app/getuserccs`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GetUserCCsRequestToJSON(requestParameters.getUserCCsRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetUserCCsResponseFromJSON(jsonValue));
    }

    /**
     * Get user CCs
     */
    async getUserCCs(hash?: number, getUserCCsRequest?: GetUserCCsRequest): Promise<GetUserCCsResponse> {
        const response = await this.getUserCCsRaw({ hash: hash, getUserCCsRequest: getUserCCsRequest });
        return await response.value();
    }

    /**
     * Gets user folders, which have activities
     */
    async getUserFoldersRaw(requestParameters: GetUserFoldersRequest): Promise<runtime.ApiResponse<GetUserFoldersResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.hash !== undefined && requestParameters.hash !== null) {
            headerParameters['hash'] = String(requestParameters.hash);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["token"] = this.configuration.apiKey("token"); // legacy authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["cons.app"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/app/getuserfolders`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetUserFoldersResponseFromJSON(jsonValue));
    }

    /**
     * Gets user folders, which have activities
     */
    async getUserFolders(hash?: number): Promise<GetUserFoldersResponse> {
        const response = await this.getUserFoldersRaw({ hash: hash });
        return await response.value();
    }

    /**
     * Gets the teams for the current user, which have open activities
     */
    async getUserTeamsRaw(requestParameters: GetUserTeamsRequest): Promise<runtime.ApiResponse<GetUserTeamsResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.showFuture !== undefined && requestParameters.showFuture !== null) {
            headerParameters['showFuture'] = String(requestParameters.showFuture);
        }

        if (requestParameters.hash !== undefined && requestParameters.hash !== null) {
            headerParameters['hash'] = String(requestParameters.hash);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["token"] = this.configuration.apiKey("token"); // legacy authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["cons.app"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/app/getuserteamsnew`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetUserTeamsResponseFromJSON(jsonValue));
    }

    /**
     * Gets the teams for the current user, which have open activities
     */
    async getUserTeams(showFuture?: boolean, hash?: number): Promise<GetUserTeamsResponse> {
        const response = await this.getUserTeamsRaw({ showFuture: showFuture, hash: hash });
        return await response.value();
    }

    /**
     * Returns one obejct per day, with all appointments for this day sorted by day and starttime
     */
    async getUserTimelineRaw(requestParameters: GetUserTimelineRequest): Promise<runtime.ApiResponse<GetUserTimelineResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.id !== undefined && requestParameters.id !== null) {
            headerParameters['id'] = String(requestParameters.id);
        }

        if (requestParameters.hash !== undefined && requestParameters.hash !== null) {
            headerParameters['hash'] = String(requestParameters.hash);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["token"] = this.configuration.apiKey("token"); // legacy authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["cons.app"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/app/getusertimeline`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InlineObjectToJSON(requestParameters.inlineObject),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetUserTimelineResponseFromJSON(jsonValue));
    }

    /**
     * Returns one obejct per day, with all appointments for this day sorted by day and starttime
     */
    async getUserTimeline(id?: string, hash?: number, inlineObject?: InlineObject): Promise<GetUserTimelineResponse> {
        const response = await this.getUserTimelineRaw({ id: id, hash: hash, inlineObject: inlineObject });
        return await response.value();
    }

    /**
     * Get all active users
     */
    async getUsersRaw(requestParameters: GetUsersRequest): Promise<runtime.ApiResponse<GetUsersResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.hash !== undefined && requestParameters.hash !== null) {
            headerParameters['hash'] = String(requestParameters.hash);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["token"] = this.configuration.apiKey("token"); // legacy authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["cons.app"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/app/getusers`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetUsersResponseFromJSON(jsonValue));
    }

    /**
     * Get all active users
     */
    async getUsers(hash?: number): Promise<GetUsersResponse> {
        const response = await this.getUsersRaw({ hash: hash });
        return await response.value();
    }

    /**
     * login in the app
     */
    async loginRaw(requestParameters: LoginOperationRequest): Promise<runtime.ApiResponse<LoginResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["cons.app"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/app/login`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LoginRequestToJSON(requestParameters.loginRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => LoginResponseFromJSON(jsonValue));
    }

    /**
     * login in the app
     */
    async login(loginRequest?: LoginRequest): Promise<LoginResponse> {
        const response = await this.loginRaw({ loginRequest: loginRequest });
        return await response.value();
    }

    /**
     * Writes the push settings of the device into table mobiledevices
     */
    async registerPushRaw(requestParameters: RegisterPushOperationRequest): Promise<runtime.ApiResponse<DefaultResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["token"] = this.configuration.apiKey("token"); // legacy authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["cons.app"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/app/registerpush`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RegisterPushRequestToJSON(requestParameters.registerPushRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DefaultResponseFromJSON(jsonValue));
    }

    /**
     * Writes the push settings of the device into table mobiledevices
     */
    async registerPush(registerPushRequest?: RegisterPushRequest): Promise<DefaultResponse> {
        const response = await this.registerPushRaw({ registerPushRequest: registerPushRequest });
        return await response.value();
    }

    /**
     * Removes one or more activities from a user folder
     */
    async removeAktsFromFolderRaw(requestParameters: RemoveAktsFromFolderOperationRequest): Promise<runtime.ApiResponse<number>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["token"] = this.configuration.apiKey("token"); // legacy authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["cons.app"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/app/removeaktsfromfolder`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RemoveAktsFromFolderRequestToJSON(requestParameters.removeAktsFromFolderRequest),
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Removes one or more activities from a user folder
     */
    async removeAktsFromFolder(removeAktsFromFolderRequest?: RemoveAktsFromFolderRequest): Promise<number> {
        const response = await this.removeAktsFromFolderRaw({ removeAktsFromFolderRequest: removeAktsFromFolderRequest });
        return await response.value();
    }

    /**
     * remove attachment from activity
     */
    async removeAttachmentRaw(requestParameters: RemoveAttachmentRequest): Promise<runtime.ApiResponse<DefaultResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["token"] = this.configuration.apiKey("token"); // legacy authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["cons.app"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/app/removeattachment`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InlineObject14ToJSON(requestParameters.inlineObject14),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DefaultResponseFromJSON(jsonValue));
    }

    /**
     * remove attachment from activity
     */
    async removeAttachment(inlineObject14?: InlineObject14): Promise<DefaultResponse> {
        const response = await this.removeAttachmentRaw({ inlineObject14: inlineObject14 });
        return await response.value();
    }

    /**
     * Removes a client from an activity
     */
    async removeClientRaw(requestParameters: RemoveClientRequest): Promise<runtime.ApiResponse<number>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["token"] = this.configuration.apiKey("token"); // legacy authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["cons.app"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/app/removeclient`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddOrRemoveClientRequestToJSON(requestParameters.addOrRemoveClientRequest),
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Removes a client from an activity
     */
    async removeClient(addOrRemoveClientRequest?: AddOrRemoveClientRequest): Promise<number> {
        const response = await this.removeClientRaw({ addOrRemoveClientRequest: addOrRemoveClientRequest });
        return await response.value();
    }

    /**
     * Remove user, address, project from favorites
     */
    async removeFavoriteRaw(requestParameters: RemoveFavoriteRequest): Promise<runtime.ApiResponse<DefaultResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["token"] = this.configuration.apiKey("token"); // legacy authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["cons.app"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/app/removefavorite`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InlineObject4ToJSON(requestParameters.inlineObject4),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DefaultResponseFromJSON(jsonValue));
    }

    /**
     * Remove user, address, project from favorites
     */
    async removeFavorite(inlineObject4?: InlineObject4): Promise<DefaultResponse> {
        const response = await this.removeFavoriteRaw({ inlineObject4: inlineObject4 });
        return await response.value();
    }

    /**
     * Removes a participant from an appointment
     */
    async removeParticipantRaw(requestParameters: RemoveParticipantOperationRequest): Promise<runtime.ApiResponse<number>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["token"] = this.configuration.apiKey("token"); // legacy authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["cons.app"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/app/removeparticipant`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RemoveParticipantRequestToJSON(requestParameters.removeParticipantRequest),
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Removes a participant from an appointment
     */
    async removeParticipant(removeParticipantRequest?: RemoveParticipantRequest): Promise<number> {
        const response = await this.removeParticipantRaw({ removeParticipantRequest: removeParticipantRequest });
        return await response.value();
    }

    /**
     * Set activity as done
     */
    async setAktDoneRaw(requestParameters: SetAktDoneRequest): Promise<runtime.ApiResponse<DefaultResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.id !== undefined && requestParameters.id !== null) {
            headerParameters['id'] = String(requestParameters.id);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["token"] = this.configuration.apiKey("token"); // legacy authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["cons.app"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/app/setaktdone`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DefaultResponseFromJSON(jsonValue));
    }

    /**
     * Set activity as done
     */
    async setAktDone(id?: number): Promise<DefaultResponse> {
        const response = await this.setAktDoneRaw({ id: id });
        return await response.value();
    }

    /**
     * Assigns activity to a project
     */
    async setAktProjectRaw(requestParameters: SetAktProjectRequest): Promise<runtime.ApiResponse<DefaultResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["token"] = this.configuration.apiKey("token"); // legacy authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["cons.app"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/app/setaktproject`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InlineObject9ToJSON(requestParameters.inlineObject9),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DefaultResponseFromJSON(jsonValue));
    }

    /**
     * Assigns activity to a project
     */
    async setAktProject(inlineObject9?: InlineObject9): Promise<DefaultResponse> {
        const response = await this.setAktProjectRaw({ inlineObject9: inlineObject9 });
        return await response.value();
    }

    /**
     * Set activity as read
     */
    async setAktReadRaw(requestParameters: SetAktReadRequest): Promise<runtime.ApiResponse<DefaultResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.id !== undefined && requestParameters.id !== null) {
            headerParameters['id'] = String(requestParameters.id);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["token"] = this.configuration.apiKey("token"); // legacy authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["cons.app"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/app/setaktread`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DefaultResponseFromJSON(jsonValue));
    }

    /**
     * Set activity as read
     */
    async setAktRead(id?: number): Promise<DefaultResponse> {
        const response = await this.setAktReadRaw({ id: id });
        return await response.value();
    }

    /**
     * Set activity secret group
     */
    async setAktSecretGroupRaw(requestParameters: SetAktSecretGroupRequest): Promise<runtime.ApiResponse<DefaultResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["token"] = this.configuration.apiKey("token"); // legacy authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["cons.app"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/app/setaktsecretgroup`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InlineObject11ToJSON(requestParameters.inlineObject11),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DefaultResponseFromJSON(jsonValue));
    }

    /**
     * Set activity secret group
     */
    async setAktSecretGroup(inlineObject11?: InlineObject11): Promise<DefaultResponse> {
        const response = await this.setAktSecretGroupRaw({ inlineObject11: inlineObject11 });
        return await response.value();
    }

    /**
     * Set activity as seen
     */
    async setAktSeenRaw(requestParameters: SetAktSeenRequest): Promise<runtime.ApiResponse<DefaultResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.id !== undefined && requestParameters.id !== null) {
            headerParameters['id'] = String(requestParameters.id);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["token"] = this.configuration.apiKey("token"); // legacy authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["cons.app"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/app/setaktseen`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DefaultResponseFromJSON(jsonValue));
    }

    /**
     * Set activity as seen
     */
    async setAktSeen(id?: number): Promise<DefaultResponse> {
        const response = await this.setAktSeenRaw({ id: id });
        return await response.value();
    }

    /**
     * Set activity type
     */
    async setAktTypeRaw(requestParameters: SetAktTypeRequest): Promise<runtime.ApiResponse<DefaultResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["token"] = this.configuration.apiKey("token"); // legacy authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["cons.app"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/app/setakttype`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InlineObject7ToJSON(requestParameters.inlineObject7),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DefaultResponseFromJSON(jsonValue));
    }

    /**
     * Set activity type
     */
    async setAktType(inlineObject7?: InlineObject7): Promise<DefaultResponse> {
        const response = await this.setAktTypeRaw({ inlineObject7: inlineObject7 });
        return await response.value();
    }

    /**
     * Set activity secret group
     */
    async setAlarmTimeRaw(requestParameters: SetAlarmTimeRequest): Promise<runtime.ApiResponse<DefaultResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["token"] = this.configuration.apiKey("token"); // legacy authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["cons.app"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/app/setalarmtime`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InlineObject12ToJSON(requestParameters.inlineObject12),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DefaultResponseFromJSON(jsonValue));
    }

    /**
     * Set activity secret group
     */
    async setAlarmTime(inlineObject12?: InlineObject12): Promise<DefaultResponse> {
        const response = await this.setAlarmTimeRaw({ inlineObject12: inlineObject12 });
        return await response.value();
    }

    /**
     * Sets the appointment date for an appointment
     */
    async setAppointmentDateRaw(requestParameters: SetAppointmentDateRequest): Promise<runtime.ApiResponse<DefaultResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["token"] = this.configuration.apiKey("token"); // legacy authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["cons.app"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/app/setappointmentdate`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InlineObject19ToJSON(requestParameters.inlineObject19),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DefaultResponseFromJSON(jsonValue));
    }

    /**
     * Sets the appointment date for an appointment
     */
    async setAppointmentDate(inlineObject19?: InlineObject19): Promise<DefaultResponse> {
        const response = await this.setAppointmentDateRaw({ inlineObject19: inlineObject19 });
        return await response.value();
    }

    /**
     * Set activity start date
     */
    async setDateRaw(requestParameters: SetDateRequest): Promise<runtime.ApiResponse<DefaultResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["token"] = this.configuration.apiKey("token"); // legacy authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["cons.app"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/app/setdate`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InlineObject15ToJSON(requestParameters.inlineObject15),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DefaultResponseFromJSON(jsonValue));
    }

    /**
     * Set activity start date
     */
    async setDate(inlineObject15?: InlineObject15): Promise<DefaultResponse> {
        const response = await this.setDateRaw({ inlineObject15: inlineObject15 });
        return await response.value();
    }

    /**
     * Set activity priority
     */
    async setPriorityRaw(requestParameters: SetPriorityRequest): Promise<runtime.ApiResponse<DefaultResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["token"] = this.configuration.apiKey("token"); // legacy authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["cons.app"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/app/setpriority`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InlineObject10ToJSON(requestParameters.inlineObject10),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DefaultResponseFromJSON(jsonValue));
    }

    /**
     * Set activity priority
     */
    async setPriority(inlineObject10?: InlineObject10): Promise<DefaultResponse> {
        const response = await this.setPriorityRaw({ inlineObject10: inlineObject10 });
        return await response.value();
    }

    /**
     * Set user signature
     */
    async setSignatureRaw(requestParameters: SetSignatureRequest): Promise<runtime.ApiResponse<DefaultResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["token"] = this.configuration.apiKey("token"); // legacy authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["cons.app"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/app/setsignature`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InlineObject2ToJSON(requestParameters.inlineObject2),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DefaultResponseFromJSON(jsonValue));
    }

    /**
     * Set user signature
     */
    async setSignature(inlineObject2?: InlineObject2): Promise<DefaultResponse> {
        const response = await this.setSignatureRaw({ inlineObject2: inlineObject2 });
        return await response.value();
    }

    /**
     * Set activity status
     */
    async setStatusRaw(requestParameters: SetStatusRequest): Promise<runtime.ApiResponse<DefaultResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["token"] = this.configuration.apiKey("token"); // legacy authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["cons.app"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/app/setstatus`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InlineObject8ToJSON(requestParameters.inlineObject8),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DefaultResponseFromJSON(jsonValue));
    }

    /**
     * Set activity status
     */
    async setStatus(inlineObject8?: InlineObject8): Promise<DefaultResponse> {
        const response = await this.setStatusRaw({ inlineObject8: inlineObject8 });
        return await response.value();
    }

    /**
     * Set activity subject
     */
    async setSubjectRaw(requestParameters: SetSubjectRequest): Promise<runtime.ApiResponse<DefaultResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["token"] = this.configuration.apiKey("token"); // legacy authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["cons.app"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/app/setsubject`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InlineObject1ToJSON(requestParameters.inlineObject1),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DefaultResponseFromJSON(jsonValue));
    }

    /**
     * Set activity subject
     */
    async setSubject(inlineObject1?: InlineObject1): Promise<DefaultResponse> {
        const response = await this.setSubjectRaw({ inlineObject1: inlineObject1 });
        return await response.value();
    }

    /**
     * Cancel workflow process
     */
    async workflowCancelProcessRaw(requestParameters: WorkflowCancelProcessOperationRequest): Promise<runtime.ApiResponse<DefaultResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["token"] = this.configuration.apiKey("token"); // legacy authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["cons.app"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/app/workflowcancelprocess`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WorkflowCancelProcessRequestToJSON(requestParameters.workflowCancelProcessRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DefaultResponseFromJSON(jsonValue));
    }

    /**
     * Cancel workflow process
     */
    async workflowCancelProcess(workflowCancelProcessRequest?: WorkflowCancelProcessRequest): Promise<DefaultResponse> {
        const response = await this.workflowCancelProcessRaw({ workflowCancelProcessRequest: workflowCancelProcessRequest });
        return await response.value();
    }

    /**
     * Starts next workflow step
     */
    async workflowDoNextStepRaw(requestParameters: WorkflowDoNextStepOperationRequest): Promise<runtime.ApiResponse<WorkflowDoNextStepResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["token"] = this.configuration.apiKey("token"); // legacy authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["cons.app"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/app/workflowdonextstep`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WorkflowDoNextStepRequestToJSON(requestParameters.workflowDoNextStepRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => WorkflowDoNextStepResponseFromJSON(jsonValue));
    }

    /**
     * Starts next workflow step
     */
    async workflowDoNextStep(workflowDoNextStepRequest?: WorkflowDoNextStepRequest): Promise<WorkflowDoNextStepResponse> {
        const response = await this.workflowDoNextStepRaw({ workflowDoNextStepRequest: workflowDoNextStepRequest });
        return await response.value();
    }

    /**
     * Starts worflow process
     */
    async workflowStartProcessRaw(requestParameters: WorkflowStartProcessRequest): Promise<runtime.ApiResponse<InlineResponse2002>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["token"] = this.configuration.apiKey("token"); // legacy authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["cons.app"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/app/workflowstartprocess`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InlineObject22ToJSON(requestParameters.inlineObject22),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse2002FromJSON(jsonValue));
    }

    /**
     * Starts worflow process
     */
    async workflowStartProcess(inlineObject22?: InlineObject22): Promise<InlineResponse2002> {
        const response = await this.workflowStartProcessRaw({ inlineObject22: inlineObject22 });
        return await response.value();
    }

}
