/* tslint:disable */
/* eslint-disable */
/**
 * Consolidate Legacy API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AdditionalCommunication
 */
export interface AdditionalCommunication {
    /**
     * 
     * @type {string}
     * @memberof AdditionalCommunication
     */
    conntype: string;
    /**
     * 
     * @type {string}
     * @memberof AdditionalCommunication
     */
    connvalue: string;
}

export function AdditionalCommunicationFromJSON(json: any): AdditionalCommunication {
    return AdditionalCommunicationFromJSONTyped(json, false);
}

export function AdditionalCommunicationFromJSONTyped(json: any, ignoreDiscriminator: boolean): AdditionalCommunication {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'conntype': json['conntype'],
        'connvalue': json['connvalue'],
    };
}

export function AdditionalCommunicationToJSON(value?: AdditionalCommunication | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'conntype': value.conntype,
        'connvalue': value.connvalue,
    };
}


