/* tslint:disable */
/* eslint-disable */
/**
 * Consolidate Legacy API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetProjectListRequest
 */
export interface GetProjectListRequest {
    /**
     * 
     * @type {string}
     * @memberof GetProjectListRequest
     */
    searchstring?: string;
    /**
     * 
     * @type {number}
     * @memberof GetProjectListRequest
     */
    offset?: number;
    /**
     * 
     * @type {number}
     * @memberof GetProjectListRequest
     */
    limit?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GetProjectListRequest
     */
    onlyactive?: boolean;
}

export function GetProjectListRequestFromJSON(json: any): GetProjectListRequest {
    return GetProjectListRequestFromJSONTyped(json, false);
}

export function GetProjectListRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetProjectListRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'searchstring': !exists(json, 'searchstring') ? undefined : json['searchstring'],
        'offset': !exists(json, 'offset') ? undefined : json['offset'],
        'limit': !exists(json, 'limit') ? undefined : json['limit'],
        'onlyactive': !exists(json, 'onlyactive') ? undefined : json['onlyactive'],
    };
}

export function GetProjectListRequestToJSON(value?: GetProjectListRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'searchstring': value.searchstring,
        'offset': value.offset,
        'limit': value.limit,
        'onlyactive': value.onlyactive,
    };
}


