/* tslint:disable */
/* eslint-disable */
/**
 * Consolidate Legacy API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WorkflowCancelProcessRequest
 */
export interface WorkflowCancelProcessRequest {
    /**
     * 
     * @type {number}
     * @memberof WorkflowCancelProcessRequest
     */
    aktid: number;
    /**
     * 
     * @type {string}
     * @memberof WorkflowCancelProcessRequest
     */
    reasoncomment?: string;
}

export function WorkflowCancelProcessRequestFromJSON(json: any): WorkflowCancelProcessRequest {
    return WorkflowCancelProcessRequestFromJSONTyped(json, false);
}

export function WorkflowCancelProcessRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkflowCancelProcessRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'aktid': json['aktid'],
        'reasoncomment': !exists(json, 'reasoncomment') ? undefined : json['reasoncomment'],
    };
}

export function WorkflowCancelProcessRequestToJSON(value?: WorkflowCancelProcessRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'aktid': value.aktid,
        'reasoncomment': value.reasoncomment,
    };
}


