/* tslint:disable */
/* eslint-disable */
/**
 * Consolidate Legacy API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GetAktResponseBaseType,
    GetAktResponseBaseTypeFromJSON,
    GetAktResponseBaseTypeFromJSONTyped,
    GetAktResponseBaseTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetAddressResponseObjects
 */
export interface GetAddressResponseObjects {
    /**
     * 
     * @type {number}
     * @memberof GetAddressResponseObjects
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof GetAddressResponseObjects
     */
    description?: string;
    /**
     * 
     * @type {GetAktResponseBaseType}
     * @memberof GetAddressResponseObjects
     */
    type?: GetAktResponseBaseType;
}

export function GetAddressResponseObjectsFromJSON(json: any): GetAddressResponseObjects {
    return GetAddressResponseObjectsFromJSONTyped(json, false);
}

export function GetAddressResponseObjectsFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetAddressResponseObjects {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'type': !exists(json, 'type') ? undefined : GetAktResponseBaseTypeFromJSON(json['type']),
    };
}

export function GetAddressResponseObjectsToJSON(value?: GetAddressResponseObjects | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'description': value.description,
        'type': GetAktResponseBaseTypeToJSON(value.type),
    };
}


