/* tslint:disable */
/* eslint-disable */
/**
 * Consolidate Legacy API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AddParticipantRequest
 */
export interface AddParticipantRequest {
    /**
     * 
     * @type {number}
     * @memberof AddParticipantRequest
     */
    aktid: number;
    /**
     * 
     * @type {string}
     * @memberof AddParticipantRequest
     */
    uid?: string;
    /**
     * 
     * @type {number}
     * @memberof AddParticipantRequest
     */
    cid?: number;
    /**
     * 
     * @type {string}
     * @memberof AddParticipantRequest
     */
    anr?: string;
}

export function AddParticipantRequestFromJSON(json: any): AddParticipantRequest {
    return AddParticipantRequestFromJSONTyped(json, false);
}

export function AddParticipantRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddParticipantRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'aktid': json['aktid'],
        'uid': !exists(json, 'uid') ? undefined : json['uid'],
        'cid': !exists(json, 'cid') ? undefined : json['cid'],
        'anr': !exists(json, 'anr') ? undefined : json['anr'],
    };
}

export function AddParticipantRequestToJSON(value?: AddParticipantRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'aktid': value.aktid,
        'uid': value.uid,
        'cid': value.cid,
        'anr': value.anr,
    };
}


