/* tslint:disable */
/* eslint-disable */
/**
 * Consolidate Legacy API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    GetContactItemAddress,
    GetContactItemContact,
    GetContactItemDivider,
    GetContactItemAddressFromJSONTyped,
    GetContactItemAddressToJSON,
    GetContactItemContactFromJSONTyped,
    GetContactItemContactToJSON,
    GetContactItemDividerFromJSONTyped,
    GetContactItemDividerToJSON,
} from './';

/**
 * @type GetContactItem
 * 
 * @export
 */
export type GetContactItem = { type: 'A' } & GetContactItemAddress | { type: 'D' } & GetContactItemDivider | { type: 'K' } & GetContactItemContact;

export function GetContactItemFromJSON(json: any): GetContactItem {
    return GetContactItemFromJSONTyped(json, false);
}

export function GetContactItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetContactItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    switch (json['id'][0]) {
        case 'A':
            return {...GetContactItemAddressFromJSONTyped(json, true), type: 'A'};
        case 'D':
            return {...GetContactItemDividerFromJSONTyped(json, true), type: 'D'};
        case 'K':
            return {...GetContactItemContactFromJSONTyped(json, true), type: 'K'};
        default:
            throw new Error(`No variant of GetContactItem exists with 'type=${json['id'][0]}'`);
    }
}

export function GetContactItemToJSON(value?: GetContactItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    switch (value['type']) {
        case 'A':
            return GetContactItemAddressToJSON(value);
        case 'D':
            return GetContactItemDividerToJSON(value);
        case 'K':
            return GetContactItemContactToJSON(value);
        default:
            throw new Error(`No variant of GetContactItem exists with 'type=${value['type']}'`);
    }
}

