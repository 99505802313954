import {
  Configuration,
  DefaultApi,
} from '@consolidate/shared/data-access-legacy-api';
import { AuthService } from '@consolidate/shared/util-auth';
import { OidcClient } from 'oidc-client';
import * as semver from 'semver';

class LoginFacade {
  public async login(
    webserviceUrl: string,
    minVersion: string
  ): Promise<true | 'unreachable' | 'outdated' | 'servermissing'> {
    if (!webserviceUrl.toLowerCase().startsWith('http')) {
      webserviceUrl = `https://${webserviceUrl}`;
    }

    const result = await this.validateVersion(webserviceUrl, minVersion);
    if (result !== true) {
      return result;
    }

    if (!(await this.validateServer(webserviceUrl))) {
      return 'servermissing';
    }

    await AuthService.login(webserviceUrl);

    return true;
  }

  private async validateVersion(
    webserviceUrl: string,
    minVersion: string
  ): Promise<true | 'unreachable' | 'outdated'> {
    try {
      const api = new DefaultApi(
        new Configuration({
          basePath: webserviceUrl,
        })
      );

      const info = await api.getApiInfo();

      const version = semver.valid(semver.coerce(info.version)) ?? '';
      minVersion = semver.valid(semver.coerce(minVersion)) ?? '';

      if (semver.gte(version, minVersion)) {
        return true;
      }

      return 'outdated';
    } catch {
      return 'unreachable';
    }
  }

  private async validateServer(webserviceUrl: string): Promise<boolean> {
    try {
      const client = new OidcClient({
        authority: webserviceUrl,
      });

      const metadata = await client.metadataService.getMetadata();
      return !!metadata;
    } catch {
      return false;
    }
  }
}

export default new LoginFacade();
