/* tslint:disable */
/* eslint-disable */
/**
 * Consolidate Legacy API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GetSentMailsResponseItems,
    GetSentMailsResponseItemsFromJSON,
    GetSentMailsResponseItemsFromJSONTyped,
    GetSentMailsResponseItemsToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetSentMailsResponse
 */
export interface GetSentMailsResponse {
    /**
     * 
     * @type {Array<GetSentMailsResponseItems>}
     * @memberof GetSentMailsResponse
     */
    items?: Array<GetSentMailsResponseItems>;
}

export function GetSentMailsResponseFromJSON(json: any): GetSentMailsResponse {
    return GetSentMailsResponseFromJSONTyped(json, false);
}

export function GetSentMailsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetSentMailsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'items': !exists(json, 'items') ? undefined : ((json['items'] as Array<any>).map(GetSentMailsResponseItemsFromJSON)),
    };
}

export function GetSentMailsResponseToJSON(value?: GetSentMailsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'items': value.items === undefined ? undefined : ((value.items as Array<any>).map(GetSentMailsResponseItemsToJSON)),
    };
}


