/* tslint:disable */
/* eslint-disable */
/**
 * Consolidate Legacy API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetthumbpicturesResponseItems
 */
export interface GetthumbpicturesResponseItems {
    /**
     * 
     * @type {number}
     * @memberof GetthumbpicturesResponseItems
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof GetthumbpicturesResponseItems
     */
    picture?: string;
}

export function GetthumbpicturesResponseItemsFromJSON(json: any): GetthumbpicturesResponseItems {
    return GetthumbpicturesResponseItemsFromJSONTyped(json, false);
}

export function GetthumbpicturesResponseItemsFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetthumbpicturesResponseItems {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'picture': !exists(json, 'picture') ? undefined : json['picture'],
    };
}

export function GetthumbpicturesResponseItemsToJSON(value?: GetthumbpicturesResponseItems | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'picture': value.picture,
    };
}


