/* tslint:disable */
/* eslint-disable */
/**
 * Consolidate Legacy API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RemoveParticipantRequest
 */
export interface RemoveParticipantRequest {
    /**
     * 
     * @type {number}
     * @memberof RemoveParticipantRequest
     */
    aktid: number;
    /**
     * 
     * @type {string}
     * @memberof RemoveParticipantRequest
     */
    uid?: string;
    /**
     * 
     * @type {number}
     * @memberof RemoveParticipantRequest
     */
    cid?: number;
    /**
     * 
     * @type {string}
     * @memberof RemoveParticipantRequest
     */
    anr?: string;
    /**
     * 
     * @type {string}
     * @memberof RemoveParticipantRequest
     */
    reason?: string;
}

export function RemoveParticipantRequestFromJSON(json: any): RemoveParticipantRequest {
    return RemoveParticipantRequestFromJSONTyped(json, false);
}

export function RemoveParticipantRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): RemoveParticipantRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'aktid': json['aktid'],
        'uid': !exists(json, 'uid') ? undefined : json['uid'],
        'cid': !exists(json, 'cid') ? undefined : json['cid'],
        'anr': !exists(json, 'anr') ? undefined : json['anr'],
        'reason': !exists(json, 'reason') ? undefined : json['reason'],
    };
}

export function RemoveParticipantRequestToJSON(value?: RemoveParticipantRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'aktid': value.aktid,
        'uid': value.uid,
        'cid': value.cid,
        'anr': value.anr,
        'reason': value.reason,
    };
}


